import {Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-coin',
  templateUrl: './my-coin.component.html',
  styleUrls: ['./my-coin.component.css']
})

export class MyCoinComponent implements OnInit {
  UserDetails: any = {}
  MyAccountPage: any = {};
  MyTransaction:any=[];
  GetAllTransaction:any=[];
  emails:any='';
  constructor(private serviceObj: ArabesqueService,private router:Router) { }
  RefreshUD() { this.UserDetails = this.serviceObj.getUD(); 
    if(this.UserDetails==null)
    this.router.navigate(['/Home', 0]);
    this.MyAccountPage = this.serviceObj.GetPageSpecificContent('MyAccount'); 
  }

  ngOnInit() {
    this.UserDetails = this.serviceObj.getUD();
    this.MyAccountPage = this.serviceObj.GetPageSpecificContent('MyAccount');
    
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
        document.getElementById('btnMessageModal1').click();
      }
      this.serviceObj.getArbData('UserDetails', null).subscribe(
        ArbApiResponse => {
          this.serviceObj.removeUD();
          this.serviceObj.setUD(ArbApiResponse["data"], false);
          this.RefreshUD();
          document.getElementById('btnRefreshUD').click();
          document.getElementById('btnHeaderRefreshUD').click();
        });
    
    }
    if (window.location.href.indexOf('/Home') > 0)
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
  else
  this.GetAllTransaction.GetAllTransaction=false;
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader';
    /*Call Api for request from GetCoinTransaction*/
   
    this.serviceObj.getArbData('GetCoinTransaction', this.GetAllTransaction).subscribe(
      ArbApiResponse => {
        this.MyTransaction = ArbApiResponse["data"];
      }
    );
  }

  // All Spending and Earning
  requestTransaction() {
       this.GetAllTransaction.GetAllTransaction=!this.GetAllTransaction.GetAllTransaction;
        this.serviceObj.getArbData('GetCoinTransaction', this.GetAllTransaction).subscribe(
         ArbApiResponse => {
           this.MyTransaction = ArbApiResponse["data"];
         }
       );
    }
  // public validators = [ this.must_be_email ];
  //   public errorMessages = {
  //       'must_be_email': 'Enter valid email adress!'
  //   };
  //   private must_be_email(control: FormControl) {        
  //       var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  //       if (control.value != "" && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
  //           return { "must_be_email": true };
  //       }
  //       return null;
  //   }
  // public validators = [ this.must_be_email ];
  // public errorMessages = {
  //     'must_be_email': 'Enter valid email adress!'
  // };
  // private must_be_email(control: FormControl) {        
  //     var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  //     if (control.value != "" && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
  //         return { "must_be_email": true };
  //     }
  //     return null;
  // }



}
