import {Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Location, PopStateEvent  } from '@angular/common';
import {  Router,NavigationEnd,NavigationStart } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-my-people',
  templateUrl: './my-people.component.html',
  styleUrls: ['./my-people.component.css']
})

export class MyPeopleComponent implements OnInit {
  order = "ID";
  PeoplePage: any = {};
  ascending:boolean = true;
  ascendingRequests:boolean=true;
  SearchObj: any = {};
  UserDetails: any = {}
  MyConnectionsList:any=[];
  Connected:any=[];
  Recommendedby:string;
  AcceptRejectRequest:any={};
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  MyPeopleRecommendationsList:any=[];
  DelStatus:any={};
  AllRecList:any=[];
  
  constructor(private serviceObj: ArabesqueService,private router:Router, private location: Location) {
    this.SearchObj.PageIndex = 1;
    this.SearchObj.ItemType = 'All';
    this.SearchObj.SearchText = '';
    this.SearchObj.IsTimeline=true;
    this.SearchObj.Location='';
    this.SearchObj.Interest='';
    this.SearchObj.Distance=0;

    this.SearchObj.Latitude = '';
    this.SearchObj.Longitude = '';

    this.AcceptRejectRequest.ID=0;
    this.AcceptRejectRequest.IsApproved=false; 
   }
  
   RefreshUD() { this.UserDetails = this.serviceObj.getUD();
    this.PeoplePage = this.serviceObj.GetPageSpecificContent('People'); 
    if(this.UserDetails==null)
    this.router.navigate(['/Home', 0]);
  }

  ngOnInit() 
  {
    this.RefreshUD();

    if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
      document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
     $("#MessageModal1").modal({ backdrop: 'static', keyboard: false});
    }
    let tab=sessionStorage.getItem('TAB');
    if(tab!=null){
      if(tab=='P')
      {
      $('#propMyItem').removeClass('active');
      $('#propItem').removeClass('active');

      $('#propReq').removeClass('active');
      $('#propRequest').removeClass('active');

      $('#propRecom').removeClass('active');
      $('#propRecomend').removeClass('active');
      
      $('#propReq').addClass('active');
      $('#propRequest').addClass('active');
      sessionStorage.removeItem('TAB');
    }
    }
    
    if (window.location.href.indexOf('/Home') > 0)
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
  else
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader';
    
        /*start scroll top*/
        this.location.subscribe((ev:PopStateEvent) => {
          this.lastPoppedUrl = ev.url;
      });
      this.router.events.subscribe((ev:any) => {
          if (ev instanceof NavigationStart) {
              if (ev.url != this.lastPoppedUrl)
                  this.yScrollStack.push(window.scrollY);
          } else if (ev instanceof NavigationEnd) {
              if (ev.url == this.lastPoppedUrl) {
                  this.lastPoppedUrl = undefined;
                  window.scrollTo(0, this.yScrollStack.pop());
              } else
                  window.scrollTo(0, 0);
          }
      });
    /*End scroll top*/
    this.serviceObj.getArbData('MyConnections', this.SearchObj).subscribe(
      data => {
      this.MyConnectionsList = data["data"];
      //this.Connected= data["data"]["Connected"];
      },
      err => console.error(err)
    );
    
    this.serviceObj.getArbData('GetMyPeopleRecommendations', this.SearchObj).subscribe(
      data => {
      this.MyPeopleRecommendationsList = data["data"];
      },
      err => console.error(err)
    );

  }

  onSortBy() {
    this.ascending =! this.ascending ;
  }
  
  onSortByRequests() {
    this.ascendingRequests =! this.ascendingRequests ;
  }

  AcceptRequest(rqst){
    this.AcceptRejectRequest.ID=rqst.ID;
    this.AcceptRejectRequest.IsApproved=true;
    this.serviceObj.postArbData('AcceptRejectPeopleRequest', this.AcceptRejectRequest).subscribe(
      ArbApiResponse => {
        document.getElementById('divMessage').innerHTML=ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        this.MyConnectionsList.Requests=this.MyConnectionsList.Requests.filter(i=>i.ID !==rqst.ID);
        
        this.serviceObj.getArbData('UserDetails', null).subscribe(
          ArbApiResponse => {
            this.serviceObj.removeUD();
            this.serviceObj.setUD(ArbApiResponse["data"], false);
            this.RefreshUD();
            document.getElementById('btnHeaderRefreshUD').click();
      
          });
      },
      ArbApiErr => { 
       }
   );
  }

  RejectRequest(rqst){
    this.AcceptRejectRequest.ID=rqst.ID;
    this.AcceptRejectRequest.IsApproved=false;
    this.serviceObj.postArbData('AcceptRejectPeopleRequest', this.AcceptRejectRequest).subscribe(
      ArbApiResponse => {
        document.getElementById('divMessage').innerHTML=ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        this.MyConnectionsList.Requests=this.MyConnectionsList.Requests.filter(i=>i.ID !==rqst.ID);

        this.serviceObj.getArbData('UserDetails', null).subscribe(
          ArbApiResponse => {
            this.serviceObj.removeUD();
            this.serviceObj.setUD(ArbApiResponse["data"], false);
            this.RefreshUD();
            document.getElementById('btnHeaderRefreshUD').click();
      
          });
      },
      ArbApiErr => { 
       }
   );
  }

  RevokeRequest(rqst){
    this.AcceptRejectRequest.ID=rqst.ID;
    this.AcceptRejectRequest.IsApproved=false;
    this.serviceObj.postArbData('CancelSentPendingRequest', this.AcceptRejectRequest).subscribe(
      ArbApiResponse => {
        document.getElementById('divMessage').innerHTML=ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        this.MyConnectionsList.PendingConnections=this.MyConnectionsList.PendingConnections.filter(i=>i.ID !==rqst.ID);
      },
      ArbApiErr => { 
       }
   );
  }


      
 DeleteConnection(Item: any) {
    this.DelStatus=Item;
    $('#ConfirmID').show();
  }

  
  OK(status:string,Item: any)
  {
    $('#ConfirmID').hide();
          if(status=='C')
          {
          }
          else if(status=='O')
          {
            this.serviceObj.postArbData('DeleteConnection', Item).subscribe(
              ArbApiResponse => {
                this.MyConnectionsList.Connected=this.MyConnectionsList.Connected.filter(i=>i.ID !=Item.ID);
                document.getElementById('divMessage').innerHTML=ArbApiResponse["Message"];
                document.getElementById('btnMessageModal').click();
              },
              ArbApiErr => { 
               }
            );
          }
  }


      MuteConnection(Item:any)
      {  
      this.serviceObj.postArbData('MuteConnection', Item).subscribe(
         ArbApiResponse => {
          if(Item.IsMutedByMe==true){
            Item.IsMutedByMe=false}
            else{
            Item.IsMutedByMe=true;
            }
          document.getElementById('divMessage').innerHTML=ArbApiResponse["Message"];
          document.getElementById('btnMessageModal').click();
         },
         ArbApiErr => { 
          }
       );
     }
     ViewRecommended(Item:any)
     {
      let Recommed:any={};
      Recommed.PeopleID=Item.IntroducedID;
      Recommed.IsSent=(this.UserDetails.ID==Item.SenderID);
      if(this.UserDetails.ID==Item.SenderID)
      {
        if(this.PeoplePage)
        this.Recommendedby=this.PeoplePage.Recommendedto;
      }
      else{
        if(this.PeoplePage)
        this.Recommendedby=this.PeoplePage.Recommendedby;
      }
      this.serviceObj.getArbData('GetAllRecommendationByPeople', Recommed).subscribe(
        ArbApiResponse => {
          this.AllRecList=ArbApiResponse["data"];
          $('#totalRecommended').modal('toggle');
        });
     
   }


}
