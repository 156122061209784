import { Component, OnInit,ViewChild } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from "@angular/common";
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent } from '../../../node_modules/ngx-image-cropper';

declare var $ :any;

@Component({
  selector: 'app-my-time-line',
  templateUrl: './my-time-line.component.html',
  styleUrls: ['./my-time-line.component.css']
})
export class MyTimeLineComponent implements OnInit {
  posted: number = 0;
  CommentID: number = 0;
  UserDetails: any = {}
  SearchObj: any = [];
  MyNewPostList: any = [];
  PostList: any = [];
  LikedUsersList:any=[];
  fileToUpload: File = null;
  ArabesqueWallCategories: any = [];
  MyNewPost: any = {};
  NewComment: any = {};
  isValidCat: boolean = true;
  isValidText: boolean = true;
  imageUrl: string = '';
  vdoURL: string = '';
  isLiked: boolean = false;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  PostFileErrormsg:string='';
  ShowOnlineVdoField:boolean=true;
  uploadedVdoFileName:string='';
  isDisablePublish:boolean=false;
  data2:any;
  TempImageArr:any=[];
  ID:number=1;
  tempImag:any='';
  TimeLineContent:any={};
  fileToUpload1:File=null;
  filesize:number=100;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  DelStatus:any={};
  TempImageArray:any=[];

  constructor(private serviceObj: ArabesqueService, private router: Router, private location: Location, public sanitizer: DomSanitizer) {
    this.MyNewPost.PostID =0;
    this.MyNewPost.PostPic = '';
    this.MyNewPost.PostText = '';
    this.MyNewPost.CategoryID = 0;
    this.MyNewPost.FileType = '';
    this.MyNewPost.FileTitle = '';   
    this.MyNewPost.OnlineVdo='';

    this.MyNewPost.Latitude ='';
    this.MyNewPost.Longitude ='';
  
    this.NewComment.CommentText = '';
    this.NewComment.CommentID = 0;

    this.SearchObj.CategoryIDs = '';
    this.SearchObj.PageIndex = 1;
    this.SearchObj.ItemType = 'All';
    this.SearchObj.SearchText = '';
    this.SearchObj.IsTimeline = true;
    this.SearchObj.Longitude = '';
    this.SearchObj.Latitude = '';
   

  this.data2 = {};

  }
  RefreshUD() { this.UserDetails = this.serviceObj.getUD();
    if(this.UserDetails==null)
    this.router.navigate(['/Home', 0]);
    this.TimeLineContent=this.serviceObj.GetPageSpecificContent('TimeLine'); 
  }

  ngOnInit() {
    this.UserDetails = this.serviceObj.getUD();
    this.TimeLineContent=this.serviceObj.GetPageSpecificContent('TimeLine'); 

    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

      document.getElementById('btnLoadMap').click();

    /*start scroll top*/
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else
          window.scrollTo(0, 0);
      }
    });
    /*End scroll top*/
  
    
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
       // document.getElementById('btnMessageModal1').click();
       $("#MessageModal1").modal({ backdrop: 'static', keyboard: false});
      }
    }
    this.serviceObj.getArbData('ArabesqueWallCategories', '').subscribe(
      ArbApiResponse => {
        this.ArabesqueWallCategories = ArbApiResponse["data"];
      });

    this.serviceObj.getArbData('GetTimelinePosts', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.PostList = ArbApiResponse["data"];
      });

  }


  
  fileChangeEvent(ev: any): void {
  let file:FileList=ev.target.files
        if(file.length>0)
        {
            this.fileToUpload=file.item(0);
              var reader=new FileReader();
              reader.onload=(event:any)=>{
                document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
                setTimeout(() => {  
                  var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
                  if(wdth>380)
                  this.filesize=380
                  else
                  this.filesize=wdth
                  this.imageChangedEvent = ev;
                }, 2000);
                
              }
        reader.readAsDataURL(this.fileToUpload);
      }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  loadImageFailed() {
      // show message
  }
    SearchPost() 
    {

      this.serviceObj.getArbData('GetTimelinePosts', this.SearchObj).subscribe(
        ArbApiResponse => {
          this.PostList = ArbApiResponse["data"];
        });
    }

  onSelect(event) 
  {
    var selindex = event.split(":")[0];
    if (selindex == 0) {
      this.isValidCat = false;
    } else {
      this.isValidCat = true;
    }
  }

PostEdit(Post:any)
{
  this.MyNewPost.PostID=Post.PostID;
  this.imageUrl=Post.PostImgURL;
  this.MyNewPost.PostPic = '';
  this.vdoURL=Post.PostVdoURL;
  this.TempImageArray=Post.PostImageList;
  this.TempImageArr=Post.PostImageList;
  this.MyNewPost.OnlineVdo = Post.ExternalVdoURL;
  this.MyNewPost.CategoryID =Post.CategoryID;
  this.MyNewPost.FileTitle =Post.FileTitle;
  if(Post.PostImgURL!='')
  this.MyNewPost.FileType = 'Img';
  if(Post.PostVdoURL!='')
  this.MyNewPost.FileType = 'Vdo';
  this.MyNewPost.PostText =Post.PostText;
  if(Post.ExternalVdoURL!='' && Post.ExternalVdoURL.length>0)
  this.ShowOnlineVdoField=false;
  else
  this.ShowOnlineVdoField=true;
}

PostReset()
{
  this.MyNewPost={};
  this.MyNewPost.PostID =0;
  this.MyNewPost.PostPic = '';
  this.MyNewPost.PostText = '';
  this.MyNewPost.CategoryID = 0;
  this.MyNewPost.FileType = '';
  this.MyNewPost.FileTitle = '';   
  this.MyNewPost.OnlineVdo='';
  this.MyNewPost.Latitude ='';
  this.MyNewPost.Longitude ='';
  this.imageUrl='';
  this.vdoURL='';
  this.TempImageArr=[];
  this.TempImageArray=[];
  this.isValidText = true;
  this.ShowOnlineVdoField=true;
  this.isValidCat = true;
}
PostDelete(Post:any)
  {
    $('#ConfirmID').show();
    this.DelStatus=Post;
    
  }

OK(status:string,Post: any)
 {
  $('#ConfirmID').hide();
        if(status=='C')
        {
        }
        else if(status=='O')
        {
         let TempPost:any={};
          TempPost.PostID=Post.PostID;
          this.serviceObj.postArbData('DeleteWallPost', TempPost).subscribe(
            ArbApiResponse => 
            {
              document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
              document.getElementById('btnMessageModal').click()
              if(ArbApiResponse["Status"]==1)
              {
                this.serviceObj.getArbData('GetTimelinePosts', this.SearchObj).subscribe(
                  ApiResponse => {
                    this.PostList = ApiResponse["data"];
                });
              };
          });
        }
}

  PublishPost() 
  {
    if (this.MyNewPost.PostText == '') {
      this.isValidText = false;
    }
    else {
      this.isValidText = true;
    }
    if (this.MyNewPost.CategoryID == 0) {
      this.isValidCat = false;
    } else {
      this.isValidCat = true;
    }
    if (this.MyNewPost.PostText == '' || this.MyNewPost.CategoryID == 0) {

    } else {

      if (document.getElementById('txtAddressCoords')["value"] != '') {
        this.MyNewPost.Latitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[0];
        this.MyNewPost.Longitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[1];
      }else{
        this.MyNewPost.Latitude ='';
        this.MyNewPost.Longitude ='';
      }
      this.isDisablePublish=true;
      this.serviceObj.postArbData('AddWallPost', this.MyNewPost).subscribe(
        ArbApiResponse => {
          if(ArbApiResponse["Status"]==1)
          {
            this.TempImageArray=[];
            this.TempImageArr=[];
          this.isValidText = true;
          this.RemovePic();
          this.serviceObj.getArbData('GetTimelinePosts', this.SearchObj).subscribe(
            newpost => {
              this.PostList = newpost["data"];
              this.MyNewPost.PostID =0;
              this.MyNewPost.PostPic = '';
              this.imageUrl = '';
              this.MyNewPost.FileTitle = '';
              this.MyNewPost.FileType = '';
              this.MyNewPost.CategoryID = 0;
              this.MyNewPost.PostText = '';
              this.MyNewPost.OnlineVdo='';
              this.isValidText = true;
              this.ShowOnlineVdoField=true;
            }
          );
        }
        this.isDisablePublish=false;
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        },
        error=>{
          this.isDisablePublish=false;
        }
      );

    }
  }



  closeCro()
  {
    $('#MdlCropImg').removeClass('show'); 
    $('#MdlCropImg').addClass('fade'); 
    $('body').removeClass('modal-open');
  }

   CropImage()
   {
     if(this.MyNewPost.PostID==0 && (this.MyNewPost.FileType=='Img' || this.MyNewPost.FileType==''))
     {
        this.MyNewPost.FileType='Img';
        this.MyNewPost.PostPic='';
        this.MyNewPost.FileTitle = '';  
        this.vdoURL='';
        this.TempImageArr=[];
        this.TempImageArray=[];
        $('#MdlCropImg').removeClass('fade'); 
        $('body').addClass('modal-open');  
        $('#MdlCropImg').addClass('show'); 
     }
     else if(this.MyNewPost.PostID>0 && (this.MyNewPost.FileType=='Img' || this.MyNewPost.FileType==''))
     {
      $('#MdlCropImg').removeClass('fade'); 
      $('body').addClass('modal-open');  
      $('#MdlCropImg').addClass('show');
     }
   } 

    base64MimeType(encoded) 
    {
        var result = null;
        if (typeof encoded !== 'string') {
          return result;
        }
        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
        if (mime && mime.length) {
          result = mime[1];
        }
        return result;
    }

    
      SaveImage()
      {
        if(this.TempImageArr.length>0)
        {
            let i:number=1;
          for(let x of this.TempImageArr)
          {
            if((x.Thumb.toLowerCase().indexOf('sectionimages'))==-1)
            {
            let blob=this.dataURItoBlob(x.Thumb);
            let typeimg=this.base64MimeType(x.Thumb);
            var t=typeimg.split('/')[1];
            let names:string='filename.'+'jpg';
            var file = new File([blob], names, {type: typeimg});
            if(i==1)
            this.MyNewPost.PostPic=file;
            if(i==2)
            this.MyNewPost.PostPic0=file;
            if(i==3)
            this.MyNewPost.PostPic1=file;
            if(i==4)
            this.MyNewPost.PostPic2=file;
            if(i==5)
            this.MyNewPost.PostPic3=file;
            if(i==6)
            this.MyNewPost.PostPic4=file;
            if(i==7)
            this.MyNewPost.PostPic5=file;
            if(i==8)
            this.MyNewPost.PostPic6=file;
            }
            i++;
          }
        }
        this.TempImageArray=this.TempImageArr;
        this.MyNewPost.FileType='Img';
        $('#MdlCropImg').removeClass('show'); 
        $('#MdlCropImg').addClass('fade'); 
        $('body').removeClass('modal-open');
      }

        Add()
        {
            if(this.croppedImage!='')
            {
                this.ID+=this.ID+1;
                if(this.TempImageArr.length<5){
                  this.TempImageArr.push({ID:this.ID,Thumb:this.croppedImage,Image:''});
            }
          }
        }    
    
       RemoveIMG(Img:any)
       {
        this.TempImageArr=this.TempImageArr.filter(x => x.ID != Img.ID);
        if(this.MyNewPost.PostID!=0)
        {
          if(this.TempImageArray.filter(x => x.ID != Img.ID).length!=0)
          {
            this.RemoveImage(Img.ID);
          }
        }
       }

       RemoveImage(ID:any)
       {
         let DelPostImage:any={};
         DelPostImage.ID=ID
        this.serviceObj.postArbData('DeletePostImage', DelPostImage).subscribe(
          ArbApiResponse => 
          {
            if(ArbApiResponse["Status"]==1)
            {
              this.TempImageArray=this.TempImageArray.filter(x => x.ID != ID);
            }
          });
       }

  VideoLink()
  {
      if(this.MyNewPost.PostID==0 && (this.MyNewPost.FileType=='Vdo' || this.MyNewPost.FileType==''))
      {
          this.MyNewPost.FileType='Vdo';
          this.MyNewPost.PostPic='';
        // this.MyNewPost.FileTitle = '';  
          this.vdoURL='';
          this.TempImageArr=[];
          this.TempImageArray=[];
          this.ShowOnlineVdoField=!this.ShowOnlineVdoField;
          if(this.ShowOnlineVdoField==true)
          {
            this.MyNewPost.OnlineVdo='';
          }
      }
    }
  
   dataURItoBlob(dataURI) 
   {
          var binary = atob(dataURI.split(',')[1]);
          var array = [];
        for (var i = 0; i < binary.length; i++) 
        {
          array.push(binary.charCodeAt(i));
        }
       return new Blob([new Uint8Array(array)], {type: 'image/jpg'});
    }
  

  AddPostFile(fileInput: any, fileType: string) 
  {  
    this.PostFileErrormsg=''; 
    this.MyNewPost.FileType = fileType;
    this.MyNewPost.PostPic = fileInput.target.files;
    this.ShowOnlineVdoField=true
    this.vdoURL='';
    this.MyNewPost.FileTitle = '';  
    this.TempImageArr=[];
    this.TempImageArray=[];
    let file: FileList = fileInput.target.files
    if(file.length>0)
      {
          this.fileToUpload = file.item(0);
          var reader = new FileReader();
          reader.onload = (event: any) => {  
          document.getElementById('video_player')['src'] = event.target.result;        
          setTimeout(() => {  
          var lendur= document.getElementById('video_player')['duration'];
            if (file[0].size == 0 || file[0].size > 10240000) // 10MB
            {
              if(this.TimeLineContent)
              this.PostFileErrormsg=this.TimeLineContent.MaximumVideo;
              this.RemovePic();
            }
            else
            {
                this.vdoURL = event.target.result;
                this.imageUrl = '';
                this.PostFileErrormsg='';
              this.uploadedVdoFileName= file[0].name;
              // }
            }
          }, 2000);
        
          }
          reader.readAsDataURL(this.fileToUpload);
    }
  }

  RemovePic() {
    this.MyNewPost.PostPic = '';
    this.imageUrl = '';
    this.vdoURL = '';
    this.MyNewPost.FileTitle = '';
    this.MyNewPost.FileType = '';
    this.isValidText = true;
  
      this.TempImageArr=[];
      this.TempImageArray=[];
  }

  AddPostComment(pst: any) {
    if (document.getElementById('Comment_' + pst.PostID)["value"] != "") {
      this.NewComment.PostID = pst.PostID;
      this.NewComment.CommentID = 0;
      this.NewComment.CommentText = document.getElementById('Comment_' + pst.PostID)["value"];
      this.serviceObj.postArbData('AddWallComment', this.NewComment).subscribe(
        ArbApiResponse => {
          const d: Date = new Date();
          pst.Comments.push({
            "CommentID": ArbApiResponse["data"], "Comment": this.NewComment.CommentText, "CommentDateTime": d.toString(),
            "Replies": [],
            "CommentedBy": [{ "FirstName": this.UserDetails.FirstName, "PictureUrl": this.UserDetails.PictureUrl }]
          });
          document.getElementById('Comment_' + pst.PostID)["value"] = "";
          this.posted = pst.PostID;
          pst.IsEditable =false;
        }
      );
    }
  }

  AddPostComment_ON(Comment1: any, PostID: number) {
    let NewComment: any = {};
    if (document.getElementById('Comment_ON_' + Comment1.CommentID)["value"] != "") {
      NewComment.CommentID = Comment1.CommentID;
      NewComment.PostID = PostID
      NewComment.CommentText = document.getElementById('Comment_ON_' + Comment1.CommentID)["value"];
      this.serviceObj.postArbData('AddWallComment', NewComment).subscribe(
        ArbApiResponse => {
          const d: Date = new Date();
          Comment1.Replies.push({
            "CommentID": 0, "Comment": NewComment.CommentText, "CommentDateTime": d.toString(),
            "CommentedBy": [{ "FirstName": this.UserDetails.FirstName, "PictureUrl": this.UserDetails.PictureUrl }]
          });
          document.getElementById('Comment_ON_' + Comment1.CommentID)["value"] = "";
        }
      );
    }

  }
  AddPostLike(pst: any) {
    if (this.UserDetails) {
      if (!pst.HasLiked && !pst.HasDisLiked) {
        pst.HasLiked = true;
        this.NewComment.PostID = pst.PostID;
        this.serviceObj.postArbData('AddLike', this.NewComment).subscribe(
          ArbApiResponse => {
            this.NewComment.PostID = 0;
            this.NewComment.CommentText = '';
            pst.Likes = pst.Likes + 1;
            pst.IsEditable =false;
          }
        );
      }

    }
    else {
      document.getElementById('plusLoginBtn').click();
    }
  }

  AddPostDisLike(pst: any) {
    if (this.UserDetails) {
      if (!pst.HasLiked && !pst.HasDisLiked) {
        pst.HasDisLiked = true;
        this.NewComment.PostID = pst.PostID;

        this.serviceObj.postArbData('AddDisLike', this.NewComment).subscribe(
          ArbApiResponse => {
            this.NewComment.PostID = 0;
            this.NewComment.CommentText = '';
            pst.DisLikes = pst.DisLikes + 1;
            pst.IsEditable =false;
          }
        );
      }
    }
    else {
      document.getElementById('plusLoginBtn').click();
    }
  }

  PostCommentToggle(PostID: number) {
    if (this.posted != PostID) {
      this.posted = PostID
    } else {
      this.posted = 0;
    }

  }

  FirstCommentToggle(Comment: any) {
    if (this.CommentID != Comment.CommentID) {
      this.CommentID = Comment.CommentID
    } else {
      this.CommentID = 0;
    }

  }

  MyTimeLine() {
    if (this.UserDetails) {
      this.router.navigate(['/MyTimeLine'])
    }
    else {
      document.getElementById('plusLoginBtn').click();
    }
  }

  ChatOnline(){

    
  }

  
  LikedUsers(pst:any,Type:string)
  {
    if (this.UserDetails!=null) 
      {
        if(Type=='L' && pst.Likes==0)
        {
          if(this.TimeLineContent)
          document.getElementById('divMessage').innerHTML =this.TimeLineContent.Nolikefound
          document.getElementById('btnMessageModal').click();
        }
        else if(Type=='D' && pst.DisLikes==0)
        {if(this.TimeLineContent)
          document.getElementById('divMessage').innerHTML = this.TimeLineContent.Nodislikefound;
          document.getElementById('btnMessageModal').click();
        }else
        {
          let LikedObj:any={};
          LikedObj.PostID=pst.PostID
          LikedObj.IsLiked=(Type=='L')?true:false;
          this.serviceObj.getArbData('WallPostLikedUsers', LikedObj).subscribe(
            ArbApiResponse => {
              if(ArbApiResponse["Status"]==1)
              {
                  this.LikedUsersList = ArbApiResponse["data"];
              }
            },
            err => console.error(err)
          );
       }
      }
    else
    {
      document.getElementById('plusLoginBtn').click();
    }
  }

  ShowPostImage(pst:any,img:any)
  {
    pst.PostImgURL=img.Image;
  }
}
