import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.css']
})

export class ExchangeComponent implements OnInit {
  UserDetails: any = {}
  ExItems: any = [];
  SearchObj: any = {};
  ExchangeRequest: any = {};
  ExchangeContent: any = {};
  showIntr: boolean = true;
  showMile: boolean = true;
  ExchangeCatagories: any = [];
  ExchangeSubCatagories: any = [];
  show: boolean = true;
  morePagesAvailable: boolean = false;
  IsLoading: boolean = true;

  constructor(private serviceObj: ArabesqueService, private router: Router) {
    this.SearchObj.PageIndex = 1;
    this.SearchObj.ItemType = 'All';
    this.SearchObj.SearchText = '';
    this.SearchObj.SortBy = 'Latest';
    this.SearchObj.Distance = 0;
    this.SearchObj.IsTimeline = false;
    this.SearchObj.Latitude = '';
    this.SearchObj.Longitude = '';
    this.SearchObj.CategoryID = 0;
    this.SearchObj.SubCategoryID = 0;

    this.ExchangeRequest.ItemID = '';
    this.ExchangeRequest.OfferText = '';
  }

  RefreshUD() 
  { 
    this.UserDetails = this.serviceObj.getUD(); 
    this.ExchangeContent=this.serviceObj.GetPageSpecificContent('Exchange');
  }

  ngOnInit() {
    this.IsLoading = true;
    this.UserDetails = this.serviceObj.getUD(); 
    this.ExchangeContent=this.serviceObj.GetPageSpecificContent('Exchange');

    if (this.UserDetails != null) {
      if (this.UserDetails.IsBasicFilled != 0 && this.UserDetails.IsBasicFilled != '') {
        document.getElementById('divMessage').innerHTML = this.UserDetails.IsBasicFilled;
       $("#MessageModal1").modal({ backdrop: 'static', keyboard: false});
      }
    }
    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';
      debugger;
      this.SearchExchangeItems(this.SearchObj.ItemType, false);
    // this.serviceObj.getArbData('ExItems', this.SearchObj).subscribe(
    //   data => {
    //     this.IsLoading = false;
    //     this.ExItems = data["data"];
    //   }
    // );
    this.serviceObj.getArbData('ExchangeCategories', '').subscribe(
      ArbApiResponse => {
        this.ExchangeCatagories = ArbApiResponse["data"]["Categories"];
      }
    );
    // this.serviceObj.getArbData('RecommendedItems', this.SearchObj).subscribe(
    //   ArbApiResponse => {
    //     this.ExchangeCatagories = ArbApiResponse["data"];  
    //   }
    // );
    document.getElementById('btnLoadMap').click();
  }

  toggleIntr() {
    this.showIntr = !this.showIntr;
  }

  FilterItem() {
    this.SearchObj.ItemType = 'All';

    this.SearchExchangeItems(this.SearchObj.ItemType, false);
   }

  toggle() {
    this.show = !this.show;
  }

  onCategorySelect(vlue) {
    this.SearchObj.SubCategoryID = 0;
    var selindex = vlue.split(":")[0];
    if (selindex == 0) {
    }
    else {
      this.ExchangeSubCatagories = this.ExchangeCatagories[this.SearchObj.CategoryID - 1]["Subcategories"];
    }
  }

  GetNextPage() {
    if (this.morePagesAvailable) {
      this.SearchObj.PageIndex += 1;
      this.SearchExchangeItems(this.SearchObj.ItemType, true);
    }
  }

  SearchExchangeItems(tyValue, IsLazyLoading: boolean) {
    this.IsLoading = true;
    if (IsLazyLoading == null || IsLazyLoading == false) {
      this.SearchObj.PageIndex = 1;
      this.ExItems = [];
    }

    if (tyValue != null)
      this.SearchObj.ItemType = tyValue;

    if (this.SearchObj.Distance > 0) {
      this.SearchObj.Latitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[0];
      this.SearchObj.Longitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[1];
    }
    else {
      this.SearchObj.Latitude = '';
      this.SearchObj.Longitude = '';
    }

    this.serviceObj.getArbData('ExItems', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.IsLoading = false;
        if (ArbApiResponse["data"].length > 0) {
          if (this.SearchObj.PageIndex == 1) {
            this.ExItems = ArbApiResponse["data"];
           
          }
          else {
            for (var p = 0; p < ArbApiResponse["data"].length; p++)
              this.ExItems.push(ArbApiResponse["data"][p]);
          }

          if (ArbApiResponse["data"].length < 10)
          this.morePagesAvailable = false;
        else
          this.morePagesAvailable = true;
        }
        else {
          this.morePagesAvailable = false;
        }
      },
      err => {
        this.IsLoading = false;
        this.morePagesAvailable = false;
        console.error(err);
      }
    );
  }

  toggleMile() {
    this.showMile = !this.showMile;
  }

  Edit(Items: any) {
    this.serviceObj.SetData(Items);
    this.router.navigate(['/AddExchange'])
  }

  showInterest(Item: any) {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      if (!Item.HasShownInterest) {
        this.ExchangeRequest.OfferText = '';
        this.ExchangeRequest.ItemID = Item.ItemID;
        this.serviceObj.postArbData('SendExchangeRequest', this.ExchangeRequest).subscribe(
          ArbApiResponse => {
            Item.HasShownInterest = true;
          },
          ArbApiErr => {
          }
        );
      }
    }
  }
  
  SetASFavorite(exchangeItem:any)
  {
    if (this.UserDetails == null) 
         document.getElementById('plusLoginBtn').click();
  else{
    let MyFav:any={};
    MyFav.ItemID=exchangeItem.ItemID;
    if(exchangeItem.Favorite=='Y')
    MyFav.Status='N'
    else
    MyFav.Status='Y'
    
    this.serviceObj.postArbData('AddWishList', MyFav).subscribe(
      ArbApiResponse => {
        if(ArbApiResponse["Status"]==1)
        {
            if(exchangeItem.Favorite=='Y')
            {
              exchangeItem.Favorite='N';
            }else
            exchangeItem.Favorite='Y';
      }
      document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
      document.getElementById('btnMessageModal').click();
      },
    );
  }

  }

  //End Clsaa
}
