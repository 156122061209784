import { Component, OnInit, TemplateRef } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Route, ActivatedRoute } from '@angular/router'
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
declare var $:any;

@Component({
  selector: 'app-exchange-view',
  templateUrl: './exchange-view.component.html',
  styleUrls: ['./exchange-view.component.css']
})

export class ExchangeViewComponent implements OnInit {
  UserDetails: any = {}
  ExItem: any = [];
  SearchObj: any = [];
  Suggested: any = [];
  ExchangeRequest: any = {};
  isValid: boolean = true;
  ImageList: any = [];
  ExchangeItem: any = {};
  isOK: boolean = false;
  isOKInterested: boolean = false;
  modalRef: BsModalRef;
  ItemRecommendation: any = [];
  RecommendedItemsList: any = [];
  UsersInterestedInItemList: any = [];
  searchText: string = '';
  selectAll: boolean = true;
  checkbox: any = false;
  slides = [{ image: 'assets/images/product1.jpg' }, { image: 'assets/images/product1.jpg' },{ image: 'assets/images/product1.jpg' } ];
  Favorite:string='#ef1c04e8';
  MyConnectionsList: any = [];
  showIndicator = true;
  SearchByName: string
  NewRecommendation: any = {};
  SameUserItems: any = {};
  selectedAll: any;
  RequestLogList: any = [];
  TempID:string='';
  ExchangeContent: any = {};

  constructor(private serviceObj: ArabesqueService, private route: ActivatedRoute, private modalService: BsModalService) {
    this.SearchObj.ItemID =0;
    this.ExItem.ID=0;
    this.ExItem.EncryptedID='';
    this.SearchObj.EncryptedID ='';
    this.SearchObj.All = true;
    this.SearchObj.SearchText = '';

    this.ExchangeRequest.OfferText = '';
    this.ExchangeRequest.ItemID =0;
    this.ExchangeRequest.EncryptedID='';
    this.ExchangeRequest.OfferPrice =0;
    this.ExchangeRequest.OfferCoin = 0;
    this.NewRecommendation.ItemID =0;

    this.route.params.subscribe(params => this.TempID = params.ID);
    //this.ExchangeRequest.ItemID = this.ExItem.ID;
    this.ExchangeRequest.EncryptedID = this.TempID;
    this.ExchangeRequest.isCoin = true;
    //this.SearchObj.ID = this.ExItem.ID;
    this.SearchObj.EncryptedID = this.TempID;
    this.NewRecommendation.EncryptedID = this.TempID;
    this.ExItem.EncryptedID=this.TempID;

    this.NewRecommendation.ItemID = this.ExItem.ID;
    this.NewRecommendation.UserIDs = '';
    this.NewRecommendation.isWallPost = false;
    this.NewRecommendation.PostText = '';

    this.SameUserItems.UserID = 0;
    this.SameUserItems.ItemID = 0;
    this.SameUserItems.EncryptedID =this.TempID;
    this.ExItem.Longitude = '';
    this.ExItem.Latitude = '';
    document.getElementById('btnLoadMap').click();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  RefreshUD() {
    this.ngOnInit();
  }

  ngOnInit() {
    this.UserDetails = this.serviceObj.getUD();
    this.ExchangeContent=this.serviceObj.GetPageSpecificContent('Exchange'); 
    
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
        document.getElementById('btnMessageModal1').click();
      }
    }
    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

      if (document.getElementById('txtAddressCoords') != null && document.getElementById('txtAddressCoords')["value"] != '' && this.SearchObj.Distance > 0) {
        this.ExItem.Latitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[0];
        this.ExItem.Longitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[1];  
      } 
      else {
        var LD= JSON.parse(sessionStorage.getItem('LD'));
        if(LD!=null)
          {	
            this.ExItem.Latitude = LD['Latitude'];
            this.ExItem.Longitude = LD['Longitude'];
          }
      }

    this.serviceObj.getArbData('GetItemDetails', this.ExItem).subscribe(
      ArbApiResponse => {
        this.ExchangeItem = ArbApiResponse["data"];
        this.SameUserItems.UserID = ArbApiResponse["data"]["PersonID"];
        this.Suggested = ArbApiResponse["data"]["SuggestedItems"];
        if (this.ExchangeItem.HasShownInterest) {
          this.isOKInterested = true;
        }
        if (this.ExchangeItem.ExchangedFor3 > 0) {
          this.ExchangeRequest.isCoin = false;
          this.ExchangeRequest.OfferPrice = this.ExchangeItem.ExchangedFor3;
        }
        if (this.ExchangeItem.Coin > 0) {
          this.ExchangeRequest.isCoin = true;
          this.ExchangeRequest.OfferCoin = this.ExchangeItem.Coin;
        }
        
        //---SameUserItems
        this.serviceObj.getArbData('SameUserItems', this.SameUserItems).subscribe(
          ArbApiResponse => {
            this.RecommendedItemsList = ArbApiResponse["data"];
          }

        );
      
      }
    );

    this.serviceObj.getArbData('GetUsersInterestedInItem', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.UsersInterestedInItemList = ArbApiResponse["data"];
      }
    );
    

  }
  switchIndicator(): void 
  {
    this.showIndicator = !this.showIndicator;
  }

  SendExchangeRequest(vlue) 
  {
    if (this.UserDetails == null) 
    {
      document.getElementById('plusLoginBtn').click();
    }
    else 
    {
            if (this.ExchangeItem.Owner.ID == this.UserDetails.ID) 
            {
              if(this.ExchangeContent)
              document.getElementById('divMessage').innerHTML =this.ExchangeContent.Youcannotshow;
              document.getElementById('btnMessageModal').click();
            }
            else 
            {
                //(ExchangeRequest.isCoin==true) && ExchangeItem.Coin>0
              // if((this.ExchangeRequest.isCoin==true && this.ExchangeItem.Coin>this.UserDetails.CoinBalance)||
              // (this.ExchangeRequest.isCoin==false && this.ExchangeItem.ExchangedFor3>0))
              // {
                this.ExchangeRequest.ItemID =0;
              this.serviceObj.postArbData('SendExchangeRequest', this.ExchangeRequest).subscribe(
                ArbApiResponse => 
                {
                  if (ArbApiResponse["Status"] == 1) 
                  {
                    this.isOKInterested = true;
                    this.isOK = true;
                    this.ExchangeItem.HasShownInterest = true;
                    this.SearchObj.ItemID =0;
                    document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
                    this.serviceObj.getArbData('GetUsersInterestedInItem', this.SearchObj).subscribe(
                      ArbApiResponse => {
                        this.UsersInterestedInItemList = ArbApiResponse["data"];
                      }
                    );
                  }
                  else 
                  {
                    document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
                  }
                  document.getElementById('sendOfferPopup').click();
                  document.getElementById('btnMessageModal').click();
                }
              );
            //}
            }
    }

  }

  SendInterstt() 
  {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      if (this.ExchangeItem.Owner.ID == this.UserDetails.ID) 
      {
         if(this.ExchangeContent)
        document.getElementById('divMessage').innerHTML =this.ExchangeContent.Youcannotshow;
        document.getElementById('btnMessageModal').click();
      }
      else if (this.ExchangeItem.ExpireOn == 'Expired') 
      {
        if(this.ExchangeContent)
        document.getElementById('divMessage').innerHTML =this.ExchangeContent.YoucannotshowEx;
        document.getElementById('btnMessageModal').click();
      }
      else 
      {
        if (!this.ExchangeItem.HasShownInterest) {
          this.ExchangeRequest.OfferText = '';
          this.ExchangeRequest.isCoin = false;
          this.ExchangeRequest.ItemID =0;
          this.serviceObj.postArbData('SendExchangeRequest', this.ExchangeRequest).subscribe(
            ArbApiResponse => {
              if (ArbApiResponse["Status"] == 1) {
                this.isOKInterested = true;
                this.ExchangeItem.HasShownInterest = true;
                document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
                document.getElementById('btnMessageModal').click();
                this.SearchObj.ItemID =0;
                this.serviceObj.getArbData('GetUsersInterestedInItem', this.SearchObj).subscribe(
                  ArbApiResponse => {
                    this.UsersInterestedInItemList = ArbApiResponse["data"];
                  }
                );
              } else {
                document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
                document.getElementById('btnMessageModal').click();
              }

            }
          );
        }
        else {
          if(this.ExchangeContent)
          document.getElementById('divMessage').innerHTML =this.ExchangeContent.Interestisalready;// 'Your Interest already sent.';
          document.getElementById('btnMessageModal').click();
        }
      }
    }

  }

  GetMyConnections() 
  {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      if (this.ExchangeItem.Owner.ID == this.UserDetails.ID) {
        if(this.ExchangeContent)
        document.getElementById('divMessage').innerHTML = this.ExchangeContent.Youcannotrecommend //'You can not recommend your Item';
        document.getElementById('btnMessageModal').click();
      } else {
        this.serviceObj.getArbData('MyConnections', null).subscribe(
          ArbApiResponse => {
            this.MyConnectionsList = ArbApiResponse["data"];
            document.getElementById('btnRecommendModal').click();
                    }
        );
      }

    }
  }

  sendOfferPopup() 
  {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      if (this.ExchangeItem.Owner.ID == this.UserDetails.ID) {

        if(this.ExchangeContent)
        document.getElementById('divMessage').innerHTML =this.ExchangeContent.Youcannotshow;
        document.getElementById('btnMessageModal').click();
      }
      else {
        document.getElementById('btnsendOfferPopup').click();
     }
    }

  }
  // Offer Log
    requestofferlog() 
    {
      if (this.UserDetails == null) {
        document.getElementById('plusLoginBtn').click();
      }
      else 
      {
          if (this.ExchangeItem.Owner.ID == this.UserDetails.ID) 
          {
            if(this.ExchangeContent)
            document.getElementById('divMessage').innerHTML = this.ExchangeContent.Nooffer;//No offer log on your Item';
            document.getElementById('btnMessageModal').click();
          }
          else 
          {
            this.SearchObj.ItemID =0;
            this.serviceObj.getArbData('GetOfferLog', this.SearchObj).subscribe(
              ArbApiResponse => {
                this.RequestLogList = ArbApiResponse["data"];
              },
              err => console.error(err)
            );
          }
      }

  }


  SendItemRecommendation() 
  {
    this.NewRecommendation.UserIDs = '';

    for (let r of this.MyConnectionsList.Connected) {
      if (r.IsReceivingRecommendation) {
        if (this.NewRecommendation.UserIDs == "")
          this.NewRecommendation.UserIDs += r.ID;
        else
          this.NewRecommendation.UserIDs += "," + r.ID;
      }
    }

    if (this.NewRecommendation.UserIDs != '' || this.NewRecommendation.isWallPost == true) 
    {
      document.getElementById('divErr').innerHTML = '';
      this.NewRecommendation.PostText = this.ExchangeItem.ItemName;
      this.NewRecommendation.ItemID =0;
      this.serviceObj.postArbData('SendItemRecommendation', this.NewRecommendation).subscribe(
        ArbApiResponse => 
        {
        if(ArbApiResponse["Status"]==1)
        {
          document.getElementById('btnRecommendModal').click();
          document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          document.getElementById('btnMessageModal').click();
        }
        },
        ArbApiErr => {
        }
      );
    }
    else 
    {
      if(this.ExchangeContent)
      document.getElementById('divErr').innerHTML = this.ExchangeContent.connectionorOnP;
    }

  }

  // showInterest(Item: any) 
  // {
  //   if (this.UserDetails == null) {
  //     document.getElementById('plusLoginBtn').click();
  //   }
  //   else {
  //     if (!Item.HasShownInterest) {
  //       if (this.ExchangeItem.Owner.ID == this.UserDetails.ID) {

  //         if(this.ExchangeContent)
  //       document.getElementById('divMessage').innerHTML = this.ExchangeContent.Youcannotrecommend 
  //         document.getElementById('btnMessageModal').click();
  //       }
  //       this.ExchangeRequest.OfferText = '';
  //       this.ExchangeRequest.ItemID = Item.ItemID;

  //       this.serviceObj.postArbData('SendExchangeRequest', this.ExchangeRequest).subscribe(
  //         ArbApiResponse => {
  //           Item.HasShownInterest = true;
  //         },
  //         ArbApiErr => {
  //         }
  //       );
  //     }
  //   }
  // }

  selectAlls() 
  {
    for (var i = 0; i < this.MyConnectionsList.Connected.length; i++) {
      this.MyConnectionsList.Connected[i].IsReceivingRecommendation = this.selectedAll;
    }
  }
  checkIfAllSelected() 
  {
    this.selectedAll = this.MyConnectionsList.Connected.every(function (item: any) {
      return item.IsReceivingRecommendation == true;
    })
  }

  fullView()
  {
    document.getElementById('btnimageCropDiaglog').click();
  }

  imageZoom(imgID, resultID) 
  {
          var img, lens, result, cx, cy;
          img = document.getElementById('myimage_'+imgID);
          result = document.getElementById('myresult');
          /*create lens:*/
          lens = document.createElement("DIV");
          lens.setAttribute("class", "img-zoom-lens");
          /*insert lens:*/
          img.parentElement.insertBefore(lens, img);
          /*calculate the ratio between result DIV and lens:*/
          cx = result.offsetWidth / lens.offsetWidth;
          cy = result.offsetHeight / lens.offsetHeight;
          /*set background properties for the result DIV:*/
         // result.style.display = "block";
          result.style.backgroundImage = "url('" + img.src + "')";
          result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
          /*execute a function when someone moves the cursor over the image, or the lens:*/
          lens.addEventListener("mousemove", moveLens);
          img.addEventListener("mousemove", moveLens);
          /*and also for touch screens:*/
          lens.addEventListener("touchmove", moveLens);
          img.addEventListener("touchmove", moveLens);
          function moveLens(e) {
            var pos, x, y;
            /*prevent any other actions that may occur when moving over the image:*/
            e.preventDefault();
            /*get the cursor's x and y positions:*/
            pos = getCursorPos(e);
            /*calculate the position of the lens:*/
            x = pos.x - (lens.offsetWidth / 2);
            y = pos.y - (lens.offsetHeight / 2);
            /*prevent the lens from being positioned outside the image:*/
            if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
            if (x < 0) { x = 0; }
            if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
            if (y < 0) { y = 0; }
            /*set the position of the lens:*/
            lens.style.left = x + "px";
            lens.style.top = y + "px";
            /*display what the lens "sees":*/
            result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
          }
          function getCursorPos(e) {
            var a, x = 0, y = 0;
            e = e || window.event;
            /*get the x and y positions of the image:*/
            a = img.getBoundingClientRect();
            /*calculate the cursor's x and y coordinates, relative to the image:*/
            x = e.pageX - a.left;
            y = e.pageY - a.top;
            /*consider any page scrolling:*/
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            return { x: x, y: y };
          }
  }

  // SetASFavorite(exchangeItem:any)
  // {
  //   if(exchangeItem.Favorite=='Y'){
  //     this.ExchangeItem.Favorite='N';
  //   }else
  //   this.ExchangeItem.Favorite='Y';

  // }
  SetASFavorite(exchangeItem:any)
  {
     if (this.UserDetails == null) 
            document.getElementById('plusLoginBtn').click();
      else{
        let MyFav:any={};
        MyFav.ItemID=exchangeItem.ID;
        if(exchangeItem.Favorite=='Y')
        MyFav.Status='N'
        else
        MyFav.Status='Y'
        
        this.serviceObj.postArbData('AddWishList', MyFav).subscribe(
          ArbApiResponse => {
            if(ArbApiResponse["Status"]==1)
            {
                if(exchangeItem.Favorite=='Y')
                {
                  exchangeItem.Favorite='N';
                }else
                exchangeItem.Favorite='Y';
          }
          document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          document.getElementById('btnMessageModal').click();
          },
        );
      }
}
}
