import { Component, OnInit} from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Router,NavigationEnd,NavigationStart,ActivatedRoute  } from '@angular/router';
import { Location, PopStateEvent } from "@angular/common";
import { ImageCroppedEvent } from '../../../node_modules/ngx-image-cropper';
declare var $:any;

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.css']
})

export class CreateGroupComponent implements OnInit {
  NewGroup: any = {};
  isDisablePublish:boolean=false;
  addCoverPicValidation:string='';
  isEdit:number=0
  HobbyName:string='';
  InviteEvent: any = {};
  ErrorMessage: string = '';
  GroupsCategory: any = [];
  MyList: any = [];
  show: boolean = true;
  PublisList: any = [];
  isStart: boolean = false;
  isNext: boolean = true;
  isNext1: Boolean = true;
  UserDetails: any = {}
  isValid: Boolean = true;
  isValidHobby:boolean=true;
  isValidKey: Boolean = true;
  imageUrl: string = '';
  fileToUpload: File = null;
  Basic: string = 'pl-3 pb-3 position-relative sle ActiveS';
  Product: string = 'pl-3 pb-3 position-relative sle';
  Publish: string = 'pl-3 pb-3 position-relative sle';
  MyConnectionsList:any=[];
  OtherPeopleList:any=[];
  SocialConnecttionList:any=[];
  UserInterestCategories:any=[];
  searchText:string='';
  SearchObj:any={};
  AllPeople:any={};
  isWallPost:boolean=false;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  data1:any;
  TempImageArr:any=[];
  ID:number=1;
  data2:any;
  tempImag:any='';
  EnteredKeywords:string='';
  GropupContent:any={};
  isValidDescription: boolean = true;
  filesize:number=100;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(private serviceObj: ArabesqueService,private route:Router,private ar:ActivatedRoute,private location: Location) {
    this.NewGroup.GroupID = 0;
    this.NewGroup.GroupName = '';
    this.NewGroup.CategoryID = 0;
    this.NewGroup.HobbyID=0;

    this.NewGroup.Category ='';
    this.NewGroup.Keywords = [];
    this.NewGroup.KeyW ='';
    this.NewGroup.Description = '';
    this.NewGroup.Joinability = true;
    this.NewGroup.IsPrivate = false;
    this.NewGroup.isWallPost=false;
    this.NewGroup.isVisible = true;

    this.InviteEvent.EmailTo = '';
    this.InviteEvent.Subject = '';
    this.InviteEvent.InviteMessage = '';
    
    this.ar.params.subscribe( params => this.isEdit =params.ID );
    this.SearchObj.PageIndex = 0;

    this.AllPeople.UserIDs='';
    this.AllPeople.Emails='';
    this.AllPeople.Subject='';
    this.AllPeople.Message='';
  

  this.data2 = {};
  
  }

  RefreshUD() 
  { 
    this.UserDetails = this.serviceObj.getUD();
    if(this.UserDetails==null)
    this.route.navigate(['/Home', 0]);
    this.GropupContent=this.serviceObj.GetPageSpecificContent('Group');
  }

  
  fileChangeEvent(ev: any): void 
  {
    let file:FileList=ev.target.files
    if(file.length>0)
    {
         this.fileToUpload=file.item(0);
          var reader=new FileReader();
          reader.onload=(event:any)=>{
            document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
            setTimeout(() => {  
              var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
              if(wdth>380)
              this.filesize=380
              else
              this.filesize=wdth
              this.imageChangedEvent = ev;
            }, 2000);
             
          }
          reader.readAsDataURL(this.fileToUpload);
        }
    }

    imageCropped(event: ImageCroppedEvent) 
    {
      this.croppedImage = event.base64;
    }
    imageLoaded() 
    {
        // show cropper
    }
    loadImageFailed() {
        // show message
    }
ngOnInit() 
{
  this.UserDetails = this.serviceObj.getUD();
  this.GropupContent=this.serviceObj.GetPageSpecificContent('Group');
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
        document.getElementById('btnMessageModal1').click();
      }
    }
    if (window.location.href.indexOf('/Home') > 0)
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
  else
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader';
        this.location.subscribe((ev:PopStateEvent) => {
          this.lastPoppedUrl = ev.url;
      });
      this.route.events.subscribe((ev:any) => {
          if (ev instanceof NavigationStart) {
              if (ev.url != this.lastPoppedUrl)
                  this.yScrollStack.push(window.scrollY);
          } else if (ev instanceof NavigationEnd) {
              if (ev.url == this.lastPoppedUrl) {
                  this.lastPoppedUrl = undefined;
                  window.scrollTo(0, this.yScrollStack.pop());
              } else
                  window.scrollTo(0, 0);
          }
      });
    this.serviceObj.getArbData('GetGroupCategories', '').subscribe(
      ArbApiResponse => {
        this.GroupsCategory = ArbApiResponse["data"];
      }
    );
    if(this.isEdit==1){
      this.serviceObj.getData.subscribe(      
       u_data => {
        if(u_data!='0') {  
        this.NewGroup=u_data;
        this.imageUrl=u_data["Logo"];
         this.isWallPost=true;
        this.NewGroup.isWallPost=false;
        if(this.NewGroup.HobbyID==null)
        this.NewGroup.HobbyID=0;
        if(this.NewGroup.Visibility==null)
        this.NewGroup.isVisible = true;
        else
        this.NewGroup.isVisible=this.NewGroup.Visibility;
        if(this.NewGroup.Keywords!='')
        {
          this.NewGroup.Keywords=this.serviceObj.GetSplitArray(this.NewGroup.Keywords);
        }
        }
      }
     );
    }



   this.serviceObj.getArbData('MyConnections', this.SearchObj).subscribe(
        ArbApiResponse => {
          this.MyConnectionsList = ArbApiResponse["data"];
          this.SocialConnecttionList = ArbApiResponse["data"]["Connected"];
      }
    );

    this.serviceObj.getArbData('OtherPeople', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.OtherPeopleList = ArbApiResponse["data"];
    }
    );
    this.serviceObj.getArbData('GetUserInterestCategories', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.UserInterestCategories = ArbApiResponse["data"];
    }
    );

  }
  onSelectHobby(event){
    let selindex:number=0;
    selindex = event.split(":")[0];
    if (selindex == 0) {
      this.isValidHobby = false;
    }
    else 
    {
      this.isValidHobby = true;
    }
  }
  onSelect(event) {
    let selindex:number=0;
    selindex = event.split(":")[0];//.trim()
    if (selindex == 0) {
      this.isValid = false;
    }
    else 
    {
      this.isValid = true;
    }
    this.NewGroup.HobbyID=0;

  }
  StartGroup() {
    this.Product = 'pl-3 pb-3 position-relative sle ActiveS';
    if (this.NewGroup.GroupName == '') {

      this.isStart = false;
      this.isNext = true;
      this.isNext1 = true;
    } else {
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
    }
  }
  NextGroupValidation() {
    if (this.NewGroup.CategoryID == 0) {
      this.isValid = false;
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
    } else {
      this.NewGroup.Category =this.GroupsCategory.filter(x => x.CategoryID == this.NewGroup.CategoryID)[0].Category;
      this.isStart = true;
      this.isNext = true;
      this.isNext1 = false;
      this.isValid = true;
    }
    if(this.NewGroup.CategoryID==8)
    {

      if (this.NewGroup.HobbyID == 0) {
            this.isValidHobby = false;
            this.isStart = true;
            this.isNext = false;
            this.isNext1 = true;
          } else {

           this.HobbyName=this.UserInterestCategories.filter(x => x.InterestID ==this.NewGroup.HobbyID)[0].Interest;
            this.isStart = true;
            this.isNext = true;
            this.isNext1 = false;
            this.isValidHobby = true;
    }
  }

    if (this.NewGroup.Keywords.length==0) {
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
      this.isValidKey = false;
    } 
    else 
    {
      this.isStart = true;
      this.isNext = true;
      this.isNext1 = false;
      this.isValidKey = true;
    }
    if (this.NewGroup.Description=='') {
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
      this.isValidDescription = false;
    } 
    else 
    {
      this.isStart = true;
      this.isNext = true;
      this.isNext1 = false;
      this.isValidDescription = true;
    }
    if(this.NewGroup.Description!='' && this.NewGroup.Keywords.length!=0 && this.NewGroup.CategoryID != 0 && this.NewGroup.GroupName != '')
      {
        if(this.NewGroup.CategoryID==8){
          if (this.NewGroup.HobbyID != 0) {
            this.isStart = true;
            this.isNext = true;
            this.isNext1 = false;
          }else{
            this.isStart = true;
            this.isNext = false;
            this.isNext1 = true;
          }

        }
      }
      else
      {
        this.isStart = true;
        this.isNext = false;
        this.isNext1 = true;
      }
      this.EnteredKeywords='';
      for(var x=0;x<this.NewGroup.Keywords.length;x++){
          if(this.EnteredKeywords=='')
          this.EnteredKeywords=this.NewGroup.Keywords[x].value;
          else
          this.EnteredKeywords=this.EnteredKeywords+','+this.NewGroup.Keywords[x].value;
        }
  }
  
  PublishGroup(AlsoSendInvite:boolean) {
    this.isDisablePublish=true;

    //this.NewGroup.Keywords=this.EnteredKeywords;
    this.NewGroup.KeyW =this.EnteredKeywords;
    this.serviceObj.postArbData('AddUpdateGroup', this.NewGroup).subscribe(
      ArbApiResponse => 
      {   
        document.getElementById('divMessage').innerHTML=ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        if(ArbApiResponse["Status"]==1)
         {  
            if(AlsoSendInvite==true)
            {
              this.AllPeople.GroupID=ArbApiResponse["data"];
              this.GetAllConnection();
            }
            this.route.navigate(['/MyGroup'])
         }
        },
      ArbApiErr => {
        this.isDisablePublish=false;
      }
    );
  }
  PrevStarGroup() {
    this.isStart = false;
    this.isNext = true;
    this.isNext1 = true;
  }
  PreviousGroup() {
    this.isStart = true;
    this.isNext = false;
    this.isNext1 = true;
  }

  Send() {
    if (this.InviteEvent.EmailTo == '') {
      this.ErrorMessage = 'Email is Required';
      return false;
    } else {

      this.ErrorMessage = '';
    }
  }

  toggle() {
    this.show = !this.show;
  }

  // fileChangeListener($event) {
  //   var image:any = new Image();
  //   var file:File = $event.target.files[0];
  //   if(file!=undefined){
  //   var myReader:FileReader = new FileReader();
  //   var that = this;
  //   myReader.onloadend = function (loadEvent:any) {
  //       image.src = loadEvent.target.result;
  //       //that.cropper.setImage(image);
  //       //that.xx=loadEvent.target.result;
  //   };
  //   myReader.readAsDataURL(file);
  // }
//}
  closeCro(){
    $('#MdlCropImg').removeClass('show'); 
    $('#MdlCropImg').addClass('fade'); 
    $('body').removeClass('modal-open');
  }

   CropImage(){
    $('#MdlCropImg').removeClass('fade'); 
    $('body').addClass('modal-open');  
    $('#MdlCropImg').addClass('show'); 
   } 
    base64MimeType(encoded) {
        var result = null;
        if (typeof encoded !== 'string') {
          return result;
        }
        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
        if (mime && mime.length) {
          result = mime[1];
        }
        return result;
  }
  SaveImage(){
    if(this.croppedImage!='')
    {
        let blob=this.dataURItoBlob(this.croppedImage);
        let typeimg=this.base64MimeType(this.croppedImage);

        var t=typeimg.split('/')[1];
        let names:string='filename.'+t;
        var file = new File([blob], names, {type: typeimg});
        this.NewGroup.CoverPic=file;
        this.imageUrl=this.croppedImage;

    $('#MdlCropImg').removeClass('show'); 
    $('#MdlCropImg').addClass('fade'); 
    $('body').removeClass('modal-open');
    }
  }

    
   RemoveIMG(){
    this.tempImag='';
   }
   dataURItoBlob(dataURI) {

          var binary = atob(dataURI.split(',')[1]);
          var array = [];
          for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {    type: 'image/jpg'});
    }

  addCoverPic(fileInput: any) {
    let file: FileList = fileInput.target.files
    this.NewGroup.CoverPic = fileInput.target.files;
    this.fileToUpload = file.item(0);
    //show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {

      document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
      setTimeout(() => {  
        var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
        var hgth = document.getElementById('imgDimensionValidation')['naturalHeight'];
        if (wdth == 0 || hgth == 0 || wdth < 899) {
          // show message
          this.addCoverPicValidation='min width  900 or more';
          this.NewGroup.CoverPic='';
          this.imageUrl='';
        }
        else{
          this.addCoverPicValidation='';
          this.imageUrl=event.target.result;
        }
      }, 2000);
     // this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  GetAllConnection(){
    this.AllPeople.Subject='Invitation for Joining peepway Group - ' +this.NewGroup.GroupName +' '+this.InviteEvent.Subject;
    this.AllPeople.Message=this.InviteEvent.InviteMessage;
    this.AllPeople.UserIDs='';
        for(let x of this.MyConnectionsList.Connected){
          if(x.IsReceivingRecommendation==true){
            if(this.AllPeople.UserIDs=='')
            {
              this.AllPeople.UserIDs=this.AllPeople.UserIDs+x.ID;
            }
            else
            {
              this.AllPeople.UserIDs=this.AllPeople.UserIDs+','+x.ID;
            }
          }
      }
      for(let x of this.OtherPeopleList){

        if(x.IsChecked==true){
          if(this.AllPeople.UserIDs=='')
          {
            this.AllPeople.UserIDs=this.AllPeople.UserIDs+x.ID;
          }
          else
          {
            this.AllPeople.UserIDs=this.AllPeople.UserIDs+','+x.ID;
          }
        }
    }
     // this.AllPeople.Other='';InviteEvent.EmailTo
     if(this.InviteEvent.EmailTo!=='')
     {
      this.AllPeople.Emails='';

          if(this.InviteEvent.EmailTo.split(',').length>0){
            for(let y of this.InviteEvent.EmailTo.split(','))
            {
                if(this.AllPeople.Emails=='')
                {
                  this.AllPeople.Emails=this.AllPeople.Emails+y;
                }
                else
                {
                  this.AllPeople.Emails=this.AllPeople.Emails+','+y;
              }
            }
          }
     }

     if(this.AllPeople.Emails!='' || this.AllPeople.UserIDs!='')
     {
     this.serviceObj.postArbData('SendGroupInvitation', this.AllPeople).subscribe(
      ArbApiResponse => {
        document.getElementById('divMessage').innerHTML=ArbApiResponse["Message"];
            document.getElementById('btnMessageModal').click();
      },
      ArbApiErr => {
      }
    );
  }


  }

  /* End class */
}
