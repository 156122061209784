import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
declare var $:any;

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})

export class GroupsComponent implements OnInit {
  UserDetails: any = {}
  GroupItems: any = [];
  GroupCategories: any = [];
  SearchObj: any = {};
  NewJoinRequest: any = {};
  show: boolean = true;
  MostRecommendedGroupsList: any = [];
  rdoAll: boolean = true;
  IsLoading: boolean = true;
  morePagesAvailable: boolean = false;
  GropupContent:any={};

  constructor(private serviceObj: ArabesqueService) {
    this.SearchObj.PageIndex = 1;
    this.SearchObj.SearchText = '';
    this.SearchObj.CategoryIDs = 0;
    this.SearchObj.IsTimeline = false;

    this.NewJoinRequest.GroupID = 0;
  }

  RefreshUD() { this.UserDetails = this.serviceObj.getUD();
    this.GropupContent=this.serviceObj.GetPageSpecificContent('Group'); 
  }

  ngOnInit() {
    this.IsLoading = true;
    this.RefreshUD();

    if (this.UserDetails != null) {
      if (this.UserDetails.IsBasicFilled != 0 && this.UserDetails.IsBasicFilled != '') {
        document.getElementById('divMessage').innerHTML = this.UserDetails.IsBasicFilled;
       // document.getElementById('btnMessageModal').click();
       $("#MessageModal1").modal({ backdrop: 'static', keyboard: false});
      }
    }
    
    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

      this.SearchGroupItems(false);
    

    this.serviceObj.getArbData('GetGroupCategories', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.GroupCategories = ArbApiResponse["data"];
      },
      err => console.error(err)
    );

    this.serviceObj.getArbData('MostRecommendedGroups', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.MostRecommendedGroupsList = ArbApiResponse["data"];
      },
      err => console.error(err)
    );
  }

  GetNextPage() {
    if (this.morePagesAvailable) {
      this.SearchObj.PageIndex += 1;
      this.SearchGroupItems(true);
    }
  }

  SearchGroupItems(IsLazyLoading: boolean) {
    this.IsLoading = true;
    if (IsLazyLoading == null || IsLazyLoading == false) {
      this.SearchObj.PageIndex = 1;
      this.GroupItems = [];
    }

 
      this.serviceObj.getArbData('GroupItems', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.IsLoading = false;
        if (ArbApiResponse["data"].length > 0) 
        {
          if (this.SearchObj.PageIndex == 1) {
            this.GroupItems = ArbApiResponse["data"];
          
          }
          else {
            for (var p = 0; p < ArbApiResponse["data"].length; p++)
              this.GroupItems.push(ArbApiResponse["data"][p]);
          }
          if (ArbApiResponse["data"].length < 10)
          this.morePagesAvailable = false;
        else
          this.morePagesAvailable = true;
        }
        else {
          this.morePagesAvailable = false;
        }
      }
      ,
      err => {
        this.IsLoading = false;
        this.morePagesAvailable = false;
        //console.error(err);
      }
    );
  }

  FilterGroup() {
    this.SearchGroupItems(false);
  }

  toggle() {
    this.show = !this.show;
    this.rdoAll = false;
  }
  CategoryAll() {
    this.show = true;
    this.rdoAll = true;
    this.SearchObj.CategoryIDs = 0
  }

  JoinGroup(Group: any) {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      if (Group.JoinStatus == '') {
        this.NewJoinRequest.GroupID = Group.GroupID;
        this.serviceObj.postArbData('JoinGroup', this.NewJoinRequest).subscribe(
          ArbApiResponse => {
            Group.JoinStatus = 'Sent';
          },
          ArbApiErr => {
          }
        );
      }
    }
  }
  //endClass
}
