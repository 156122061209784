
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterByText'
})

export class FilterQuestion implements PipeTransform {

  transform(items: any[], searchText: string): any[] { 
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      if(it.SearchKey!=null)
         return it.SearchKey.toLowerCase().includes(searchText);
     else 
      return it.Answer.toLowerCase().includes(searchText);
    });
  }
  



}