import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
declare var LanguageSpecificContent: any;
declare var UserSetting: any;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer sk_live_DllByFZMGPfCJqEyrqbD1SsO'

  })
};

@Injectable()
export class ArabesqueService {
  API_BaseURL: any = window.location.href.toLowerCase();
  relativeURL: string;
  responseObj: any = [];
  AUID: any;
  private behaviorSubject = new BehaviorSubject('0');
  getData = this.behaviorSubject.asObservable();

  constructor(private httpClient: HttpClient) {

    if ((this.API_BaseURL.toLowerCase().indexOf('//peepway.com') > 0) || (this.API_BaseURL.toLowerCase().indexOf('//wwww.peepway.com') > 0)) {
    this.API_BaseURL= 'http://api.peepway.com/';
    }
    else if (this.API_BaseURL.toLowerCase().indexOf('//beta.peepway.com') > 0) {
      this.API_BaseURL= 'http://bapi.peepway.com/';
      }
    else if(this.API_BaseURL.toLowerCase().indexOf('//sbeta.peepway.com') > 0){
      this.API_BaseURL= 'http://sbapi.peepway.com/';
    }
    else if(this.API_BaseURL.toLowerCase().indexOf('//goarabesque.com') > 0){
      this.API_BaseURL= 'http://api.goarabesque.com/';
    }
    else
    {
      this.API_BaseURL= 'http://localhost:9084/';
     //	this.API_BaseURL= 'http://api.peepway.com/';
    }
    let Temppath: any = window.location.href;
    if (Temppath.toLowerCase().indexOf("https") == 0) {
      this.API_BaseURL = this.API_BaseURL.toLowerCase().replace('http:', 'https:');
    }
  }

  SetData(message: string) {
    this.behaviorSubject.next(message)
  }

  setUD(UD, isToRemember) {
    localStorage.setItem('UD', JSON.stringify(UD));
    if (isToRemember)
      sessionStorage.setItem('UD', JSON.stringify(UD));
  }

  getUD() {
    return JSON.parse(localStorage.getItem('UD'));
  }

  removeUD() {
    localStorage.removeItem('UD');
    sessionStorage.removeItem('UD');
  }

  setRefCode(RefCode) {
    localStorage.setItem('RefCode', JSON.stringify(RefCode));
  }

  getRefCode() {
    return JSON.parse(localStorage.getItem('RefCode'));
  }

  removeRefCode() {
    localStorage.removeItem('RefCode');
  }
  
  GetSplitArray(Item: any): any {
    let Arraylist: any = [];
    let FinalArr: any = [];
    Arraylist = Item.split(',');
    for (var i = 0; i < Arraylist.length; i++) {
      FinalArr.push({ display: Arraylist[i], value: Arraylist[i] });
    }
    return FinalArr;
  }
  
  GetPageSpecificContent(PageName:string)
  {//LanguageSpecificContent={};
   // LanguageSpecificContent=JSON.parse(localStorage.getItem('LanguageSpecificContent'));
   return LanguageSpecificContent[PageName];
  }

  getArbData(getType: string, qryCriteria: any): any {
    this.AUID = this.getUD();
    this.AUID = (this.AUID == undefined || this.AUID == null || this.AUID["ID"] == null) ? 0 : this.AUID["ID"];
    this.relativeURL = '';

    switch (getType) {
      case 'UserDetails': {
  
        this.relativeURL = 'api/User/UserDetails?T=' + this.AUID+'&Language=' + UserSetting.Language;
        break;
      }
      case 'UserGroups': {
        this.relativeURL = 'api/Groups/UserGroups?T=' + this.AUID;
        break;
      }
      case 'GetUserInterestCategories': {
        this.relativeURL = 'api/People/GetUserInterestCategories?T=' + this.AUID;
        break;
      }
      case 'PageContent': {
        this.relativeURL = 'api/App/PageContent?Language=' + qryCriteria.Language;
        break;
      }
      case 'GetMyPeopleRecommendations': {
        this.relativeURL = 'api/People/GetMyPeopleRecommendations?T=' + this.AUID;
        break;
      }
      case 'GetUserFavoriteItems': {
        this.relativeURL = 'api/Exchange/GetUserFavoriteItems?T=' + this.AUID;
        break;
      }
      case 'GetGroupDetails': {
        this.relativeURL = 'api/Groups/GetGroupDetails?T=' + this.AUID +'&Language=' + UserSetting.Language + '&GroupID=' + qryCriteria.GroupID+ '&EncryptedID=' + qryCriteria.EncryptedID;
        break;
      }
      case 'GetCoinTransaction': {
        this.relativeURL = 'api/User/GetCoinTransaction?T=' + this.AUID + '&GetAllTransaction=' + qryCriteria.GetAllTransaction;
        break;
      }
      case 'CoinOffer': {
        this.relativeURL = 'api/Coins/CoinOffer?T=' + this.AUID  +'&Language=' + UserSetting.Language ;
        break;
      }
      case 'CommonFAQ': {
        this.relativeURL = 'api/User/CommonFAQ?Language=' + UserSetting.Language ;
        break;
      }
      case 'SameUserItems': {
        this.relativeURL = 'api/Exchange/SameUserItems?T=' + this.AUID + '&UserID=' + qryCriteria.UserID + '&ItemID=' + qryCriteria.ItemID+ '&EncryptedID=' + qryCriteria.EncryptedID;
        break;
      }
      case 'GetGroupRecommendations': {
        this.relativeURL = 'api/Groups/GetGroupRecommendations?T=' + this.AUID + '&GroupID=' + qryCriteria.GroupID;
        break;
      }
      case 'WallPostLikedUsers': {
        this.relativeURL = 'api/Timeline/WallPostLikedUsers?T=' + this.AUID + '&PostID=' + qryCriteria.PostID+'&IsLiked=' + qryCriteria.IsLiked;
        break;
      }
      case 'OtherPeople': {
        this.relativeURL = 'api/People/OtherPeople?T=' + this.AUID + '&pi=' + qryCriteria.PageIndex;
        break;
      }
      case 'GetExchangeRequests': {
        this.relativeURL = 'api/Exchange/GetExchangeRequests?T=' + this.AUID;
        break;
      }
      case 'GetGroupRequests': {
        this.relativeURL = 'api/Groups/GetGroupRequests?T=' + this.AUID;
        break;
      }
      case 'GetIncomingRequestsBygroupID': {
        this.relativeURL = 'api/Groups/GetIncomingRequestsBygroupID?T=' + this.AUID + '&GroupID=' + qryCriteria.GroupID;
        break;
      }
      case 'MostRecommendedGroups': {
        this.relativeURL = 'api/Groups/MostRecommendedGroups?T=' + this.AUID +'&Language=' + UserSetting.Language ;
        break;
      }
      case 'GetIncomingRequestsByEventID': {
        this.relativeURL = 'api/Events/GetIncomingRequestsByEventID?T=' + this.AUID + '&EventID=' + qryCriteria.EventID;
        break;
      }
      case 'MostRecommendedEvents': {
        this.relativeURL = 'api/Events/MostRecommendedEvents?T=' + this.AUID +'&Language=' + UserSetting.Language + '&CountryID=' + UserSetting.CountryID;
        break;
      }
      case 'GetEventRecommendations': {
        this.relativeURL = 'api/Events/GetEventRecommendations?T=' + this.AUID + '&EventID=' + qryCriteria.EventID;
        break;
      }
      case 'GetEventRequests': {
        this.relativeURL = 'api/Events/GetEventRequests?T=' + this.AUID;
        break;
      }
      case 'GetRecievedEventInvitations': {
        this.relativeURL = 'api/Events/GetRecievedEventInvitations?T=' + this.AUID;
        break;
      }

      case 'GetEventPosts': {
        this.relativeURL = 'api/Events/GetEventPosts?T=' + this.AUID + '&pi=' + qryCriteria.PageIndex + '&ty=' + qryCriteria.ItemType + '&searchPhrase=' + 
        qryCriteria.SearchText + '&my=' + qryCriteria.IsTimeline + '&EventID=' + qryCriteria.ID;
        break;
      }
      case 'GetGroupPosts': {
        this.relativeURL = 'api/Groups/GetGroupPosts?T=' + this.AUID + '&pi=' + qryCriteria.PageIndex + '&ty=' +
         qryCriteria.ItemType + '&searchPhrase=' + qryCriteria.SearchText + '&my=' + qryCriteria.IsTimeline 
         + '&GroupID=' + qryCriteria.GroupID + '&EncryptedID=' + qryCriteria.EncryptedID;
        break;
      }
      case 'GetEventDetails': {
        this.relativeURL = 'api/Events/GetEventDetails?T=' + this.AUID +'&Language=' + UserSetting.Language + '&ID=' + qryCriteria.EventID + '&EncryptedID=' + qryCriteria.EncryptedID + '&CountryID=' + UserSetting.CountryID;
        break;
      }
      case 'MostRecommendedPeople': {
        this.relativeURL = 'api/People/MostRecommendedPeople?T=' + this.AUID;
        break;
      }
      case 'MyConnections': {
        this.relativeURL = 'api/People/MyConnections?T=' + this.AUID;
        break;
      }
      case 'GetUserDetails': {
        this.relativeURL = 'api/People/GetUserDetails?T=' + this.AUID + '&EncryptedID=' + qryCriteria.EncryptedID + '&ID=0&CountryID=' + UserSetting.CountryID +'&Language=' + UserSetting.Language ;
        break;
      }
      case 'GetAllRecommendationByItem': {
        this.relativeURL = 'api/Exchange/GetAllRecommendationByItem?T=' + this.AUID + '&ItemID=' + qryCriteria.ItemID + '&IsSent=' + qryCriteria.IsSent;
        break;
      }
      case 'GetUsersInterestedInItem': {
        this.relativeURL = 'api/People/GetUsersInterestedInItem?T=' + this.AUID + '&ItemID=' + qryCriteria.ItemID + '&all=' + qryCriteria.All+ '&EncryptedID=' + qryCriteria.EncryptedID;
        break;
      }
      case 'GetUsersInterestedInItemMY': {
        this.relativeURL = 'api/People/GetUsersInterestedInItem?T=' + this.AUID + '&ItemID=' + qryCriteria.ID + '&all=' + qryCriteria.All+ '&EncryptedID=' + qryCriteria.EncryptedID;
        break;
      }
      case 'GetAllRecommendationByPeople': {
        this.relativeURL = 'api/People/GetAllRecommendationByPeople?T=' + this.AUID+ '&PeopleID=' + qryCriteria.PeopleID + '&PeopleID=' + qryCriteria.PeopleID + '&IsSent=' + qryCriteria.IsSent;
        break;
      }
      case 'GetAllRecommendationByGroup': {
        this.relativeURL = 'api/Group/GetAllRecommendationByGroup?T=' + this.AUID + '&SocietyID=' + qryCriteria.SocietyID + '&IsSent=' + qryCriteria.IsSent;
        break;
      }
      case 'GetAllRecommendationByEvent': {
        this.relativeURL = 'api/Event/GetAllRecommendationByEvent?T=' + this.AUID + '&EventID=' + qryCriteria.EventID + '&IsSent=' + qryCriteria.IsSent;
        break;
      }
      case 'UserEvent': {
        this.relativeURL = 'api/Groups/UserGroups?T=' + this.AUID;
        break;
      }
      case 'CreateEvents': {
        this.relativeURL = 'api/Main/Master/LoginStatus';
        break;
      }
      case 'GetGroupCategories': {
        this.relativeURL = 'api/Groups/GetGroupCategories?T=' + this.AUID + '&Language='+UserSetting.Language;
        break;
      }
      case 'GetEventCategories': {
        this.relativeURL = 'api/Events/GetEventCategories?T=' + this.AUID + '&Language='+UserSetting.Language;
        break;
      }
      case 'UserInterestCategories': {
        this.relativeURL = 'api/Users/UserInterestCategories';
        break;
      }
      case 'ArabesqueWallCategories': {
        this.relativeURL = 'api/Timeline/ArabesqueWallCategories?T=' + this.AUID+ '&Language='+UserSetting.Language;
        break;
      }
      case 'GetTimelinePosts': {
        this.relativeURL = 'api/Timeline/GetTimelinePosts?T=' + this.AUID + '&Language='+UserSetting.Language + '&CountryID=' + UserSetting.CountryID + '&pi=' + qryCriteria.PageIndex + '&ty=' + qryCriteria.ItemType + '&searchPhrase=' + qryCriteria.SearchText + '&my=' + qryCriteria.IsTimeline + '&CategoryIDs=' + qryCriteria.CategoryIDs;
        break;
      }
      case 'GetWallPosts': {
        this.relativeURL = 'api/Timeline/GetWallPosts?T=' + this.AUID + '&pi=' + qryCriteria.PageIndex + '&searchPhrase=' + qryCriteria.SearchText + '&CategoryIDs=' + qryCriteria.CategoryIDs
          + '&Distance=' + qryCriteria.Distance + '&SortBy=' + qryCriteria.SortBy
           + '&CountryID=' + UserSetting.CountryID  + '&Language='+UserSetting.Language
          + '&Latitude=' + qryCriteria.Latitude + '&Longitude=' + qryCriteria.Longitude
          +'&PostCode='+ qryCriteria.PostCode;
        break;
      }
      case 'InitialData': {
        this.relativeURL = 'api/Main/Society_SocietyManagement/InitialData?itemID=' + qryCriteria.ItemName;
        break;
      }
      case 'InitialTypes': {
        this.relativeURL = 'api/Main/Search_Search/InitialData';
        break;
      }
      case 'ExItems': {
        this.relativeURL = 'api/Exchange/SearchItems?T=' + this.AUID + '&pi=' + qryCriteria.PageIndex + '&ty=' + qryCriteria.ItemType + '&searchPhrase=' + qryCriteria.SearchText + '&my=' + qryCriteria.IsTimeline + '&SortBy=' + qryCriteria.SortBy + '&Latitude=' + qryCriteria.Latitude + '&Longitude=' + qryCriteria.Longitude + '&Distance=' + qryCriteria.Distance
          + '&CategoryID=' + qryCriteria.CategoryID + '&SubCategoryID=' + qryCriteria.SubCategoryID + '&CountryID=' + UserSetting.CountryID;
        break;
      }
      case 'RecommendedItems': {
        this.relativeURL = 'api/Exchange/RecommendedItems?T=' + this.AUID + '&pi=' + qryCriteria.PageIndex + '&ty=' + qryCriteria.ItemType + '&searchPhrase=' + qryCriteria.SearchText + '&my=' + qryCriteria.IsTimeline;
        break;
      }
      case 'GetMyItemRecommendations': {
        this.relativeURL = 'api/Exchange/GetMyItemRecommendations?T=' + this.AUID;
        break;
      }
      case 'ExchangeCategories': {
        this.relativeURL = 'api/Exchange/ExchangeCategories?Language='+UserSetting.Language;
        break;
      }
      case 'SearchUsers': {
        this.relativeURL = 'api/People/SearchUsers?T=' + this.AUID + '&pi=' + qryCriteria.PageIndex + '&searchPhrase=' + qryCriteria.SearchText + '&Distance=' + qryCriteria.Distance + '&UserInterestIDs=' + qryCriteria.UserInterestIDs + '&LocationIDs=' + qryCriteria.LocationIDs + '&my=' + qryCriteria.IsTimeline
          + '&Latitude=' + qryCriteria.Latitude + '&Longitude=' + qryCriteria.Longitude+'&CountryID='+UserSetting.CountryID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'GroupItems': {
        this.relativeURL = 'api/Groups/SearchGroups?T=' + this.AUID + '&pi=' + qryCriteria.PageIndex + '&searchPhrase=' + qryCriteria.SearchText + '&my=' + qryCriteria.IsTimeline + '&CategoryIDs=' + qryCriteria.CategoryIDs+'&Language='+UserSetting.Language;
        break;
      }
      case 'SearchEvents': {
        this.relativeURL = 'api/Events/SearchEvents?T=' + this.AUID + '&pi=' + qryCriteria.PageIndex + '&searchPhrase=' + qryCriteria.SearchText + '&StartDate=' + qryCriteria.StartDateFinal + '&EndDate=' + qryCriteria.EndDateFinal
          + '&my=' + qryCriteria.IsTimeline + '&Distance=' + qryCriteria.Distance + '&LocationIDs=' + qryCriteria.LocationIDs
          + '&Latitude=' + qryCriteria.Latitude + '&Longitude=' + qryCriteria.Longitude + '&Language=' + UserSetting.Language +'&CountryID='+UserSetting.CountryID;
        break;
      }
      case 'UserInfo': {
        this.relativeURL = 'api/User/UserInfo?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'CountryAndCity': {
        this.relativeURL = 'api/App/CountryAndCity?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'GetItemDetails': {
        this.relativeURL = 'api/Exchange/GetItemDetails?T=' + this.AUID + '&Language=' + UserSetting.Language +'&CountryID='+UserSetting.CountryID+ '&Latitude=' + qryCriteria.Latitude +
        '&Longitude='+qryCriteria.Longitude+ '&EncryptedID='+qryCriteria.EncryptedID+ '&ID='+qryCriteria.ID;;
        break;
      }
      case 'MySocialConnections': {
        this.relativeURL = 'api/People/MySocialConnections?provider=' + qryCriteria.provider + '&code=' + qryCriteria.code;
        break;
      }
      case 'GetOfferLog': {
        this.relativeURL = 'api/Exchange/RequestLog?T=' + this.AUID + '&ItemID=' + qryCriteria.ID+ '&EncryptedID=' + qryCriteria.EncryptedID + '&LogType=0';;
        break;
      }
      case 'GetOfferLogDetail': {
        this.relativeURL = 'api/Exchange/RequestLog?T=' +  qryCriteria.UsrID + '&ItemID=' + qryCriteria.ID+ '&EncryptedID=' + qryCriteria.EncryptedID + '&LogType=1';
        break;
      }
      case 'GetWallPostComment':{
        this.relativeURL = 'api/Timeline/GetWallPostComment?T=' + this.AUID + '&PostID=' + qryCriteria.PostID +'&CountryID='+UserSetting.CountryID+ '&pi=' + qryCriteria.pi;
        break;
        
      }
      case 'GetUnreadChat':{
        debugger;
        // {
        //   id: ConversationId;
        //   subject?: string;
        //   topicId?: string;
        //   photoUrl?: string;
        //   welcomeMessages?: string[];
        //   custom?: {[name: string]: string };
        //   participants: {
        //     [id: UserId]: {access: "ReadWrite" | "Read", notify: boolean}
        //   };
        //   createdAt: UnixMilliseconds;
        // }
        //Authorization: Bearer sk_live_DllByFZMGPfCJqEyrqbD1SsO
        var headers = new HttpHeaders({'Content-Type':'application/json',
        'Authorization':'Bearer sk_test_Dx8S2KevbDy8isfibyn789KO'});
      let url="https://api.talkjs.com/v1/tLKUC0SW/users/"+this.AUID +"/conversations?unreadsOnly=false";
      //  let url="https://api.talkjs.com/v1/gYvb5eK6/users/"+this.AUID +"/sessions";
       return this.httpClient.get(url, { headers: headers });
       }

      }

    //alert(this.API_BaseURL + this.relativeURL );
    if (this.relativeURL != '') {
           return this.httpClient.get(this.API_BaseURL + this.relativeURL);
    }
    else return null;
  }

  postArbData(postType: string, valueObj: any) {
    this.AUID = this.getUD();
    this.AUID = (this.AUID == undefined || this.AUID == null || this.AUID["ID"] == null) ? 0 : this.AUID["ID"];
    this.relativeURL = '';

    switch (postType) {
      case 'Register': {
        this.relativeURL = 'api/User/Register?Language=' + UserSetting.Language;
        break;
      }
      case 'AskForReferralCode': {
        this.relativeURL = 'api/User/AskForReferralCode?Language=' + UserSetting.Language+'&CountryID='+UserSetting.CountryID;;
        break;
      }
      case 'Login': {
        this.relativeURL = 'api/User/Login?Language=' + UserSetting.Language;
        break;
      }
      case 'PasswordReset': {
        this.relativeURL = 'api/User/PasswordReset?Language=' + UserSetting.Language;
        break;
      }
      case 'PublishProduct': {
        this.relativeURL = 'api/Exchange/AddUpdateItem?T=' + this.AUID + '&Language=' + UserSetting.Language + '&CountryID='+UserSetting.CountryID;
        break;
      }
      case 'SendGroupInvitation': {
        this.relativeURL = 'api/Groups/SendGroupInvitation?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'AddWishList': {
        this.relativeURL = 'api/Exchange/AddWishList?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeletePostImage': {
        this.relativeURL = 'api/Timeline/DeletePostImage?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'ShareWithFriend': {
        this.relativeURL = 'api/User/ShareWithFriend?T=' + this.AUID  + '&Language=' + UserSetting.Language;
        break;
      }
      case 'SendEventInvitation': {
        this.relativeURL = 'api/Events/SendEventInvitation?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeleteWallPost': {
        this.relativeURL = 'api/Timeline/DeleteWallPost?T=' + this.AUID;
        break;
      }
      case 'RemoveProfileImages': {
        this.relativeURL = 'api/User/RemoveProfileImages?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'SetChatStatus': {
        this.relativeURL = 'api/User/SetChatStatus?T=' + this.AUID;
        break;
      }
      case 'ExitEvent': {
        this.relativeURL = 'api/Events/ExitEvent?T=' + this.AUID+ '&Language=' + UserSetting.Language;
        break;
      }
      case 'EventRating': {
        this.relativeURL = 'api/Events/EventRating?T=' + this.AUID+ '&Language=' + UserSetting.Language;
        break;
      }
      case 'EventRating': {
        this.relativeURL = 'api/Events/EventRating?T=' + this.AUID+ '&Language=' + UserSetting.Language;
        break;
      }
      case 'SupportRequest': {
        this.relativeURL = 'api/User/SupportRequest?T='+ this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'SendConnectionRequest': {
        this.relativeURL = 'api/People/SendConnectionRequest?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'AcceptRejectExchangeRequest': {
        this.relativeURL = 'api/Exchange/AcceptRejectExchangeRequest?T=' + this.AUID;
        break;
      }
      case 'DeleteItemRecommendation': {
        this.relativeURL = 'api/Exchange/DeleteItemRecommendation?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'AcceptRejectGroupRequest': {
        this.relativeURL = 'api/Groups/AcceptRejectGroupRequest?T=' + this.AUID;
        break;
      }
      case 'SavePreferredCategories': {
        this.relativeURL = 'api/User/SavePreferredCategories?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'ApproveRejectEventRequest': {
        this.relativeURL = 'api/Events/ApproveRejectEventRequest?T=' + this.AUID;
        break;
      }
      case 'AcceptRejectPeopleRequest': {
        this.relativeURL = 'api/People/AcceptRejectConnectionRequest?T=' + this.AUID;
        break;
      }
      case 'CancelSentPendingRequest': {
        this.relativeURL = 'api/People/CancelSentPendingRequest?T=' + this.AUID;
        break;
      }
      case 'CancelSentGroupRequest': {
        this.relativeURL = 'api/Groups/CancelSentGroupRequest?T=' + this.AUID+ '&Language=' + UserSetting.Language;
        break;
      }
      case 'ExitGroup': {
        this.relativeURL = 'api/Groups/ExitGroup?T=' + this.AUID+ '&Language=' + UserSetting.Language;
        break;
      }
      case 'SendItemRecommendation': {
        this.relativeURL = 'api/Exchange/SendItemRecommendation?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'SendEventRecommendations': {
        this.relativeURL = 'api/Events/SendEventRecommendations?T=' + this.AUID+ '&Language=' + UserSetting.Language;;
        break;
      }
      case 'SendGroupRecommendation': {
        this.relativeURL = 'api/Groups/SendGroupRecommendation?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'SaveBasicInfo': {
        this.relativeURL = 'api/User/SaveBasicInfo?T=' + this.AUID  + '&Language=' + UserSetting.Language;
        break;
      }
      case 'ChangePassword': {
        this.relativeURL = 'api/User/ChangePassword?T=' + this.AUID + '&Language=' + UserSetting.Language+ '&Code=' ;
        break;
      }
      case 'AddUpdateEducation': {
        this.relativeURL = 'api/User/AddUpdateEducation?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeleteEducation': {
        this.relativeURL = 'api/User/DeleteEducation?T=' + this.AUID ;
        break;
      }

      case 'AddUpdateWorkExperience': {
        this.relativeURL = 'api/User/AddUpdateWorkExperience?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeleteWrokExp': {
        this.relativeURL = 'api/User/DeleteWrokExp?T=' + this.AUID ;
        break;
      }
      case 'AddUpdateSkill': {
        this.relativeURL = 'api/User/AddUpdateSkill?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeleteSkill': {
        this.relativeURL = 'api/User/DeleteSkill?T=' + this.AUID ;
        break;
      }
      case 'AddUpdateTraining': {
        this.relativeURL = 'api/User/AddUpdateTraining?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeleteTraining': {
        this.relativeURL = 'api/User/DeleteTraining?T=' + this.AUID ;
        break;
      }
      case 'AddUpdateLanguage': {
        this.relativeURL = 'api/User/AddUpdateLanguage?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeleteLanguage': {
        this.relativeURL = 'api/User/DeleteLanguage?T=' + this.AUID ;
        break;
      }
      case 'AddUpdateCertification': {
        this.relativeURL = 'api/User/AddUpdateCertification?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeleteCertification': {
        this.relativeURL = 'api/User/DeleteCertification?T=' + this.AUID ;
        break;
      }
      case 'AddUpdateMembership': {
        this.relativeURL = 'api/User/AddUpdateMembership?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeleteMembership': {
        this.relativeURL = 'api/User/DeleteMembership?T=' + this.AUID ;
        break;
      }
      case 'SaveInterests': {
        this.relativeURL = 'api/User/SaveInterests?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'JoinGroup': {
        this.relativeURL = 'api/Groups/JoinGroup?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeleteGroup': {
        this.relativeURL = 'api/Groups/DeleteGroup?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeleteEvent': {
        this.relativeURL = 'api/Events/DeleteEvent?T=' + this.AUID+ '&Language=' + UserSetting.Language;;
        break;
      }
      case 'DeleteConnection': {
        this.relativeURL = 'api/People/DeleteConnection?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'RecommendPerson': {
        this.relativeURL = 'api/People/RecommendPerson?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'MuteConnection': {
        this.relativeURL = 'api/People/MuteConnection?T=' + this.AUID;
        break;
      }
      case 'AddUpdateGroup': {
        this.relativeURL = 'api/Groups/AddUpdateGroup?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'JoinEvent': {
        this.relativeURL = 'api/Events/JoinEvent?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'AddUpdateEvent': {
        this.relativeURL = 'api/Events/AddUpdateEvent?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'CancelEventRequest': {
        this.relativeURL = 'api/Events/CancelEventRequest?T=' + this.AUID+ '&Language=' + UserSetting.Language;;
        break;
      }
      case 'SaveProfileImages': {
        this.relativeURL = 'api/User/SaveProfileImages?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'DeleteItem': {
        this.relativeURL = 'api/Exchange/DeleteItem?T=' + this.AUID  + '&Language=' + UserSetting.Language;
        break;
      }
      case 'CancelPendingRequest': {
        this.relativeURL = 'api/Exchange/CancelPendingRequest?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'SendExchangeRequest': {
        this.relativeURL = 'api/Exchange/SendExchangeRequest?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'AddWallPost': {
        this.relativeURL = 'api/Timeline/AddWallPost?T=' + this.AUID + '&Language='+ UserSetting.Language +'&CountryID='+UserSetting.CountryID;
        break;
      }
      case 'AddWallComment': {
        this.relativeURL = 'api/Timeline/AddWallComment?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'AddLike': {
        this.relativeURL = 'api/Timeline/AddLike?T=' + this.AUID+ '&Language=' + UserSetting.Language;;
        break;
      }
      case 'ReportWallAbuse': {
        this.relativeURL = 'api/Timeline/ReportWallAbuse?T=' + this.AUID+ '&Language=' + UserSetting.Language;;
        break;
      }
      case 'AddDisLike': {
        this.relativeURL = 'api/Timeline/AddDisLike?T=' + this.AUID+ '&Language=' + UserSetting.Language;;
        break;
      }
      case 'AddGroupComment': {
        this.relativeURL = 'api/Groups/AddGroupComment?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'AddGroupPost': {
        this.relativeURL = 'api/Groups/AddGroupPost?T=' + this.AUID;
        break;
      }
      case 'AddGroupComment': {
        this.relativeURL = 'api/Groups/AddGroupComment?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'AddEventPost': {
        this.relativeURL = 'api/Events/AddEventPost?T=' + this.AUID + '&Language=' + UserSetting.Language;
        break;
      }
      case 'AddEventComment': {
        this.relativeURL = 'api/Events/AddEventComment?T=' + this.AUID+ '&Language=' + UserSetting.Language;;
        break;
      }
      case 'RemoveFromGroup': {
        this.relativeURL = 'api/Groups/RemoveFromGroup?T=' + this.AUID+ '&Language=' + UserSetting.Language;;
        break;
      }
    }
    if (this.relativeURL != '') {
      let postableObj = new FormData();
      for (let objProp in valueObj) {
        if (objProp != undefined) {
          if (valueObj[objProp] == "[object FileList]") {
            for (let fileIndex = 0; fileIndex < valueObj[objProp].length; fileIndex++) {
              postableObj.append(objProp + fileIndex, valueObj[objProp][fileIndex]);
            }
          }
          else
            postableObj.append(objProp, valueObj[objProp]);
        }
      }
      return this.httpClient.post(this.API_BaseURL + this.relativeURL, postableObj);

    }
    else
      return null;
  }

}
