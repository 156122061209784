import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { CommonModule, Location, PopStateEvent } from '@angular/common';
import { Route, ActivatedRoute } from '@angular/router'
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-people-view',
  templateUrl: './people-view.component.html',
  styleUrls: ['./people-view.component.css']
})

export class PeopleViewComponent implements OnInit {
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  UserDetails: any = {}
  PeoplePage: any = {};
  SearchObj: any = {};
  PeopleConnectionRequest: any = {};
  UserDetailsList: any = [];
  BasicInfo: any = [];
  isOK: boolean = true;
  MyConnectionsList: any = [];
  NewRecommendation: any = {};
  searchText: string = '';
  selectedAll: any;
  Suggested: any = [];
  Educations:any=[];
  Interests:any=[];
  SectionVisibility:any=[];
  WorkExperiences:any=[];
  SkillDetail:any=[];
  LanguageDetail:any=[];
  TrainingDetail:any=[];
  MemberDetail:any=[];
  CertificationDetail:any=[];

  ShowBackToSearch: boolean = false;
  Item:any={};
  EncryptedID:string='';

  constructor(private serviceObj: ArabesqueService, private route: ActivatedRoute, private router: Router, private location: Location) {
    this.route.params.subscribe(params => this.EncryptedID = params.ID);
    this.UserDetailsList.ConnectionStatus = '';
    this.NewRecommendation.PersonID =0;
    this.NewRecommendation.EncryptedID = this.EncryptedID;
    this.PeopleConnectionRequest.ID=0;
    this.SearchObj.ID=0;
    this.SearchObj.EncryptedID=this.EncryptedID;
    this.Item.ID=0;
    this.Item.EncryptedID=this.EncryptedID;
    this.Item.FirstName='';
    this.Item.Image='';
  }

  RefreshUD() { this.UserDetails = this.serviceObj.getUD();
    this.PeoplePage = this.serviceObj.GetPageSpecificContent('People');
 }

  ngOnInit() {
       this.PeoplePage = this.serviceObj.GetPageSpecificContent('People');
    if (sessionStorage.getItem('PeopleItems') != null)
      this.ShowBackToSearch = true;

    this.UserDetails = this.serviceObj.getUD();
    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

    /*set on top start*/
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });

    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else
          window.scrollTo(0, 0);
      }
    });
    /*set on top start*/

    this.serviceObj.getArbData('GetUserDetails', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.UserDetailsList = ArbApiResponse["data"];
        this.BasicInfo = ArbApiResponse["data"]["User"]["BasicInfo"];
        this.SectionVisibility = ArbApiResponse["data"]["SectionVisibility"];
        this.Suggested = ArbApiResponse["data"]["SuggestedPeople"];
        this.Educations = ArbApiResponse["data"]["User"]["Educations"];
        this.Interests = ArbApiResponse["data"]["User"]["Interests"];
        this.WorkExperiences =ArbApiResponse["data"]["User"]["WorkExperiences"];
        this.SkillDetail=ArbApiResponse["data"]["User"]["Skills"];
        this.LanguageDetail=ArbApiResponse["data"]["User"]["Languages"];
        this.TrainingDetail=ArbApiResponse["data"]["User"]["TrainingCourses"];
        this.MemberDetail=ArbApiResponse["data"]["User"]["Memberships"];
        this.CertificationDetail=ArbApiResponse["data"]["User"]["Certifications"];
       
      },
      err => console.error(err)
    );

  }

  ChatUser(){
  if(this.UserDetails!=null)
  {
      this.Item.ID=this.BasicInfo.ID;
      this.Item.FirstName=this.BasicInfo.FirstName;
      this.Item.Image=this.BasicInfo.PictureURL;
      let user=localStorage.getItem('CHAT');
      if(user!=null){
        localStorage.removeItem('CHAT');
      }
      localStorage.setItem('CHAT', JSON.stringify(this.Item));
      document.getElementById('btncallchatPopup').click()
    }
  }

  SendConnectionRequest() 
  {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      this.UserDetailsList.ConnectionStatus = 'Pending'
      this.PeopleConnectionRequest.ID = this.BasicInfo.ID;
      this.serviceObj.postArbData('SendConnectionRequest', this.PeopleConnectionRequest).subscribe(
        ArbApiResponse => {
          if(ArbApiResponse["Status"]==1){
          this.UserDetailsList.AllowedToConnect=false;
          this.UserDetailsList.ConnectionStatus = 'Request Sent';
          }
        }
      );
    }
  }


  RecommendPerson() 
  {
    this.NewRecommendation.UserIDs = '';
    for (let r of this.MyConnectionsList.Connected) {
      if (r.IsReceivingRecommendation) {
        if (this.NewRecommendation.UserIDs == "")
          this.NewRecommendation.UserIDs += r.ID;
        else
          this.NewRecommendation.UserIDs += "," + r.ID;
      }
    }
        if(this.NewRecommendation.UserIDs!='')
        {
          this.NewRecommendation.PersonID =0;
        this.serviceObj.postArbData('RecommendPerson', this.NewRecommendation).subscribe(
          ArbApiResponse => {
              document.getElementById('btnRecommendModal').click();
              document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
              document.getElementById('btnMessageModal').click();
          },
          ArbApiErr => {
          }
        );
      }
  }

  GetMyConnection() 
  {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else 
    {
      this.serviceObj.getArbData('MyConnections', null).subscribe(
        ArbApiResponse => {
          this.MyConnectionsList = ArbApiResponse["data"];
          if (this.MyConnectionsList.Connected.length > 0) {
            document.getElementById('btnRecommendModal').click();
          }
          else {
            if(this.PeoplePage)
            document.getElementById('divMessage').innerHTML = this.PeoplePage.Youdonot;
            document.getElementById('btnMessageModal').click();
          }
        },
        err => console.error(err)
      );
    }
  }
  BackToPeople() {
    localStorage.setItem('BackToPeople', 'true');
    this.router.navigate(['/People'])
  }

  selectAlls() {
    for (var i = 0; i < this.MyConnectionsList.Connected.length; i++) {
      this.MyConnectionsList.Connected[i].IsReceivingRecommendation = this.selectedAll;
    }
  }
  checkIfAllSelected() {
    this.selectedAll = this.MyConnectionsList.Connected.every(function (item: any) {
      return item.IsReceivingRecommendation == true;
    })
  }
  //End Class
}
