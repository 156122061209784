import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from "@angular/common";
declare var $:any;

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})

export class EventsComponent implements OnInit {
  UserDetails: any = {}
  EventPage: any = {};
  EventItems: any = [];
  SearchObj: any = {};
  IsLoading:boolean=true;
  showSpecify: boolean = true;
  showMile: boolean = true;
  showLoc: boolean = true;
  NewJoinRequest: any = {};
  Countries: any = [];
  Cities: any = [];
  MostRecommendedEventsList: any = [];
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  rdoAnywhere:boolean=true;
  allchro:boolean=true;
  upcoming:boolean=true;
  morePagesAvailable: boolean = false;

  constructor(private serviceObj: ArabesqueService, private router: Router, private location: Location) {
    this.SearchObj.PageIndex = 1;
    this.SearchObj.SearchText = '';
    this.SearchObj.StartDate =new Date();
    this.SearchObj.StartDateFinal ='';
    this.SearchObj.EndDate = '';
    this.SearchObj.EndDateFinal = '';
    this.SearchObj.Distance = 0;
    this.SearchObj.LocationIDs = '';
    this.SearchObj.IsTimeline = false;
    this.SearchObj.Longitude = '';
    this.SearchObj.Latitude = '';

    this.NewJoinRequest.EventID = 0;
  }
  RefreshUD() 
  { this.UserDetails = this.serviceObj.getUD(); 
    this.EventPage = this.serviceObj.GetPageSpecificContent('Event');
  }

  
  ngOnInit() 
  {
    this.IsLoading=true;
    this.UserDetails = this.serviceObj.getUD(); 
    this.EventPage = this.serviceObj.GetPageSpecificContent('Event');
    
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
        $("#MessageModal1").modal({ backdrop: 'static', keyboard: false});
      }
    }
    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';
    /*set on top start*/
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else
          window.scrollTo(0, 0);
      }
    });
    /*set on top end*/
   
    this.SearchEventItems(false);

    this.serviceObj.getArbData('CountryAndCity', null).subscribe(
      ArbApiResponse => {
        this.Countries = ArbApiResponse["data"]["Countries"];
        this.Cities = ArbApiResponse["data"]["Cities"];
      },
      err => console.error(err)
    );

    this.serviceObj.getArbData('MostRecommendedEvents', null).subscribe(
      ArbApiResponse => {
        this.MostRecommendedEventsList = ArbApiResponse["data"];
      },
      err => console.error(err)
    );

    document.getElementById('btnLoadMap').click();
  }

  GetNextPage() {
    if (this.morePagesAvailable) {
      this.SearchObj.PageIndex += 1;
      this.SearchEventItems(true);
    }
  }


  SearchEventItems(IsLazyLoading: boolean) {
    this.IsLoading=true;
    if (IsLazyLoading == null || IsLazyLoading == false) {
      this.SearchObj.PageIndex = 1;
      this.EventItems = [];
    }
      if (document.getElementById('txtAddressCoords') != null && document.getElementById('txtAddressCoords')["value"] != '' && this.SearchObj.Distance > 0) {
        this.SearchObj.Latitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[0];
        this.SearchObj.Longitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[1];
         // document.getElementById('lblPostTopLocation').innerHTML = 'You are seeing the posts within ' + this.SearchObj.Distance +' kms from '+this.UserDetails.LocationName;
      } else {
        this.SearchObj.Latitude = '';
        this.SearchObj.Longitude = '';
      }
      if(this.SearchObj.StartDate!='')
      this.SearchObj.StartDateFinal= this.SearchObj.StartDate.getFullYear().toString() + '-' + ( this.SearchObj.StartDate.getMonth() + 1).toString() + '-' +  this.SearchObj.StartDate.getDate().toString() +' '+ this.SearchObj.StartDate.getHours()+':'+ this.SearchObj.StartDate.getMinutes();
      else
      this.SearchObj.StartDateFinal=''
      if(this.SearchObj.EndDate!='')
      this.SearchObj.EndDateFinal= this.SearchObj.EndDate.getFullYear().toString() + '-' + ( this.SearchObj.EndDate.getMonth() + 1).toString() + '-' +  this.SearchObj.EndDate.getDate().toString() +' '+ this.SearchObj.EndDate.getHours()+':'+ this.SearchObj.EndDate.getMinutes();
      else
      this.SearchObj.EndDateFinal='';

     this.serviceObj.getArbData('SearchEvents', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.IsLoading = false;
        if (ArbApiResponse["data"].length > 0) 
        {
          if (this.SearchObj.PageIndex == 1) {
            this.EventItems = ArbApiResponse["data"];
          }
          else {
            for (var p = 0; p < ArbApiResponse["data"].length; p++)
              this.EventItems.push(ArbApiResponse["data"][p]);
          }
          
          if (ArbApiResponse["data"].length < 10)
          this.morePagesAvailable = false;
        else
          this.morePagesAvailable = true;
        }
        else {
          this.morePagesAvailable = false;
        }
      }
      ,
      err => {
        this.IsLoading = false;
        this.morePagesAvailable = false;
        console.error(err);
      }
    );
    
  }

  FilterEvent() 
  {
    this.SearchEventItems(false);
   
    
  }

  AllChro(){
    this.allchro =true;
    this.upcoming =false;
    this.showSpecify=true;
    this.SearchObj.StartDate = '';
    this.SearchObj.EndDate = '';
  }
  Upcoming(){
    this.upcoming =true;
    this.allchro =false;
    this.showSpecify=true;
    let d=new Date();
    this.SearchObj.StartDate =new Date();//(d.getMonth()+1).toString()+'/'+d.getDate().toString()+'/'+d.getFullYear().toString() +' '+d.getHours()+':'+d.getMinutes();
    this.SearchObj.EndDate = '';
    
  }

  toggleDate() {
    this.showSpecify = !this.showSpecify;
    this.upcoming =false;
    this.allchro =false;
  }
  toggleMile() {
    this.showMile = !this.showMile;
    this.rdoAnywhere=false;
  }
  toggleLoc() {
    this.showLoc = !this.showLoc;
    this.rdoAnywhere=false;
  }
  Anywhere(){
    this.showMile =true;
    this.showLoc =true;
    this.rdoAnywhere=true;
    this.SearchObj.Distance = 0;
    this.SearchObj.LocationIDs = '';
  }

  JoinEvent(Item: any) {
    this.NewJoinRequest.EventID = Item.EventID;
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      // if (!Item.JoinStatus) {      
      this.serviceObj.postArbData('JoinEvent', this.NewJoinRequest).subscribe(
        ArbApiResponse => {
          Item.JoinStatus = 'Request Sent';
        },
        ArbApiErr => {
        }
      );
      //  }
    }
  }


}
