import { Component, OnInit,ViewChild  } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service'
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Location, PopStateEvent } from "@angular/common";
import { ImageCroppedEvent } from '../../../node_modules/ngx-image-cropper';

declare var $ :any;

@Component({
  selector: 'app-create-events',
  templateUrl: './create-events.component.html',
  styleUrls: ['./create-events.component.css']
})
export class CreateEventsComponent implements OnInit {
  NewEvent: any = {};
  EventPage: any = {};
  InviteEvent: any = {};
  isDisablePublish: boolean = false;
  ErrorMessage: string = '';
  addCoverPicValidation: string = '';
  SearchObj: any = [];
  EventCategories: any = [];
  MyList: any = [];
  PublisList: any = [];
  isValidLoc: boolean = true;
  isValidCat: boolean = true;
  isValidKeyword: boolean = true;
  isValidEmail: boolean = true;
  isValidInvit: boolean = true;
  UserDetails: any = {}
  isStart: boolean = false;
  isNext: boolean = true;
  isNext1: Boolean = true;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  imageUrl: string = '';
  fileToUpload: File = null;
  Basic: string = 'pl-3 pb-3 position-relative sle ActiveS';
  Product: string = 'pl-3 pb-3 position-relative sle';
  Publish: string = 'pl-3 pb-3 position-relative sle';
  StartTime: Date = new Date();
  EndTime: Date = new Date();
  StartMin: Date = new Date();
  EndMin: Date = new Date();
  StartHour: Date = new Date();
  EndHour: Date = new Date();
  isEdit: number = 0;
  isWallPost: boolean = false;
  MyConnectionsList: any = [];
  OtherPeopleList: any = [];
  SocialConnecttionList: any = [];
  searchText: string = '';
  AllPeople: any = {};
  MessageEndDate:string='';
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  hstep = 1;
  mstep = 1;
  sstep = 1;
  data:any;
  TempImageArr:any=[];
  ID:number=1;
   data2:any;
    tempImag:any='';
    EnteredKeywords:string='';
    isValidDescription: boolean = true;
    filesize:number=100;
    imageChangedEvent: any = '';
    croppedImage: any = '';

  constructor(private serviceObj: ArabesqueService, private route: Router, private ar: ActivatedRoute, private location: Location) {
    this.StartMin.setMinutes(0);
    this.StartHour.setHours(12);
    this.EndHour.setHours(12);
    this.EndMin.setMinutes(0);

    this.EventCategories = [];
    this.NewEvent.EventID = 0;
    this.NewEvent.GroupEventID = 0;
    this.NewEvent.EventName = '';
    this.NewEvent.CategoryID = 0;
    this.NewEvent.Category = '';
    this.NewEvent.Keywords = [];
    this.NewEvent.KeyW ='';
    this.NewEvent.Description = '';
    this.NewEvent.StartDate = new Date();
    this.NewEvent.EndDate = new Date();

    this.NewEvent.StartDate.setDate(this.NewEvent.StartDate.getDate());
    //dt.getHours() + 2 
    this.NewEvent.EndDate.setHours((this.NewEvent.EndDate.getHours() + 2));

    this.NewEvent.Location = '';
    this.NewEvent.Joinability = true;
    this.NewEvent.IsPrivate = false;
    this.NewEvent.isVisible = true;
    this.NewEvent.isWallPost = false;

    this.NewEvent.StartTime = new Date();
    this.NewEvent.EndTime = new Date();
    this.NewEvent.EndTime.setHours((this.NewEvent.EndTime.getHours() + 2));
    this.NewEvent.mydata = '';
    this.NewEvent.Latitude = '';
    this.NewEvent.Longitude = '';

    this.InviteEvent.EmailTo = '';
    this.InviteEvent.Subject = '';
    this.InviteEvent.InviteMessage = '';

    this.SearchObj.ItemName ='';
    this.SearchObj.PageIndex = 0;

    this.AllPeople.Connected = '';
    this.AllPeople.Other = '';
    this.AllPeople.UserIDs = '';
    this.AllPeople.Emails = '';

    this.ar.params.subscribe(params => this.isEdit = params.ID);


  this.data2 = {};
  }


  RefreshUD() { this.UserDetails = this.serviceObj.getUD();
    this.EventPage = this.serviceObj.GetPageSpecificContent('Event');
    if(this.UserDetails==null)
    this.route.navigate(['/Home', 0]);
  }


  
  
  fileChangeEvent(ev: any): void 
  {
          let file:FileList=ev.target.files
          if(file.length>0)
          {
              this.fileToUpload=file.item(0);
                var reader=new FileReader();
                reader.onload=(event:any)=>{
                  document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
                  setTimeout(() => {  
                    var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
                    if(wdth>380)
                    this.filesize=380
                    else
                    this.filesize=wdth
                    this.imageChangedEvent = ev;
                  }, 2000);
                  
                }
                reader.readAsDataURL(this.fileToUpload);
        }
    }

    imageCropped(event: ImageCroppedEvent) 
    {
      this.croppedImage = event.base64;
    }
    imageLoaded() {
        // show cropper
    }
    loadImageFailed() {
        // show message
    }

  ngOnInit() 
  {
    this.UserDetails = this.serviceObj.getUD();
    this.EventPage = this.serviceObj.GetPageSpecificContent('Event');
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
        document.getElementById('btnMessageModal1').click();
      }
    }
    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';



    /*set on top start*/
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.route.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else
          window.scrollTo(0, 0);
      }
    });
    /*set on top start*/
    this.serviceObj.getArbData('GetEventCategories', '').subscribe(
      ArbApiResponse => {
        this.EventCategories = ArbApiResponse["data"];
      },
      ArbApiErr => {
      }
    );
    
    if(this.isEdit==1){
      this.serviceObj.getData.subscribe(
        u_data => {
          if(u_data!='0') {  
          this.NewEvent = u_data;
          this.isWallPost = true;
          if (u_data["CoverPic"])
            this.imageUrl = u_data["CoverPic"];
            if(u_data["Visibility"]!=null){
              this.NewEvent.isVisible=u_data["Visibility"];
            }

          this.NewEvent.isWallPost = false;
          if (this.NewEvent.isVisible == null)
            this.NewEvent.isVisible = true;
          if (this.NewEvent.StartDate == null)
          {
            this.NewEvent.StartDate ='';
          }
         
          if (this.NewEvent.EndDate == null){
            this.NewEvent.EndDate ='';
          }
           
            if (this.NewEvent.StartDate == null || this.NewEvent.StartDate =='')
            this.NewEvent.StartTime ='';
            else
            this.NewEvent.StartTime = this.NewEvent.StartDate;

            if (this.NewEvent.EndDate == null || this.NewEvent.EndDate=='')
            this.NewEvent.EndTime ='';
            else
            this.NewEvent.EndTime=this.NewEvent.EndDate;
            if(this.NewEvent.Keywords!='')
            {
              this.NewEvent.Keywords=this.serviceObj.GetSplitArray(this.NewEvent.Keywords);
            }
        }
      }
      );
    }
 

    this.serviceObj.getArbData('MyConnections', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.MyConnectionsList = ArbApiResponse["data"];
        this.SocialConnecttionList = ArbApiResponse["data"]["Connected"];
        err => console.error(err)
      }
    );
    this.serviceObj.getArbData('OtherPeople', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.OtherPeopleList = ArbApiResponse["data"];
        err => console.error(err)
      }
    );
    document.getElementById('btnLoadMap').click();
  }/*end INIT*/



  GetAllConnection() {
    this.AllPeople.UserIDs = '';
    for (let x of this.MyConnectionsList.Connected) {

      if (x.IsReceivingRecommendation == true) {
        if (this.AllPeople.UserIDs == '') {
          this.AllPeople.UserIDs = this.AllPeople.UserIDs + x.ID;
        }
        else {
          this.AllPeople.UserIDs = this.AllPeople.UserIDs + ',' + x.ID;
        }
      }
    }
    for (let x of this.OtherPeopleList) {

      if (x.IsChecked == true) {
        if (this.AllPeople.UserIDs == '') {
          this.AllPeople.UserIDs = this.AllPeople.UserIDs + x.ID;
        }
        else {
          this.AllPeople.UserIDs = this.AllPeople.UserIDs + ',' + x.ID;
        }
      }
    }
    // this.AllPeople.Other='';InviteEvent.EmailTo
    if (this.InviteEvent.EmailTo !== '') {
      this.AllPeople.Emails = '';

      if (this.InviteEvent.EmailTo.split(',').length > 0) {
        for (let y of this.InviteEvent.EmailTo.split(',')) {
          if (this.AllPeople.Emails == '') {
            this.AllPeople.Emails = this.AllPeople.Emails + y;
          }
          else {
            this.AllPeople.Emails = this.AllPeople.Emails + ',' + y;

          }
        }
      }
    }
    if(this.AllPeople.UserIDs!='')
    {
      this.serviceObj.postArbData('SendEventInvitation', this.AllPeople).subscribe(
        ArbApiResponse => {
          document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          document.getElementById('btnMessageModal').click();
        }
      );
    }
  }


  onSelect(event) {
    var selindex = event.split(":")[1].trim();
    if (selindex == 0) {
      this.isValidCat = false;

    } else {
      this.isValidCat = true;
    }
  }

  StartEvent() {
    this.Product = 'pl-3 pb-3 position-relative sle ActiveS';
    if (this.NewEvent.EventName == '') {
      this.isStart = false;
      this.isNext = true;
      this.isNext1 = true;
    } else {
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
    }
  }

  NextEventValidation() {

    this.MessageEndDate='';
    this.Publish = 'pl-3 pb-3 position-relative sle ActiveS';
    if (this.NewEvent.CategoryID == 0) {
      this.isValidCat = false;
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
    } else {
      this.isValidCat = true;
      this.isStart = true;
      this.isNext = true;
      this.isNext1 = false;
      this.NewEvent.Category =this.EventCategories.filter(x => x.CategoryID == this.NewEvent.CategoryID)[0].Category;
    }
    if (this.NewEvent.Keywords.length==0) {
      this.isValidKeyword = false;
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
    } else {
      this.isValidKeyword = true;
      this.isStart = true;
      this.isNext = true;
      this.isNext1 = false;
    }
    if (this.NewEvent.Description=='') {
      this.isValidDescription=false;
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
     }
     else {
      this.isStart = true;
      this.isNext = true;
      this.isNext1 = false;
      this.isValidDescription=true;
     }

    this.NewEvent.Location=document.getElementById('txtCountrySearch')["value"];
    if (this.NewEvent.Location == '') {
      this.isValidLoc = false;
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
    } else {
      this.isValidLoc = true;
      this.isStart = true;
      this.isNext = true;
      this.isNext1 = false;
       if (document.getElementById('txtAddressCoords')["value"] != '') {
        this.NewEvent.Latitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[0];
        this.NewEvent.Longitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[1];
      }
    }

    if (this.NewEvent.StartDate == '') {
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
      this.isValidStartDate = false;
    } else {
      this.isStart = true;
      this.isNext = true;
      this.isNext1 = false;
      this.isValidStartDate = true;
    }
    if (this.NewEvent.EndDate == '') {
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
      this.isValidEndDate = false;
      if(this.EventPage)
      this.MessageEndDate=this.EventPage.EventEnd;
    } else {
      this.isStart = true;
      this.isNext = true;
      this.isNext1 = false;
      this.isValidEndDate = true;
      this.MessageEndDate='';
    }
    if (this.NewEvent.StartDate <=this.NewEvent.EndDate) {
      this.isStart = true;
      this.isNext = true;
      this.isNext1 = false;
      this.isValidEndDate = true;
      this.MessageEndDate='';
   
    } else {
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
      this.isValidEndDate = false;
      if(this.EventPage)
      this.MessageEndDate=this.EventPage.Enddatecan;
    }

    if (this.NewEvent.Description=='' ||this.NewEvent.StartDate == '' || this.NewEvent.Location == '' || this.NewEvent.Keywords.length ==0 || this.NewEvent.CategoryID == 0 || ((this.NewEvent.StartDate > this.NewEvent.EndDate))) {
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
    }
    else 
    {
      this.isStart = true;
      this.isNext = true;
      this.isNext1 = false;
    }

    this.EnteredKeywords='';
    for(var x=0;x<this.NewEvent.Keywords.length;x++){
        if(this.EnteredKeywords=='')
        this.EnteredKeywords=this.NewEvent.Keywords[x].value;
        else
        this.EnteredKeywords=this.EnteredKeywords+','+this.NewEvent.Keywords[x].value;
      }
  }

  PreviousEventValidation() {
    this.isStart = true;
    this.isNext = false;
    this.isNext1 = true;
  }

  PreviousEventStart() {
    this.isStart = false;
    this.isNext = true;
    this.isNext1 = true;
  }

  AddUpdateEvent(AlsoSendInvite: boolean) 
  {
    this.NewEvent.StartDate = new Date(this.NewEvent.StartDate);
    this.NewEvent.StartDate = this.NewEvent.StartDate.getFullYear().toString() + '-' + (this.NewEvent.StartDate.getMonth() + 1).toString() + '-' + this.NewEvent.StartDate.getDate().toString() + ' ' + this.NewEvent.StartDate.getHours().toString() + ':' + this.NewEvent.StartDate.getMinutes().toString();

    if (this.NewEvent.EndDate != '') {
      this.NewEvent.EndDate = new Date(this.NewEvent.EndDate);
      this.NewEvent.EndDate = this.NewEvent.EndDate.getFullYear().toString() + '-' + (this.NewEvent.EndDate.getMonth() + 1).toString() + '-' + this.NewEvent.EndDate.getDate().toString() + ' ' + this.NewEvent.EndDate.getHours().toString() + ':' + this.NewEvent.EndDate.getMinutes().toString();
    }
    if ((this.NewEvent.CategoryID == 0) || (this.NewEvent.Keywords.length ==0) || (this.NewEvent.Location == '') || (this.NewEvent.StartDate == '')) {
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
    }
    else {
      this.isDisablePublish = true;
     // this.NewEvent.Keywords=this.EnteredKeywords;
     this.NewEvent.KeyW=this.EnteredKeywords; 
      if (this.UserDetails != null) {
        if (sessionStorage.getItem('GroupID') != null && sessionStorage.getItem('GroupID')!='null') 
          this.NewEvent.GroupEventID =sessionStorage.getItem('GroupID');
        else
        this.NewEvent.GroupEventID=0;
      }
      // this.NewEvent.StartDate =this.datepipe.transform(this.NewEvent.StartDate, 'yyyy-MM-dd');
      this.serviceObj.postArbData('AddUpdateEvent', this.NewEvent).subscribe(
        ArbApiResponse => 
        {
          document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          document.getElementById('btnMessageModal').click();
          if(ArbApiResponse["Status"]==1)
          {
          if (AlsoSendInvite == true) {
            this.AllPeople.EventID = ArbApiResponse["data"];
            this.GetAllConnection();
          }
            sessionStorage.setItem('GroupID',null);
            this.route.navigate(['/MyEvent'])
         }
        },
        ArbApiErr => {
          this.isDisablePublish = false;
        }
      );
    }
  }

  SendInvitation() {
    if (this.InviteEvent.EmailTo == '') {
      this.isValidEmail = false;

    } else {
      this.isValidEmail = true;
    }
    if (this.InviteEvent.Subject == '') {
      this.isValidInvit = false;

    } else {
      this.isValidInvit = true;
    }
  }


  closeCro()
  {
    $('#MdlCropImg').removeClass('show'); 
    $('#MdlCropImg').addClass('fade'); 
    $('body').removeClass('modal-open');
  }

   CropImage()
   {
    $('#MdlCropImg').removeClass('fade'); 
    $('body').addClass('modal-open');  
    $('#MdlCropImg').addClass('show'); 
   } 
    base64MimeType(encoded) 
    {
    var result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

      SaveImage()
      {
        if(this.croppedImage!='')
        {
          let blob=this.dataURItoBlob(this.croppedImage);
          let typeimg=this.base64MimeType(this.croppedImage);
          var t=typeimg.split('/')[1];
          let names:string='filename.'+t;
          var file = new File([blob], names, {type: typeimg});
          this.NewEvent.CoverPic=file;
          this.imageUrl=this.croppedImage;

        $('#MdlCropImg').removeClass('show'); 
        $('#MdlCropImg').addClass('fade'); 
        $('body').removeClass('modal-open');  
        }
      }

   
   RemoveIMG()
   {
    this.tempImag='';
   
   }
   dataURItoBlob(dataURI) 
   {
          var binary = atob(dataURI.split(',')[1]);
          var array = [];
        for (var i = 0; i < binary.length; i++) 
        {
          array.push(binary.charCodeAt(i));
        }
       return new Blob([new Uint8Array(array)], {    type: 'image/jpg'});
    }
  



  addCoverPic(fileInput: any) 
  {
    this.addCoverPicValidation = '';
    let file: FileList = fileInput.target.files
    this.NewEvent.CoverPic = fileInput.target.files;
    this.fileToUpload = file.item(0);
    //show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      document.getElementById('imgDimensionValidation')['src'] = event.target.result;
      setTimeout(() => {
        var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
        var hgth = document.getElementById('imgDimensionValidation')['naturalHeight'];
        if (wdth == 0 || hgth == 0 || wdth < 899) {
          // show message
          this.addCoverPicValidation = 'min width  900 or more';
          this.NewEvent.CoverPic = '';
          this.imageUrl = '';
        }
        else {
          this.addCoverPicValidation = '';
          this.imageUrl = event.target.result;
        }
      }, 2000);
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  /* End class */
}
