import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  UserDetails: any = {}
  constructor(private serviceObj: ArabesqueService) { }
  RefreshUD() { this.UserDetails = this.serviceObj.getUD(); }

  ngOnInit() {
  }

}
