
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'Active'
})

export class ActivePipe implements PipeTransform {

  transform(items: any[], IsActive: boolean): any[] { 
    if (!items || items.length==0) return [];
    return items.filter(it => {
      return it.IsActive==IsActive;  
       });
  }
}