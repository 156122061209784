import { Component, OnInit,ViewChild } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Route,Router, ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent } from '../../../node_modules/ngx-image-cropper';
declare var $ :any;

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.css']
})

export class EventViewComponent implements OnInit {
  UserDetails: any = {}
  EventPage: any = {};
  EventID: any = 0;
  posted: number = 0;
  CommentID: number = 0;
  attendence:any='A';
  imageUrl: any = '';
  vdoURL: any = '';
  MyNewPost: any = {};
  SearchObj: any = [];
  fileToUpload: File = null;
  isValidText: boolean = true;
  PostList: any = [];
  EventDetailsList: any = [];
  Events: any = [];
  MyConnectionsList: any = [];
  NewRecommendation: any = {};
  PostFileErrormsg: string = '';
  EventRecommendationsList: any = [];
  EventRecommendations: any = {};
  NewComment: any = {};
  coumt_: number = 0;
  searchText: string = '';
  selectAll = true;
  selectedAll: any;
  MostRecommendedEventsList: any = [];
  uploadedVdoFileName: string = '';
  ShowOnlineVdoField:boolean=true;
  Suggested:any=[];
  isDisablePublish:boolean=false;
  tempImag:any='';
  filesize:number=100;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  EncryptedID:string='';

  constructor(private serviceObj: ArabesqueService, private route: ActivatedRoute,private router:Router) {
    this.MyNewPost.ID = 0;
    this.SearchObj.ID = 0;
    this.SearchObj.EventID= 0;
    this.route.params.subscribe(params => this.EncryptedID= params.ID);
   
    this.MyNewPost.PostText = '';

    this.SearchObj.EncryptedID =this.EncryptedID;
    this.SearchObj.PageIndex = 1;
    this.SearchObj.ItemType = 'All';
    this.SearchObj.SearchText = '';
    this.SearchObj.IsTimeline = false;

    this.NewRecommendation.EventID =0;
    this.NewRecommendation.UserIDs = '';

    this.EventRecommendations.EventID = this.SearchObj.ID;

  }

  RefreshUD() { 
    this.UserDetails = this.serviceObj.getUD(); 
    this.EventPage = this.serviceObj.GetPageSpecificContent('Event');
    this.ngOnInit();
  }

  
  ngOnInit() 
  {
    this.UserDetails = this.serviceObj.getUD();
    this.EventPage = this.serviceObj.GetPageSpecificContent('Event');
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
        document.getElementById('btnMessageModal1').click();
      }
    }
    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

    this.serviceObj.getArbData('GetEventDetails', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.EventDetailsList = ArbApiResponse["data"];
        this.Events = ArbApiResponse["data"]["Event"];
        this.Suggested= ArbApiResponse["data"]["SuggestedEvents"];
        if(ArbApiResponse["data"]["Event"]!=null){
          this.attendence=ArbApiResponse["data"]["Event"].Attending;
        }
        else
        {
          this.attendence='A';
        }
        if(this.UserDetails!=null && this.EventDetailsList.IsMember==true)
        {
          this.SearchObj.ID=this.Events.ID;
          this.serviceObj.getArbData('GetEventPosts', this.SearchObj).subscribe(
            ArbApiResponse => {
              this.PostList = ArbApiResponse["data"];
            });
        }
      },
      err => console.error(err)
    );
  }
  LeaveEvent(MemberID:any)
  {
        let LeaveEvent:any={};
        LeaveEvent.EventID=this.Events.ID;
        if(MemberID!=null && MemberID!=0)
        LeaveEvent.MemberID=MemberID;
        else
        LeaveEvent.MemberID='';
        this.serviceObj.postArbData('ExitEvent', LeaveEvent).subscribe(
          ArbApiResponse => {
            document.getElementById('divMessage').innerHTML =ArbApiResponse["Message"];
            document.getElementById('btnMessageModal').click();
            if(ArbApiResponse["Status"]==1)
            {
            if(MemberID!=null && MemberID!=0)
            {
            this.Events.JoinStatus='';
            this.Events.Members=this.Events.Members.filter(i=>i.ID !==MemberID);
            $('#totalEventMemberCount').modal('toggle');
            }
          }
      
          },
          ArbApiErr => {
          }
        );
  }

  fileChangeEvent(ev: any): void 
  {
    
    let file:FileList=ev.target.files
    if(file.length>0)
    {
         this.fileToUpload=file.item(0);
          var reader=new FileReader();
          reader.onload=(event:any)=>{
            document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
            setTimeout(() => {  
              var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
              if(wdth>380)
              this.filesize=380
              else
              this.filesize=wdth
              this.imageChangedEvent = ev;
            }, 2000);
             
          }
          reader.readAsDataURL(this.fileToUpload);
        }
  }

    imageCropped(event: ImageCroppedEvent) 
    {
      this.croppedImage = event.base64;
    }
    imageLoaded() 
    {
        // show cropper
    }
    loadImageFailed() 
    {
        // show message
    }

  closeCro()
  {
    $('#MdlCropImg').removeClass('show'); 
    $('#MdlCropImg').addClass('fade'); 
    $('body').removeClass('modal-open');
  }

   CropImage()
   {
      if (this.UserDetails == null) {
        document.getElementById('plusLoginBtn').click();
      } else 
      {
          $('#MdlCropImg').removeClass('fade'); 
          $('body').addClass('modal-open');  
          $('#MdlCropImg').addClass('show'); 
      }
   } 

    base64MimeType(encoded) 
    {
        var result = null;
        if (typeof encoded !== 'string') {
          return result;
        }
        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
        if (mime && mime.length) {
          result = mime[1];
        }
        return result;
    }

      SaveImage()
      {
        if(this.croppedImage!='')
        {
          let blob=this.dataURItoBlob(this.croppedImage);
          let typeimg=this.base64MimeType(this.croppedImage);
          var t=typeimg.split('/')[1];
          let names:string='filename.'+t;
          var file = new File([blob], names, {type: typeimg});
          this.MyNewPost.PostPic=file;
          this.imageUrl=this.croppedImage;
          this.MyNewPost.FileType='Img';
          this.vdoURL = '';
          this.ShowOnlineVdoField=true;
        $('#MdlCropImg').removeClass('show'); 
        $('#MdlCropImg').addClass('fade'); 
        $('body').removeClass('modal-open'); 
        } 
      }

   
   RemoveIMG()
   {
    this.tempImag='';
   }

   dataURItoBlob(dataURI) 
   {
          var binary = atob(dataURI.split(',')[1]);
          var array = [];
        for (var i = 0; i < binary.length; i++) 
        {
          array.push(binary.charCodeAt(i));
        }
       return new Blob([new Uint8Array(array)], {    type: 'image/jpg'});
    }
  


  AddPostFile(fileInput: any, fileType: string) 
  {
    if (this.UserDetails == null) 
    {
      document.getElementById('plusLoginBtn').click();
    } else 
    {
      this.PostFileErrormsg = '';
      this.MyNewPost.FileType = fileType;
      this.MyNewPost.PostPic = fileInput.target.files;
      this.ShowOnlineVdoField=true;
      let file: FileList = fileInput.target.files;
      if(file.length>0)
      {
          this.fileToUpload = file.item(0);
          //show image preview
          var reader = new FileReader();
          reader.onload = (event: any) => 
          {
            if (fileType == 'Img') 
            {
              document.getElementById('imgDimensionValidation')['src'] = event.target.result;
              setTimeout(() => {
                var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
                var hgth = document.getElementById('imgDimensionValidation')['naturalHeight'];
                if (wdth == 0 || hgth == 0 || wdth < 350) {
                  this.RemovePic();
                  this.PostFileErrormsg = 'min width  350 or more';
                  this.imageUrl = '';
                  this.vdoURL = '';
                }
              }, 2000);
              this.imageUrl = event.target.result;
              this.vdoURL = '';
              this.PostFileErrormsg = '';
            }
            else 
            {
              if (fileInput.size == 0 || fileInput.size > 10240000) // 2MB
              {
                if(this.EventPage)
                this.PostFileErrormsg=this.EventPage.MaximumVideo;
                this.RemovePic();
                // show message
              }
              else {
                this.uploadedVdoFileName = file[0].name;
                this.vdoURL = event.target.result;
                this.imageUrl = '';
                this.PostFileErrormsg = '';
              }

            }
          }
          reader.readAsDataURL(this.fileToUpload);
        }
    }
  }

  RemovePic() {
    this.MyNewPost.PostPic = '';
    this.imageUrl = '';
    this.vdoURL = '';
    this.MyNewPost.FileTitle = '';
    this.MyNewPost.FileType = '';
  }

  PublishPost() 
  {
    if (this.UserDetails == null) 
    {
      document.getElementById('plusLoginBtn').click();
    }
    else 
    {
      if (this.MyNewPost.PostText == '') 
      {
        this.isValidText = false;
      }
      else {
        this.isValidText = true;
      }
      if (this.MyNewPost.PostText != '') {
        this.isDisablePublish=true;
        this.MyNewPost.ID =this.Events.ID;
        this.serviceObj.postArbData('AddEventPost', this.MyNewPost).subscribe(
          ArbApiResponse => 
          {
            document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
            document.getElementById('btnMessageModal').click();
            if(ArbApiResponse["Status"]==1)
            {
            this.isDisablePublish=false;
            this.MyNewPost.PostPic = '';
            this.imageUrl = '';
            this.MyNewPost.FileTitle = '';
            this.MyNewPost.FileType = '';
            this.MyNewPost.PostText = '';
            this.isValidText = true;
            this.serviceObj.getArbData('GetEventPosts', this.SearchObj).subscribe(
              ArbApiResponse => {
                this.PostList = ArbApiResponse["data"];
                this.RemovePic();
                this.isValidText = true;
                this.ShowOnlineVdoField = true;
              });
            }
          },
          ArbApiErr => {
            this.isDisablePublish=false;
          }
        
        );
      }
    }
  }


  SendEventRecommendation() {
    this.NewRecommendation.UserIDs = '';
    for (let r of this.MyConnectionsList.Connected) {
      if (r.IsReceivingRecommendation) {
        if (this.NewRecommendation.UserIDs == "")
          this.NewRecommendation.UserIDs += r.ID;
        else
          this.NewRecommendation.UserIDs += "," + r.ID;
      }
    }
    if(this.NewRecommendation.UserIDs!='')
      {
        this.NewRecommendation.EventID=this.Events.ID;
      this.serviceObj.postArbData('SendEventRecommendations', this.NewRecommendation).subscribe(
        ArbApiResponse => {
          document.getElementById('recommendPopup').click();
          document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          document.getElementById('btnMessageModal').click();

        },
        ArbApiErr => {
        }
      );
    }
  }

  AddPostComment(pst: any) {
    if (document.getElementById('Comment_' + pst.PostID)["value"] != "") {
      this.NewComment.PostID = pst.PostID;
      this.NewComment.CommentID = 0;  
      this.NewComment.CommentText = document.getElementById('Comment_' + pst.PostID)["value"];
      this.serviceObj.postArbData('AddEventComment', this.NewComment).subscribe(
        ArbApiResponse => {
          const d: Date = new Date();
          pst.Comments.push({
            "CommentID":ArbApiResponse["data"], "Comment": this.NewComment.CommentText, "CommentDateTime": d.toString(),
            "Replies": [],
            "CommentedBy": [{ "FirstName": this.UserDetails.FirstName, "PictureUrl": this.UserDetails.PictureUrl }]
          });
          document.getElementById('Comment_' + pst.PostID)["value"] = "";
          this.posted = pst.PostID;
        }
      );
    }
  }

  AddPostComment_ON(Comment1: any,PostID:number) {
    let NewComment: any = {};
    if (document.getElementById('Comment_ON_' + Comment1.CommentID)["value"] != "") {
      NewComment.CommentID = Comment1.CommentID;
      NewComment.PostID = PostID
      NewComment.CommentText = document.getElementById('Comment_ON_' + Comment1.CommentID)["value"];
      this.serviceObj.postArbData('AddEventComment', NewComment).subscribe(
        ArbApiResponse => {
       const d: Date = new Date();
      Comment1.Replies.push({
        "CommentID": 0, "Comment": NewComment.CommentText, "CommentDateTime": d.toString(),
        "CommentedBy": [{ "FirstName": this.UserDetails.FirstName, "PictureUrl": this.UserDetails.PictureUrl }]
      });
      document.getElementById('Comment_ON_' + Comment1.CommentID)["value"] = "";
        }
      );
    }

  }

  PostCommentToggle(PostID: number) {
    if (this.posted != PostID) {
      this.posted = PostID
    } else {
      this.posted = 0;
    }

  }
  FirstCommentToggle(Comment: any) {
    if (this.CommentID != Comment.CommentID) {
      this.CommentID = Comment.CommentID
    } else {
      this.CommentID = 0;
    }

  }


  GetEventRecommendations() {

    this.serviceObj.getArbData('GetEventRecommendations', this.EventRecommendations).subscribe(
      ArbApiResponse => {
        this.EventRecommendationsList = ArbApiResponse["data"];
      },
      err => console.error(err)
    );
  }

  VideoLink()
  {
      this.MyNewPost.FileType='';
      this.MyNewPost.PostPic='';
      this.vdoURL='';
      this.ShowOnlineVdoField=!this.ShowOnlineVdoField;
      if(this.ShowOnlineVdoField==true)
      {
        this.MyNewPost.OnlineVdo='';
      }
    }

  GetMyConnections() 
  {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      if (this.Events.HasEnded == false) {

        this.serviceObj.getArbData('MyConnections', null).subscribe(
          ArbApiResponse => {
            this.MyConnectionsList = ArbApiResponse["data"];
            if (this.MyConnectionsList.Connected.length > 0) {
              document.getElementById('btnRecommendModal').click();
            }
            else {
              if(this.EventPage)
              document.getElementById('divMessage').innerHTML = this.EventPage.Youdonothave;
              document.getElementById('btnMessageModal').click();
            }
          },
          err => console.error(err)
        );
      }
      // else
      // {
      //   document.getElementById('divMessage').innerHTML ='Can not recommend after event ended';
      //   document.getElementById('btnMessageModal').click();
      // }
    }
  }


  selectAlls() {
    for (var i = 0; i < this.MyConnectionsList.Connected.length; i++) {
      this.MyConnectionsList.Connected[i].IsReceivingRecommendation = this.selectedAll;
    }
  }
  checkIfAllSelected() {
    this.selectedAll = this.MyConnectionsList.Connected.every(function (item: any) {
      return item.IsReceivingRecommendation == true;
    })
  }


  EventRating(_rating: number) {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      if (this.EventDetailsList.IsMember == true) {

      //  if(this.EventDetailsList.Event.OwnerID!=this.UserDetails.ID)
      //  {
          let EventRating: any = {};
        EventRating.EventID =this.Events.ID;
        EventRating.Rating = _rating;
        this.serviceObj.postArbData('EventRating', EventRating).subscribe(
          ArbApiResponse => {
            document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
            document.getElementById('btnMessageModal').click();
            // for (var r = 1; r <= 5; r++)
            //   document.getElementById('Rating' + r).className = r <= _rating ? '' : 'ratingc';
            this.Events.Rating=_rating;
          },
          ArbApiErr => {
            //error only menber can provide rating
          }
        );
      // }
      // else{
      //   document.getElementById('divMessage').innerHTML = 'Owner can not provide rating.';
      //   document.getElementById('btnMessageModal').click();
      // }
      }
      else {
        if(this.EventPage)
        document.getElementById('divMessage').innerHTML = this.EventPage.Onlymemberscan;
        document.getElementById('btnMessageModal').click();
      }

    }
  }



  EventAttendance(attendance:string){
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else 
    {
      if (this.EventDetailsList.IsMember == true) 
      {
        if(this.Events.Attending=='X'){
        let EventAttendance: any = {};
        EventAttendance.EventID =this.Events.ID;
        EventAttendance.Attendance =attendance;
        this.Events.Attending=attendance;
        this.serviceObj.postArbData('EventAttendance', EventAttendance).subscribe(
          ArbApiResponse => {
            this.attendence='';
            if(attendance=='A')
            this.attendence= true;
            if(attendance=='N')
            this.attendence=false;
            document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
            document.getElementById('btnMessageModal').click();
          }
        );
      }
      }
      else {
 
        // document.getElementById('divMessage').innerHTML = 'Only members can give Attendance.';
        // document.getElementById('btnMessageModal').click();
        $('#MessageModalForJoinEvent').show();
      }
    }
  }

  Close(){
    $('#MessageModalForJoinEvent').hide();
  }

  JoinEvent() {
    let NewJoinRequest:any={};
    NewJoinRequest.EventID =this.Events.ID;;
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      this.serviceObj.postArbData('JoinEvent', NewJoinRequest).subscribe(
        ArbApiResponse => {
          this.Events.JoinStatus = 'Request Sent';
          this.ngOnInit();
        }
      );
    }
  }

  JoinEventFromMessage() 
  {
    this.JoinEvent();
          $('#MessageModalForJoinEvent').hide();
  }
  ViewMembers(){
    if(this.Events.Members!=null && this.Events.Members.length>0){
      document.getElementById('btntotalMemberCount').click();
    }else{
      if(this.EventPage)
      document.getElementById('divMessage').innerHTML =this.EventPage.Noparticipants;
      document.getElementById('btnMessageModal').click();
    }

}
}
