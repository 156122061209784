import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.css']
})
export class TermAndConditionComponent implements OnInit {
  UserDetails: any = {};
  StaticPage: any = {};
  constructor(private serviceObj: ArabesqueService) { }
  RefreshUD() { this.UserDetails = this.serviceObj.getUD(); 
    this.StaticPage = this.serviceObj.GetPageSpecificContent('StaticPage');}

  ngOnInit() {
    this.StaticPage = this.serviceObj.GetPageSpecificContent('StaticPage');
    if (window.location.href.indexOf('/Home') > 0)
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
  else
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader';
    
  }


}
