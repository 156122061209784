import { NgModule,OnInit,Component,Pipe,PipeTransform } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule  }  from '@angular/forms';
import { RouterModule, Routes,DefaultUrlSerializer, UrlTree  } from '@angular/router';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { GroupsComponent } from './groups/groups.component';
import { PeopleComponent } from './people/people.component';
import { EventsComponent } from './events/events.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { HomeComponent } from './home/home.component';
import { ExchangeComponent } from './exchange/exchange.component';
import { MyTimeLineComponent } from './my-time-line/my-time-line.component';
import { AddExchangeComponent } from './add-exchange/add-exchange.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { CreateEventsComponent } from './create-events/create-events.component';
import { MycomComponent } from './mycom/mycom.component';
import { ArabesqueService } from './arabesque-service.service';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ExchangeViewComponent } from './exchange-view/exchange-view.component';
import { PeopleViewComponent } from './people-view/people-view.component';
import { GroupViewComponent } from './group-view/group-view.component';
import { EventViewComponent } from './event-view/event-view.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { AboutComponent } from './about/about.component';
import { WallComponent } from './wall/wall.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { MyExchangeComponent } from './my-exchange/my-exchange.component';
import { MyPeopleComponent } from './my-people/my-people.component';
import { MyGroupComponent } from './my-group/my-group.component';
import { MyEventComponent } from './my-event/my-event.component';
import { MyCoinComponent } from './my-coin/my-coin.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { SettingComponent } from './setting/setting.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { FilterPipe } from './filter.pipe';
import { TermAndConditionComponent } from './term-and-condition/term-and-condition.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { ImageGuideLineComponent } from './image-guide-line/image-guide-line.component';
import { OrderrByPipe } from './orderby.pipe';
import { SafePipe } from './safe.pipe';
import { ActivePipe } from './Active.pipe';
import { EncodeText } from './EncodeText.pipe';
import { ReplaceExtensions } from './ReplaceExtension.pipe';
import { FilterCheckedPipe } from './isChecked.pipe';
import { NotificationsComponent } from './notifications/notifications.component';
import { FilterQuestion } from './FilterQuestion.pipe';
import { FeedbackComponent } from './feedback/feedback.component';

import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { ReactiveFormsModule } from '@angular/forms';
import { OffersComponent } from './offers/offers.component';
import { SupportComponent } from './support/support.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RecaptchaModule } from 'angular-google-recaptcha';
//import { NgCircleProgressModule } from 'ng-circle-progress';
import {ProgressBarModule} from "angular-progress-bar"
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


const appRoutes: Routes = [
  { path: '', redirectTo: '/Home', pathMatch: 'full' },
  { path: 'Home', component: HomeComponent ,},
  { path: 'Home/:CODE', component: HomeComponent },
  { path: 'home/:CODE', component: HomeComponent },
  { path: 'Groups', component: GroupsComponent },
  { path: 'Exchange', component: ExchangeComponent },
  { path: 'Mycom',component:MycomComponent},
  { path: 'People', component: PeopleComponent },
  { path: 'Events', component: EventsComponent },
  
  { path: 'groups', component: GroupsComponent },
  { path: 'exchange', component: ExchangeComponent },
  { path: 'people', component: PeopleComponent },
  { path: 'events', component: EventsComponent },

  { path: 'CreateGroup/:ID', component: CreateGroupComponent },
  { path: 'CreateEvents/:ID', component: CreateEventsComponent },
  { path: 'AddExchange/:ID',component:AddExchangeComponent},
  { path: 'MyTimeLine', component: MyTimeLineComponent },
  { path: 'ExchangeView/:ID', component: ExchangeViewComponent },
  { path: 'PeopleView/:ID', component: PeopleViewComponent },
  { path: 'GroupView/:ID', component: GroupViewComponent },
  { path: 'EventView/:ID', component: EventViewComponent },
  { path: 'Wall', component: WallComponent },
  { path: 'Wall/:PostCode', component: WallComponent },

  { path: 'exchangeview/:ID', component: ExchangeViewComponent },
  { path: 'peopleview/:ID', component: PeopleViewComponent },
  { path: 'groupview/:ID', component: GroupViewComponent },
  { path: 'eventview/:ID', component: EventViewComponent },
  { path: 'wall', component: WallComponent },
  { path: 'wall/:PostCode', component: WallComponent },
  
  { path: 'MyExchange', component: MyExchangeComponent },
  { path: 'MyPeople', component: MyPeopleComponent },
  { path: 'MyGroup', component: MyGroupComponent },
  { path: 'MyEvent', component: MyEventComponent },
  { path: 'MyAccount/:ID', component: MyAccountComponent },
  { path: 'MyCoin', component: MyCoinComponent },
  { path: 'Contact', component: ContactComponent },
  { path: 'Setting',component:SettingComponent},
  { path: 'Faq', component: FaqComponent },
  { path: 'About', component: AboutComponent },
  { path: 'Feedback', component: FeedbackComponent },
  { path: 'Support', component: SupportComponent },
  { path: 'Offers', component: OffersComponent },
  { path: 'ImageGuideLine', component: ImageGuideLineComponent },
  { path: 'GlobalSearch', component: GlobalSearchComponent },
  { path: 'TermAndCondition', component: TermAndConditionComponent },
  { path: 'PrivacyPolicy', component: PrivacyPolicyComponent },
  { path: 'termandcondition', component: TermAndConditionComponent },
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'Notifications', component: NotificationsComponent },
  { path: '**', component: PagenotfoundComponent }

];
@NgModule({
  declarations: [
    AppComponent,
    GroupsComponent,
    ExchangeComponent,
    PeopleComponent,
    EventsComponent,
    PagenotfoundComponent,
    HomeComponent,
    ExchangeComponent,
    MyTimeLineComponent,
    AddExchangeComponent,
    CreateGroupComponent,
    CreateEventsComponent,
    MycomComponent,
    HeaderComponent,
    FooterComponent,
    ExchangeViewComponent,
    PeopleViewComponent,
    GroupViewComponent,
    EventViewComponent,
    ContactComponent,
    FaqComponent,
    AboutComponent,
    WallComponent,
    PrivacyPolicyComponent,
    MyExchangeComponent,
    MyPeopleComponent,
    MyGroupComponent,
    MyEventComponent,
    MyCoinComponent,
    MyAccountComponent,
    SettingComponent,
    FilterPipe,
    TermAndConditionComponent,
    GlobalSearchComponent,
    ImageGuideLineComponent,
    OrderrByPipe ,
    SafePipe,
    FilterCheckedPipe,
    NotificationsComponent,
    FeedbackComponent,
    OffersComponent ,
    ActivePipe,
    EncodeText,
    SupportComponent,
    ReplaceExtensions,
    FilterQuestion
  ],
  imports: [RecaptchaModule.forRoot({
    siteKey: '6LeVP40UAAAAAB7fuNeH4FlThffnn4DUdJNEjgrK',
      }),
      // NgCircleProgressModule.forRoot({
      //   radius: 100,
      //   outerStrokeWidth: 8,
      //   innerStrokeWidth: 4,
      //   outerStrokeColor: "#78C000",
      //   innerStrokeColor: "#C7E596",
      //   animationDuration: 300,
      // }),
      ProgressBarModule,
    ImageCropperModule,BrowserModule,TagInputModule,BrowserAnimationsModule,ReactiveFormsModule,
    TimepickerModule.forRoot(),BsDatepickerModule.forRoot(),ModalModule.forRoot()
    ,CarouselModule.forRoot(),TooltipModule.forRoot(),FormsModule, HttpClientModule,RouterModule.forRoot(appRoutes)
  ],
  
  providers: [ArabesqueService, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent,]
})
export class AppModule { }
// platformBrowserDynamic().bootstrapModule(AppModule);

