import { Component, OnInit,ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ArabesqueService } from './../arabesque-service.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

UserDetails: any = {};
StaticPage: any = {};
  constructor(private serviceObj: ArabesqueService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.UserDetails = this.serviceObj.getUD();
    this.StaticPage = this.serviceObj.GetPageSpecificContent('StaticPage'); 
  }

  RefreshUD() { this.UserDetails = this.serviceObj.getUD(); 
    this.StaticPage = this.serviceObj.GetPageSpecificContent('StaticPage'); }
  
}
