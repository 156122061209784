import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { Observable } from 'rxjs';
import { ArabesqueService } from './../arabesque-service.service';
import { Router } from '@angular/router';
import { FilterPipe } from '../filter.pipe';
declare var $: any;

@Component({
  selector: 'app-my-event',
  templateUrl: './my-event.component.html',
  styleUrls: ['./my-event.component.css']
})

export class MyEventComponent implements OnInit {
  UserDetails: any = {}
  EventPage: any = {};
  EventItems: any = [];
  SearchObj: any = {};
  EventRequestsList: any = [];
  Incoming: any = [];
  Outgoing: any = [];
  Notifications: any = [];
  requestRejectedByYous: any=[];
  RecievedEventInvitationsList: any = [];
  EventRecommendationsList: any = [];
  EventPostsList: any = [];
  IneterestedPeopleList = [];
  IneterestedPeople: any = {};
  EventRecommendations:any=[];
  AcceptRejectRequest: any = {};
  DelStatus:any={};
  AllRecList:any=[];
  RecommededBy:string;
  constructor(private serviceObj: ArabesqueService, private router: Router) {
    this.SearchObj.PageIndex = 1;
    this.SearchObj.SearchText = '';
    this.SearchObj.IsTimeline = true;
    this.SearchObj.StartDate = '';//new Date();
    this.SearchObj.EndDate = '';//new Date();
    this.SearchObj.Distance = 0;
    this.SearchObj.LocationIDs = '';
    this.SearchObj.Longitude = '';
    this.SearchObj.Latitude = '';

    this.IneterestedPeople.EventID = 0;
  }

  RefreshUD() { this.UserDetails = this.serviceObj.getUD();
    this.EventPage = this.serviceObj.GetPageSpecificContent('Event');
    if(this.UserDetails==null)
    this.router.navigate(['/Home', 0]);
  }

  ngOnInit() {
    this.RefreshUD();
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
       $("#MessageModal1").modal({ backdrop: 'static', keyboard: false});
      }
    }
    let tab=sessionStorage.getItem('TAB');
    if(tab!=null)
    {
      if(tab=='E')
      {
      $('#propMyItem').removeClass('active');
      $('#propItem').removeClass('active');

      $('#propReq').removeClass('active');
      $('#propRequest').removeClass('active');

      $('#propRecom').removeClass('active');
      $('#propRecomend').removeClass('active');
      
      $('#propReq').addClass('active');
      $('#propRequest').addClass('active');
      sessionStorage.removeItem('TAB');
      }
    }

    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

    this.serviceObj.getArbData('SearchEvents', this.SearchObj).subscribe(
      ArbApiResponse => {
      this.EventItems = ArbApiResponse["data"];
      }
    );

    this.serviceObj.getArbData('GetEventRequests', null).subscribe(
      ArbApiResponse => {
       this.Notifications = ArbApiResponse["data"]["Notifications"];
        this.Outgoing = ArbApiResponse["data"]["Outgoing"];
        this.Incoming = ArbApiResponse["data"]["Incoming"];
        this.requestRejectedByYous = ArbApiResponse["data"]["requestRejectedByYous"];
      }
    );

    this.serviceObj.getArbData('GetEventRecommendations',this.IneterestedPeople).subscribe(
      ArbApiResponse => {
         this.EventRecommendations = ArbApiResponse["data"];
      }
    );
  }

  SearchEventItems(tyValue) {
    if (tyValue != null)
      this.SearchObj.ItemType = tyValue;
    this.serviceObj.getArbData('SearchEvents', this.SearchObj).subscribe(
      data => {
      this.EventItems = data["data"];
      },
      err => console.error(err)
    );
  }

  Edit(Items: any) {
    this.serviceObj.SetData(Items);
    this.router.navigate(['/CreateEvents', 1]);
  }

  DeleteEvent(Item: any) {
    this.DelStatus=Item;
    $('#ConfirmID').removeClass('fade'); 
    $('body').addClass('modal-open');
    $('#ConfirmID').addClass('show');
  }

  OK(status:string,Item: any){
    $('#ConfirmID').addClass('fade'); 
    $('body').removeClass('modal-open');
    $('#ConfirmID').removeClass('show');
          if(status=='C'){

          }else if(status=='O'){
              this.serviceObj.postArbData('DeleteEvent', Item).subscribe(
                ArbApiResponse => {
                  this.EventItems = this.EventItems.filter(i => i.EventID != Item.EventID);
                  document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
                  document.getElementById('btnMessageModal').click();
                },
                ArbApiErr => { 
                  document.getElementById('divMessage').innerHTML = ArbApiErr["Message"];
                  document.getElementById('btnMessageModal').click();
                }
              );
          }
  }

  getInterestedPeopleList(Itm) {
    this.IneterestedPeople.EventID = Itm.EventID;
    this.serviceObj.getArbData('GetIncomingRequestsByEventID', this.IneterestedPeople).subscribe(
      ArbApiResponse => {
        this.IneterestedPeopleList = ArbApiResponse["data"];
      },
      err => console.error(err)
    );
  }


  AcceptOffer(rqst) {
    this.AcceptRejectRequest.SenderID = rqst.SenderID;
    this.AcceptRejectRequest.EventID = rqst.EventID;
    this.AcceptRejectRequest.IsApproved = true;
    this.serviceObj.postArbData('ApproveRejectEventRequest', this.AcceptRejectRequest).subscribe(
      ArbApiResponse => {
        this.IneterestedPeopleList = this.IneterestedPeopleList.filter(i => i.SenderID !== rqst.SenderID);
        if (this.IneterestedPeopleList.length == 0) {
          $('#incomingRequesPop').modal('toggle');
          this.Incoming = this.Incoming.filter(i => i.EventID != rqst.EventID);

          this.serviceObj.getArbData('UserDetails', null).subscribe(
            ArbApiResponse => {
              this.serviceObj.removeUD();
              this.serviceObj.setUD(ArbApiResponse["data"], false);
              this.RefreshUD();
              document.getElementById('btnHeaderRefreshUD').click();
        
            });
        }
      },
      ArbApiErr => {
      }
    );
  }

  RejectOffer(rqst) {
    this.AcceptRejectRequest.SenderID = rqst.SenderID;
    this.AcceptRejectRequest.EventID = rqst.EventID;
    this.AcceptRejectRequest.IsApproved = false;
    this.serviceObj.postArbData('ApproveRejectEventRequest', this.AcceptRejectRequest).subscribe(
      ArbApiResponse => {
        this.IneterestedPeopleList = this.IneterestedPeopleList.filter(i => i.SenderID !== rqst.SenderID);
       // document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        //document.getElementById('btnMessageModal').click();

        if (this.IneterestedPeopleList.length == 0) {
          $('#incomingRequesPop').modal('toggle');
          this.Incoming = this.Incoming.filter(i => i.EventID != rqst.EventID);
        }
        this.serviceObj.getArbData('UserDetails', null).subscribe(
          ArbApiResponse => {
            this.serviceObj.removeUD();
            this.serviceObj.setUD(ArbApiResponse["data"], false);
            this.RefreshUD();
            document.getElementById('btnHeaderRefreshUD').click();
      
          });
      },
      ArbApiErr => {
      }
    );
  }


  CancelEventRequest(rqst) {
    this.IneterestedPeople.EventID = rqst.EventID;
    this.serviceObj.postArbData('CancelEventRequest', this.IneterestedPeople).subscribe(
      ArbApiResponse => {
        this.Outgoing = this.Outgoing.filter(i => i.EventID !== rqst.EventID);
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
      },
      ArbApiErr => {
      }
    );
  }

  ViewRecommended(Item:any)
  {
    let Recommed:any={};
          Recommed.EventID=Item.EventID;
          Recommed.IsSent=(this.UserDetails.ID==Item.SenderID);
          if(this.UserDetails.ID==Item.SenderID)
          {
            if(this.EventPage)
            this.RecommededBy=this.EventPage.Recommendedto;
          }
          else{
            if(this.EventPage)
            this.RecommededBy=this.EventPage.EV_Recommendedby;
          }
          
          this.serviceObj.getArbData('GetAllRecommendationByEvent', Recommed).subscribe(
            ArbApiResponse => {
              this.AllRecList=ArbApiResponse["data"];
              $('#totalRecommended').modal('toggle');
            });
  
    }

}
