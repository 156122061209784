import { Component, OnInit,ViewChild } from '@angular/core';
import {NgForm } from '@angular/forms'
import { ArabesqueService } from './../arabesque-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent } from '../../../node_modules/ngx-image-cropper';
declare var $: any;
declare var UserSetting :any;
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})

export class MyAccountComponent implements OnInit {
  ExItems: any = [];
  TabID: number = 0;
  SearchObj: any = {};
  UserDetails: any = {}
  MyAccountPage: any = {};
  MyProfile: any = {};
  txtAddressCoords: string = '';
  isValid: boolean = true;
  isValidZip:boolean=true;
  Countries: any = [];
  Cities: any = [];
  AllCities: any = [];
  InterestList: any = [];
  Interest: string = '';
  tempid: any = 0;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  eduSubmited: boolean = false;
  workSubmited: boolean = false;
  skillSubmited: boolean = false;
  TrainingSubmited: boolean = false;
  LanguageSubmited: boolean = false;
  CertifiSubmited: boolean = false;
  MembershipSubmited: boolean = false;
 
  PasswordSubmited = true;
  interstReview: boolean = false;

  NewEducation: any = {};
  NewEducationList: any = [];
  NewWork: any = {};
  NewWorkList: any = [];
  NewSkill:any={};
  NewSkillList:any=[];
  NewTraining:any={};
  NewTrainingList:any[];
  NewLanguage:any={};
  NewLanguageList:any=[];
  NewCertification:any={};
  NewCertificationList:any[];
  NewMembership:any={};
  NewMembershipList:any[];

  NewPass: any = {};
  UserInterestCategories: any = [];
  searchText: string = '';
  searchTextCategory: string = '';
  MyInterests: any = [];
  MyCategories: any = [];
  MySelectedInterests: any = {};
  UserCategories: any = [];
  MySelectedCategories: any = {};
  unamePattern = "^[A-Ya-z][0-9][A-Ya-z] ?[0-9][A-Ya-z][0-9]$";
  phonePattern ="[0-9]{10}";

   ProfileImages: any = {}
  fileToUpload:File=null;
  fileToUpload1:File=null;
  imageUrlProfilePic:string='';
  imageUrlCoverPic:string='';
  UserDetails_CoverPic:string='';
  UserDetails_PictureUrl:string='';
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  ProfilePicErrormsg:string='';
  CoverPicErrormsg:string='';
  data1:any;
  ID:number=1;
   //Cropper 2 data
   data2:any;
   //cropperSettings2:CropperSettings;
   //@ViewChild('cropper', undefined) 
   //cropper:ImageCropperComponent;
    tempImag:any='';
     //Cropper 2 data
     dataProfile:any;
     //cropperSettingsProfile:CropperSettings;
     //@ViewChild('cropperProfile', undefined) 
     //cropperProfile:ImageCropperComponent;
  tempImagProfile:any='';
  SectionVisibility:any=[];

  MaxDOB:any=new Date();
  imageChangedEvent: any = '';
  imageChangedEventC: any = '';
  croppedImageC: any = '';
  croppedImage: any = '';
  filesize:number=100;
  filesizeC:number=100;
  constructor(private serviceObj: ArabesqueService, private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.TabID = params.ID);

  this.ProfileImages.CoverPic='';
    this.ProfileImages.ProfilePic=''; 
    this.MaxDOB=new Date();
    this.SearchObj.PageIndex = 1;
    this.SearchObj.ItemType = 'All';
    this.SearchObj.SearchText = '';
    this.SearchObj.IsTimeline = true;

    this.MyProfile.FirstName = '';
    this.MyProfile.LastName = '';
    this.MyProfile.WallPostRadius = 0;
    this.MyProfile.Email = '';
    this.MyProfile.BirthDate = '';
    this.MyProfile.Gender = '';
    this.MyProfile.CityID = 0;
    this.MyProfile.CountryID = 0;
    this.MyProfile.Myself = '';
    this.MyProfile.AboutMe = '';
    this.MyProfile.Nationality = '';
    this.MyProfile.Zip = '';
    this.MyProfile.LocationName = '';
    this.MyProfile.Mobile='';
    this.MyProfile.ProfileCompletancy=0;
    this.MyProfile.Long = '';
    this.MyProfile.Lat = '';
    this.MyProfile.Location = '';
    this.MyProfile.RegisteredAs = 'I';

    //this.InterestList.
    this.NewEducation.Degree = '';
    this.NewEducation.Institute = '';
    this.NewEducation.From = '';
    this.NewEducation.FromMonth =0;
    this.NewEducation.FromYear =1900;
    this.NewEducation.To = '';
    this.NewEducation.ToYear=1900;
    this.NewEducation.ToMonth=0;
    this.NewEducation.Description = '';
    this.NewEducation.educReview = false;
    this.NewEducation.Pursuing = false;
    this.NewEducation.AddUpdateFlag=0;

    this.NewEducationList.Degree = '';
    this.NewEducationList.Institute = '';
    this.NewEducationList.From = '';
    this.NewEducationList.FromMonth =0;
    this.NewEducationList.FromYear =1900;
    this.NewEducationList.To = '';
    this.NewEducationList.ToYear=1900;
    this.NewEducationList.ToMonth=0;
    this.NewEducationList.Description = '';
    //this.NewEducationList.educReview = false;
    this.NewEducationList.Pursuing = false;

    this.NewWork.CompanyName = '';
    this.NewWork.Title = '';
    this.NewWork.From = '';
    this.NewWork.To = '';
    this.NewWork.JobProfile = '';
    this.NewWork.WFromYear=1900;
    this.NewWork.WFromMonth=0;
    this.NewWork.WToYear=1900;
    this.NewWork.WToMonth=0;
    this.NewWork.IsExperience=false;
    this.NewWork.expReview = false;
    this.NewWork.Pursuing=false

   
    this.NewSkill.skilReview = false;

    this.NewTraining.Name = '';
    this.NewTraining.Institute = '';
    this.NewTraining.From = '';
    this.NewTraining.To = '';
    this.NewTraining.FromYear=1900;
    this.NewTraining.FromMonth=0;
    this.NewTraining.ToYear=1900;
    this.NewTraining.ToMonth=0;
   this.NewTraining.TrainingReview = false;
    this.NewTraining.Pursuing=false

    
    this.NewLanguage.lamgReview = false;

    this.NewCertification.Name = '';
    this.NewCertification.Institute = '';
    this.NewCertification.From = '';
    this.NewCertification.To = '';
    this.NewCertification.FromYear=1900;
    this.NewCertification.FromMonth=0;
    this.NewCertification.ToYear=1900;
    this.NewCertification.ToMonth=0;
   this.NewCertification.certifiReview = false;
    this.NewCertification.Pursuing=false

    this.NewMembership.Name = '';
    this.NewMembership.Role = '';
    this.NewMembership.From = '';
    this.NewMembership.FromYear=1900;
    this.NewMembership.FromMonth=0;
    this.NewMembership.memberReview = false;
    
    this.NewPass.OldPassword = '';
    this.NewPass.NewPassword = '';
    this.NewPass.NewConfirmPassword = '';

    this.MySelectedInterests.InterestIDs = '';
    this.MySelectedInterests.interstReview = '';
    this.MySelectedCategories.CategoryIDs = '';
  // //Cropper settings 2
  this.data2 = {};
  // //Cropper settings 1
  this.dataProfile = {};

  }

  RefreshUD() { 
    this.UserDetails = this.serviceObj.getUD();
    if(this.UserDetails==null)
    this.router.navigate(['/Home', 0]);
    this.MyAccountPage = this.serviceObj.GetPageSpecificContent('MyAccount');
  }

  fileChangeEventC(ev: any): void {

    let file:FileList=ev.target.files
  
         this.fileToUpload=file.item(0);
          var reader=new FileReader();
          reader.onload=(event:any)=>{
  
            document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
            setTimeout(() => {  
              var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
            //  if(wdth>180)
              this.filesizeC=wdth;
            //  else
             // this.filesizeC=180
              this.imageChangedEventC = ev;
            }, 2000);
             
          }
          reader.readAsDataURL(this.fileToUpload);
      
    }
  
    
  
    imageCroppedC(event: ImageCroppedEvent) {  
      this.croppedImageC = event.base64;
    }
  
    imageLoadedC() {
        // show cropper
    }
    imageLoaded() {
      // show cropper
  }

    loadImageFailedC() {
        // show message
    }

    fileChangeEvent(ev: any): void {
      let file:FileList=ev.target.files
    
           this.fileToUpload1=file.item(0);
            var reader=new FileReader();
            reader.onload=(event:any)=>{
    
              document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
              setTimeout(() => {  
                var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
               // if(wdth>900)
                this.filesize= wdth;//900
              //  else
              //  this.filesize=900
                this.imageChangedEvent = ev;
              }, 2000);
               
            }
            reader.readAsDataURL(this.fileToUpload1);
        
      }
    
      
    
      imageCropped(event: ImageCroppedEvent) {
    
        this.croppedImage = event.base64;
      }
    
      imageLoadedCover() {
          // show cropper
      }
      loadImageFailedCover() {
          // show message
      }

  ngOnInit() {
    this.UserDetails = this.serviceObj.getUD();
    this.MyAccountPage = this.serviceObj.GetPageSpecificContent('MyAccount');
    if (this.TabID != 0) {
      $(document).ready(function () {
        $('#interTab').addClass('active');
        $('#basicTab').removeClass('active');
        $('#basicInfoTab').removeClass('active');
        $('#myInterTab').addClass('active');
      });
    }
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
       // document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
       // document.getElementById('btnMessageModal1').click();
      }
    }
    this.imageUrlCoverPic=this.UserDetails.CoverPic;
    this.imageUrlProfilePic=this.UserDetails.PictureUrl;
    
    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

    this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
      arbAPiresponse => {
        debugger;
        this.SectionVisibility = arbAPiresponse["data"]["SectionVisibility"];
        this.MyProfile = arbAPiresponse["data"]["User"]["BasicInfo"];
        if(arbAPiresponse["data"]["User"]["BasicInfo"]["Nationality"]== null) {this.MyProfile.CountryID =UserSetting.CountryID;}
        else {this.MyProfile.CountryID =  arbAPiresponse["data"]["User"]["BasicInfo"]["Nationality"]["ID"];}
        this.MyProfile.WallPostRadius = arbAPiresponse["data"]["User"]["ContactInfo"].WallPostRadius;
        this.Countries = arbAPiresponse["data"]["Countries"];
        this.Cities = arbAPiresponse["data"]["Cities"];
        this.AllCities = arbAPiresponse["data"]["Cities"];
        
        if (arbAPiresponse["data"]["User"]["BasicInfo"]["Nationality"] != null)
          this.MyProfile.Nationality = arbAPiresponse["data"]["User"]["BasicInfo"]["Nationality"]["NameEn"];
        if (arbAPiresponse["data"]["User"]["Educations"].length > 0) 
        {
          //  this.MyProfile.BirthDate = this.MyProfile.BirthDate.getFullYear().toString() + '-' + 
          //(this.MyProfile.BirthDate.getMonth() + 1).toString() + '-' + this.MyProfile.BirthDate.getDate().toString();
 
                this.NewEducation = arbAPiresponse["data"]["User"]["Educations"][0];
                this.NewEducationList = arbAPiresponse["data"]["User"]["Educations"];
                
                if( this.NewEducation.From ==null)
                {
                  this.NewEducation.From =new Date();
                }
                else
                {
                  this.NewEducation.From = new Date(this.NewEducation.From);
                }
                if( this.NewEducation.To ==null)
                {
                  this.NewEducation.To = new Date();
                  this.NewEducation.ToYear=1900;
                  this.NewEducation.ToMonth=0;
                  this.NewEducation.Pursuing=true
                }
                else
                {
                  this.NewEducation.To = new Date(this.NewEducation.To);
                  this.NewEducation.ToYear=this.NewEducation.To.getFullYear().toString();
                this.NewEducation.ToMonth=this.NewEducation.To.getMonth()+1;
                this.NewEducation.Pursuing=false;
                }
                this.NewEducation.FromYear=this.NewEducation.From.getFullYear().toString();
                this.NewEducation.FromMonth=this.NewEducation.From.getMonth()+1;
        }
        else
        {
              this.NewEducation.FromYear=1900;
              this.NewEducation.FromMonth=0;
              this.NewEducation.ToYear=1900;
              this.NewEducation.ToMonth=0;
        }
        if (arbAPiresponse["data"]["User"]["Skills"].length > 0) 
        {
          this.NewSkill = arbAPiresponse["data"]["User"]["Skills"][0];
          this.NewSkillList = arbAPiresponse["data"]["User"]["Skills"];
        }
        if (arbAPiresponse["data"]["User"]["TrainingCourses"].length > 0) 
        {
                this.NewTraining = arbAPiresponse["data"]["User"]["TrainingCourses"][0];
                this.NewTrainingList = arbAPiresponse["data"]["User"]["TrainingCourses"];
                
                if( this.NewTraining.From ==null)
                {
                  this.NewTraining.From =new Date();
                }
                else
                {
                  this.NewTraining.From = new Date(this.NewTraining.From);
                }
                if( this.NewTraining.To ==null)
                {
                  this.NewTraining.To = new Date();
                  this.NewTraining.ToYear=1900;
                  this.NewTraining.ToMonth=0;
                  this.NewTraining.Pursuing=true
                }
                else
                {
                  this.NewTraining.To = new Date(this.NewTraining.To);
                  this.NewTraining.ToYear=this.NewTraining.To.getFullYear().toString();
                this.NewTraining.ToMonth=this.NewTraining.To.getMonth()+1;
                this.NewTraining.Pursuing=false;
                }
                this.NewTraining.FromYear=this.NewTraining.From.getFullYear().toString();
                this.NewTraining.FromMonth=this.NewTraining.From.getMonth()+1;
        }
        else
        {
              this.NewTraining.FromYear=1900;
              this.NewTraining.FromMonth=0;
              this.NewTraining.ToYear=1900;
              this.NewTraining.ToMonth=0;
        }
        if (arbAPiresponse["data"]["User"]["Languages"].length > 0) 
        {
          this.NewLanguage = arbAPiresponse["data"]["User"]["Languages"][0];
          this.NewLanguageList = arbAPiresponse["data"]["User"]["Languages"];
        }
        if (arbAPiresponse["data"]["User"]["Certifications"].length > 0) 
        {
                this.NewCertification = arbAPiresponse["data"]["User"]["Certifications"][0];
                this.NewCertificationList = arbAPiresponse["data"]["User"]["Certifications"];
                
                if( this.NewCertification.From ==null)
                {
                  this.NewCertification.From =new Date();
                }
                else
                {
                  this.NewCertification.From = new Date(this.NewCertification.From);
                }
                if( this.NewCertification.To ==null)
                {
                  this.NewCertification.To = new Date();
                  this.NewCertification.ToYear=1900;
                  this.NewCertification.ToMonth=0;
                  this.NewCertification.Pursuing=true
                }
                else
                {
                  this.NewCertification.To = new Date(this.NewCertification.To);
                  this.NewCertification.ToYear=this.NewCertification.To.getFullYear().toString();
                this.NewCertification.ToMonth=this.NewCertification.To.getMonth()+1;
                this.NewCertification.Pursuing=false;
                }
                this.NewCertification.FromYear=this.NewCertification.From.getFullYear().toString();
                this.NewCertification.FromMonth=this.NewCertification.From.getMonth()+1;
        }
        else
        {
              this.NewCertification.FromYear=1900;
              this.NewCertification.FromMonth=0;
              this.NewCertification.ToYear=1900;
              this.NewCertification.ToMonth=0;
        }
        if (arbAPiresponse["data"]["User"]["Memberships"].length > 0) 
        {
                this.NewMembership = arbAPiresponse["data"]["User"]["Memberships"][0];
                this.NewMembershipList = arbAPiresponse["data"]["User"]["Memberships"];
                
                if( this.NewMembership.From ==null)
                {
                  this.NewMembership.From =new Date();
                }
                else
                {
                  this.NewMembership.From = new Date(this.NewMembership.From);
                }
                this.NewMembership.FromYear=this.NewMembership.From.getFullYear().toString();
                this.NewMembership.FromMonth=this.NewMembership.From.getMonth()+1;
        }
        else
        {
              this.NewMembership.FromYear=1900;
              this.NewMembership.FromMonth=0;
        }


        if (arbAPiresponse["data"]["User"]["WorkExperiences"].length) 
        {
            this.NewWork = arbAPiresponse["data"]["User"]["WorkExperiences"][0];
            this.NewWorkList = arbAPiresponse["data"]["User"]["WorkExperiences"];
            
            if( this.NewWork.From ==null)
            {
              this.NewWork.From ==new Date();
            }else
            {
              this.NewWork.From = new Date(this.NewWork.From);
            }
            if( this.NewWork.To ==null)
            {
              this.NewWork.To = new Date();
              this.NewWork.WToYear=1900;
              this.NewWork.WToMonth=0;
              this.NewWork.Pursuing=true
            }
            else
            {
              this.NewWork.To = new Date(this.NewWork.To);
              this.NewWork.WToYear=this.NewWork.To.getFullYear().toString();
              this.NewWork.WToMonth=this.NewWork.To.getMonth()+1;
              this.NewWork.Pursuing=false
            }
            this.NewWork.WFromYear=this.NewWork.From.getFullYear().toString();
            this.NewWork.WFromMonth=this.NewWork.From.getMonth()+1;
         
        }
        else
        {
          this.NewWork.WFromYear=1900;
          this.NewWork.WFromMonth=0;
          this.NewWork.WToYear=1900;
          this.NewWork.WToMonth=0;
        }
        if(this.NewWork.IsExperience==null){
          this.NewWork.IsExperience=false;
        }
        if (this.MyProfile.RegisteredAs == null)
          this.MyProfile.RegisteredAs = 'I';
        if (this.MyProfile.WallPostRadius == null)
          this.MyProfile.WallPostRadius = 0;
          if(this.MyProfile.Gender==null)
          this.MyProfile.Gender='';
          if(this.MyProfile.CityID==null)
          this.MyProfile.CityID =0;
          if(this.MyProfile.Myself==null)
          this.MyProfile.Myself='';
          if(this.MyProfile.AboutMe==null)
          this.MyProfile.AboutMe='';
          this.MyProfile.Zip=arbAPiresponse["data"]["User"]["ContactInfo"].PostalCode;
       
          if(this.MyProfile.LocationName==null || this.MyProfile.LocationName ==''){
            this.MyProfile.LocationName='Egypt';
          }
          if(this.MyProfile.LocationName==null || this.MyProfile.LocationName ==''){
            this.MyProfile.LocationName='Egypt';
          }
         
          if(arbAPiresponse["data"]["User"]["ContactInfo"].LocationName=='' || arbAPiresponse["data"]["User"]["ContactInfo"].LocationName==null){
            if(this.MyProfile.CountryID==53)
            {
            this.MyProfile.LocationName ='Egypt';
            this.MyProfile.Long = '31.24967';
            this.MyProfile.Lat = '30.06263';
            }
            if( this.MyProfile.CountryID==182)
            {
            this.MyProfile.LocationName ='United Arab Emirates';
            this.MyProfile.Long = '55.296249';
            this.MyProfile.Lat = '25.276987';
            }
            if( this.MyProfile.CountryID==32)
            {
            this.MyProfile.LocationName ='Canada';
            this.MyProfile.Long = '106.3468';
            this.MyProfile.Lat = '56.1304';
            }
          }
          else
          {
            this.MyProfile.LocationName = arbAPiresponse["data"]["User"]["ContactInfo"].LocationName;
            this.MyProfile.Long = arbAPiresponse["data"]["User"]["ContactInfo"].Longitude;
            this.MyProfile.Lat = arbAPiresponse["data"]["User"]["ContactInfo"].Latitude;
          }
          if(arbAPiresponse["data"]["User"]["BasicInfo"].BirthDate!=null && arbAPiresponse["data"]["User"]["BasicInfo"].BirthDate!='')
          {
            this.MyProfile.BirthDate= new Date(arbAPiresponse["data"]["User"]["BasicInfo"].BirthDate);
          }else{
            this.MyProfile.BirthDate= new Date();
            this.MyProfile.BirthDate.setFullYear(this.MyProfile.BirthDate.getFullYear() - 1);
          }
          if((arbAPiresponse["data"]["User"]["ContactInfo"]!=null) && (arbAPiresponse["data"]["User"]["ContactInfo"].Mobile!='')){
            this.MyProfile.Mobile=arbAPiresponse["data"]["User"]["ContactInfo"].Mobile;
          }else{
            this.MyProfile.Mobile='';
          }
          if(this.SectionVisibility.Interest!=null)
          this.interstReview=this.SectionVisibility.Interest;
          else
          this.interstReview=false;
          if(this.SectionVisibility.WorkExperience!=null)
          this.NewWork.expReview=this.SectionVisibility.WorkExperience;
          else
          this.NewWork.expReview=false;
          if(this.SectionVisibility.Education!=null)
          this.NewEducation.educReview=this.SectionVisibility.Education;
          else
          this.NewEducation.educReview=false;
          if(this.SectionVisibility.Skill!=null)
          this.NewSkill.skilReview=this.SectionVisibility.Skill;
          else
          this.NewSkill.skilReview=false;
           if(this.SectionVisibility.Training!=null)
          this.NewTraining.TrainingReview=this.SectionVisibility.Training;
          else
          this.NewTraining.TrainingReview=false;
          if(this.SectionVisibility.Certification!=null)
          this.NewCertification.certifiReview=this.SectionVisibility.Certification;
          else
          this.NewCertification.certifiReview=false;
          if(this.SectionVisibility.Language!=null)
          this.NewLanguage.lamgReview=this.SectionVisibility.Language;
          else
          this.NewLanguage.lamgReview=false;
          if(this.SectionVisibility.Membership!=null)
          this.NewMembership.memberReview=this.SectionVisibility.Membership;
          else
          this.NewMembership.memberReview=false;
        //btnLoadMap
        document.getElementById('btnLoadMap').click();

      //  // if(document.getElementById('lblCurrentLocation')!=null){
      //     debugger;
      //     if(this.MyProfile.LocationName=='')
      //     this.MyProfile.LocationName=document.getElementById('lblCurrentLocation').innerHTML;
      //   //}

      },
      err => console.error(err)
    );

    this.serviceObj.getArbData('GetUserInterestCategories', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.UserInterestCategories = ArbApiResponse["data"];
        for (let I of this.UserInterestCategories)
          if (I.Ischecked)
            this.PopulateUserInterest(I);
      },
      err => console.error(err)
    );

    this.serviceObj.getArbData('ArabesqueWallCategories', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.UserCategories = ArbApiResponse["data"];
        for (let C of this.UserCategories)
          if (C.Ischecked)
            this.PopulateUserCategories(C);
      },
      err => console.error(err)
    );
 
  }


  OnCountryChnage(){
    this.Cities = this.AllCities.filter(x=> x.CountryID==this.MyProfile.CountryID);
  }

  PursuingCheckWork(e){
    if(e.target.checked){
      this.NewWork.To = new Date();
        this.NewWork.WToYear=1900;
        this.NewWork.WToMonth=0;
    }
    else{
             this.NewWork.To = new Date();
              this.NewWork.WToYear=this.NewWork.To.getFullYear().toString();
              this.NewWork.WToMonth=this.NewWork.To.getMonth()+1;
    }
  }

  PursuingCheck(e){
    if(e.target.checked){
        this.NewEducation.To = new Date();
        this.NewEducation.ToYear=1900;
        this.NewEducation.ToMonth=0;
    }
    else{
      this.NewEducation.To = new Date();
      this.NewEducation.ToYear=this.NewEducation.To.getFullYear().toString();
    this.NewEducation.ToMonth=this.NewEducation.To.getMonth()+1;
    }
  }
  //      //find no image in text
  //      if(var string = "foo",
  //      substring = "oo";
  //  string.indexOf(substring) !== -1;)
  GetImageExist(URL:any)
  {
    if(URL.indexOf('nopreview') !== -1)
    return false;
    else
    return true
  }
/*------------------*/
// fileChangeListenerProfile($event) {
//   var image:any = new Image();
//   var file:File = $event.target.files[0];
//   if(file!=undefined){
//   var myReader:FileReader = new FileReader();
//   var that = this;
//   myReader.onloadend = function (loadEvent:any) {
//       image.src = loadEvent.target.result;
//      // that.cropperProfile.setImage(image);
//   };
//   myReader.readAsDataURL(file);
// }
//}
closeCroProfile(){
   $('#MdlCropImgProfile').removeClass('show'); 
    $('#MdlCropImgProfile').addClass('fade'); 
    $('body').removeClass('modal-open');   
}

 CropImageProfile(){
  this.dataProfile={};
  this.tempImagProfile='';
  $('#MdlCropImgProfile').removeClass('fade'); 
  $('body').addClass('modal-open');  
  $('#MdlCropImgProfile').addClass('show');   
} 


SaveImageProfile()
{
if(this.croppedImage!='')
{
        let blob=this.dataURItoBlob(this.croppedImage);
        let typeimg=this.base64MimeType(this.croppedImage);
        var t=typeimg.split('/')[1];
        let names:string='filename.'+t;
        var file = new File([blob], names, {type: typeimg});
       // this.NewEvent.CoverPic=file;
       this.ProfileImages.ProfilePic0=file;
       this.ProfileImages.ProfilePic=file;
        this.imageUrlProfilePic=this.croppedImage;
      
      $('#MdlCropImgProfile').removeClass('show'); 
      $('#MdlCropImgProfile').addClass('fade'); 
      $('body').removeClass('modal-open');
}
    }



/*------------------*/
//   fileChangeListener($event) {
//     var image:any = new Image();
//     var file:File = $event.target.files[0];
//     if(file!=undefined){
//     var myReader:FileReader = new FileReader();
//     var that = this;
//     myReader.onloadend = function (loadEvent:any) {
//         image.src = loadEvent.target.result;
//         //that.cropper.setImage(image);
//     };
//     myReader.readAsDataURL(file);
//   }
// }
  closeCro(){
    $('#MdlCropImg').removeClass('show'); 
    $('#MdlCropImg').addClass('fade'); 
    $('body').removeClass('modal-open');   
  }

   CropImage(){
    this.data2={};
    this.tempImag='';
    $('#MdlCropImg').removeClass('fade'); 
    $('body').addClass('modal-open');  
    $('#MdlCropImg').addClass('show'); 
  } 

    base64MimeType(encoded) 
    {
    var result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

  SaveImage()
      {
        if(this.croppedImageC!='')
        {
          let blob=this.dataURItoBlob(this.croppedImageC);
          let typeimg=this.base64MimeType(this.croppedImageC);
          var t=typeimg.split('/')[1];
          let names:string='filename.'+t;
          var file = new File([blob], names, {type: typeimg});
         this.ProfileImages.CoverPic0=file;
         this.ProfileImages.CoverPic=file;
          this.imageUrlCoverPic=this.croppedImageC;
        
        $('#MdlCropImg').removeClass('show'); 
        $('#MdlCropImg').addClass('fade'); 
        $('body').removeClass('modal-open');
        }
      }


   dataURItoBlob(dataURI) {
          var binary = atob(dataURI.split(',')[1]);
          var array = [];
        for (var i = 0; i < binary.length; i++) 
        {
          array.push(binary.charCodeAt(i));
        }
       return new Blob([new Uint8Array(array)], {    type: 'image/jpg'});
    }


  AddUserCategories(C: any) {
    if (C.Ischecked)
      this.MyCategories = this.MyCategories.filter(i => i.CategoryID !== C.CategoryID)
    else
      this.MyCategories.push({ 'CategoryID': C.CategoryID, 'Category': C.Category, 'Ischecked': C.Ischecked });
  }

  PopulateUserCategories(C: any) {
    if (!C.Ischecked)
      this.MyCategories = this.MyCategories.filter(i => i.CategoryID !== C.CategoryID)
    else
      this.MyCategories.push({ 'CategoryID': C.CategoryID, 'Category': C.Category, 'Ischecked': C.Ischecked });
  }

  SaveMyCategory() {
  
    this.MySelectedCategories.CategoryIDs = '';
    // if(this.MyCategories)
    // {
      for (let x of this.MyCategories) {
        if (this.MySelectedCategories.CategoryIDs == '') {
          this.MySelectedCategories.CategoryIDs = x.CategoryID;
        }
        else {
          this.MySelectedCategories.CategoryIDs += ',' + x.CategoryID;
        }
      }

      this.serviceObj.postArbData('SavePreferredCategories', this.MySelectedCategories).subscribe(
        ArbApiResponse => {
          document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          document.getElementById('btnMessageModal').click();
          if(ArbApiResponse["Status"]==1)
          {
                $('#basicInfoTab').removeClass('active');
                $('#controlPanMenu').removeClass('active');
                $('#myInterTab').removeClass('active');
                $('#eduWorkTab').removeClass('active');
                $('#changePassTab').removeClass('active');
                $('#changeTab').removeClass('active');
                $('#eduWorkTab').addClass('active');

                $('#basicTab').removeClass('active');
                $('#controlPanTab').removeClass('active');
                $('#interTab').removeClass('active');
                $('#eduTab').removeClass('active');
                $('#eduTab').addClass('active'); 
          }
        },
        ArbApiErr => {
          // this.UserDetails = null;
        }
      );
    // }
    // else
    // {
    //   document.getElementById('divMessage').innerHTML = "Please select the Category";
    //   document.getElementById('btnMessageModal').click();
    // }

  }

  AddUserInterest(I: any) {
    if (I.Ischecked)
      this.MyInterests = this.MyInterests.filter(i => i.InterestID !== I.InterestID)
    else
      this.MyInterests.push({ 'InterestID': I.InterestID, 'Interest': I.Interest, 'Ischecked': I.Ischecked });
  }

  PopulateUserInterest(I: any) {
    if (!I.Ischecked)
      this.MyInterests = this.MyInterests.filter(i => i.InterestID !== I.InterestID)
    else
      this.MyInterests.push({ 'InterestID': I.InterestID, 'Interest': I.Interest, 'Ischecked': I.Ischecked });
  }

  RemovePicture(Type:string){
    let UserPiture:any={};
    if(Type=="C")
    {
      UserPiture.ImageType='Cover';
        this.imageUrlCoverPic='';
    }else if(Type=="P")
    {
      UserPiture.ImageType='Profile';
      this.imageUrlProfilePic='';
    }
    else if(Type=='B')
    {
      UserPiture.ImageType='Both';
      this.imageUrlProfilePic='';
    }

    this.serviceObj.postArbData('RemoveProfileImages', UserPiture).subscribe(
      ArbApiResponse => {
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        if(ArbApiResponse["Status"]==1)
        {
        this.serviceObj.getArbData('UserDetails', null).subscribe(
          ArbApiResponse => {
            if(ArbApiResponse["Status"]==1)
            {
              this.serviceObj.removeUD();
            this.serviceObj.setUD(ArbApiResponse["data"], false);   
                setTimeout(() => {
                  this.RefreshUD();
                 this.imageUrlCoverPic= this.UserDetails.CoverPic
                  this.imageUrlProfilePic=this.UserDetails.PictureUrl;
                  document.getElementById('btnHeaderRefreshUD').click();
                 // this.ngOnInit();
                }, 1500);
              }
          });
   
      }

      },
      ArbApiErr => {
        // this.UserDetails = null;
      }
    );



  }

  SaveInterests() 
  {
    this.MySelectedInterests.InterestIDs = "";
    // if(this.MyInterests.lenght>0)
    // {
        for (let x of this.MyInterests) {
          if (this.MySelectedInterests.InterestIDs == "") {
            this.MySelectedInterests.InterestIDs = x.InterestID;
          }
          else {
            this.MySelectedInterests.InterestIDs += ',' + x.InterestID;
          }
        }
        this.MySelectedInterests.interstReview=this.SectionVisibility.Interest;

        this.serviceObj.postArbData('SaveInterests', this.MySelectedInterests).subscribe(
          ArbApiResponse => {
            document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
            document.getElementById('btnMessageModal').click();
            if(ArbApiResponse["Status"]==1)
            {
                      this.serviceObj.getArbData('UserDetails', null).subscribe(
                        ArbApiResponse => {
                          this.serviceObj.removeUD();
                          this.serviceObj.setUD(ArbApiResponse["data"], false);
                          this.RefreshUD();
                          document.getElementById('btnRefreshUD').click();
                        }
                      );
                    
                      $('#basicInfoTab').removeClass('active');
                      $('#controlPanMenu').removeClass('active');
                      $('#myInterTab').removeClass('active');
                      $('#eduWorkTab').removeClass('active');
                      $('#changePassTab').removeClass('active');
                      $('#changeTab').removeClass('active');
                      $('#eduWorkTab').addClass('active');

                      $('#basicTab').removeClass('active');
                      $('#controlPanTab').removeClass('active');
                      $('#interTab').removeClass('active');
                      $('#eduTab').removeClass('active');
                      $('#eduTab').addClass('active'); 
                    }
          },
          ArbApiErr => {
            // this.UserDetails = null;
          }
        );
      // }else
      // {
      //   document.getElementById('divMessage').innerHTML = "Please select the Interests";
      //       document.getElementById('btnMessageModal').click();
      // }
  }


  Edit(Items: any) {
    this.serviceObj.SetData(Items);
    this.router.navigate(['/AddExchange'])
  }

  SaveBasicInfo() {
    if (this.MyProfile.FirstName == '') {
      this.isValid = false;
    }
    else {
      this.isValid = true;
    }
    // if (document.getElementById('Zip')["value"]== '') {
    //   this.isValidZip = false;
    // }
    // else {
       this.isValidZip = true;
       this.MyProfile.Zip=document.getElementById('Zip')["value"];
    // }
    if (document.getElementById('txtCountrySearch')["value"] == ''){
      this.MyProfile.LocationName ='';
      this.MyProfile.Lat = '';
      this.MyProfile.Long = '';
    }
    if (document.getElementById('txtAddressCoords')["value"] != '') {
      this.MyProfile.Lat = document.getElementById('txtAddressCoords')["value"].split(' - ')[0];
      this.MyProfile.Long = document.getElementById('txtAddressCoords')["value"].split(' - ')[1];
      this.MyProfile.LocationName =document.getElementById('txtCountrySearch')["value"];
    }

    this.MyProfile.BirthDate = new Date(this.MyProfile.BirthDate);
    this.MyProfile.BirthDate = this.MyProfile.BirthDate.getFullYear().toString() + '-' + (this.MyProfile.BirthDate.getMonth() + 1).toString() + '-' + this.MyProfile.BirthDate.getDate().toString();
          if((this.isValidZip) && (this.isValid))
          {
            this.serviceObj.postArbData('SaveBasicInfo', this.MyProfile).subscribe(
              ArbApiResponse => {
                document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
                document.getElementById('btnMessageModal').click();
                
                if(ArbApiResponse["Status"]==1)
                {
                      this.serviceObj.getArbData('UserDetails', null).subscribe(
                        ArbApiResponse => {
                          this.serviceObj.removeUD();
                          this.serviceObj.setUD(ArbApiResponse["data"], false);
                          this.RefreshUD();
                          document.getElementById('btnHeaderRefreshUD').click();
                        }
                      );
                      $('#basicInfoTab').removeClass('active');
                      $('#controlPanMenu').removeClass('active');
                      $('#myInterTab').removeClass('active');
                      $('#eduWorkTab').removeClass('active');
                      $('#changePassTab').removeClass('active');
                      $('#changeTab').removeClass('active');
                      $('#controlPanMenu').addClass('active');
        
                      $('#basicTab').removeClass('active');
                      $('#controlPanTab').removeClass('active');
                      $('#interTab').removeClass('active');
                      $('#eduTab').removeClass('active');
                      $('#controlPanTab').addClass('active');
              }
            
            }

            );
          }
}


  AddInterest() {

    //this.InterestList.push();
  }

  SaveEducation(eduForm: NgForm) {
    this.eduSubmited = false;
    if (eduForm.valid && (this.NewEducation.FromYear!=1900)&&(this.NewEducation.FromMonth!=0))
    {
     if(this.NewEducation.Degree== '' || this.NewEducation.Institute == '') 
     {
      this.eduSubmited = true;
     }
     if(this.eduSubmited==false)
     {
      this.NewEducation.From = this.NewEducation.FromYear + '-' + this.NewEducation.FromMonth + '-' +1;
      if(this.NewEducation.Pursuing==true)
      this.NewEducation.To ='';
      else
      {
        this.NewEducation.To = this.NewEducation.ToYear + '-' + this.NewEducation.ToMonth+ '-' +20;
      }
      if(this.NewEducation.Pursuing==false &&(this.NewEducation.ToYear!=1900)&&(this.NewEducation.ToMonth!=0))
      {

      }
      this.serviceObj.postArbData('AddUpdateEducation', this.NewEducation).subscribe(
        ArbApiResponse => {
          document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          document.getElementById('btnMessageModal').click();
          this.eduSubmited = true;
          if(ArbApiResponse["Status"]==1)
          {
             this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
              arbAPiresponse => {
              if (arbAPiresponse["data"]["User"]["Educations"].length > 0) 
                {
                        this.NewEducation = arbAPiresponse["data"]["User"]["Educations"][0];
                        this.NewEducationList = arbAPiresponse["data"]["User"]["Educations"];
                        if( this.NewEducation.From ==null)
                        {
                          this.NewEducation.From =new Date();
                        }
                        else
                        {
                          this.NewEducation.From = new Date(this.NewEducation.From);
                        }
                        if( this.NewEducation.To ==null)
                        {
                          this.NewEducation.To = new Date();
                          this.NewEducation.ToYear=1900;
                          this.NewEducation.ToMonth=0;
                          this.NewEducation.Pursuing=true
                        }
                        else
                        {
                          this.NewEducation.To = new Date(this.NewEducation.To);
                          this.NewEducation.ToYear=this.NewEducation.To.getFullYear().toString();
                        this.NewEducation.ToMonth=this.NewEducation.To.getMonth()+1;
                        this.NewEducation.Pursuing=false;
                        }
                        this.NewEducation.FromYear=this.NewEducation.From.getFullYear().toString();
                        this.NewEducation.FromMonth=this.NewEducation.From.getMonth()+1;
                }
              }, err => console.error(err)
              );
        
          }
        },
        ArbApiErr => {
          //this.UserDetails = null;
          this.eduSubmited = true;
        }
      );
     }
    }
  }

  closeModel(){

    // $('#EducationModel').removeClass('show ng-star-inserted'); 
    //  $('#EducationModel').addClass('fade'); 
    $('body').removeClass('modal-open');  
      $('#EducationModel').hide();
 }

  ShowModel(x:any,AddUpdate:number){

    $('#EducationModel').removeClass('fade'); 
    $('body').addClass('modal-open');  
    $('#EducationModel').addClass('modal show ng-star-inserted');
    $('#EducationModel').show();
    
    this.NewEducation.AddUpdateFlag=AddUpdate;

    if(this.NewEducation.AddUpdateFlag==1){
      this.NewEducation=x;
      this.NewEducation.ID=x.ID;}
    else {
     this.NewEducation.ID=0;
     this.NewEducation.Degree=null;
     this.NewEducation.From=null;
     this.NewEducation.To =null;
     this.NewEducation.Pursuing=null;
     this.NewEducation.Institute=null;
     this.NewEducation.Description=null;
    }
    if( this.NewEducation.From ==null)
    {
      this.NewEducation.From =new Date();
    }
    else
    {
      this.NewEducation.From = new Date(this.NewEducation.From);
    }
    if( this.NewEducation.To ==null)
    {
      this.NewEducation.To = new Date();
      this.NewEducation.ToYear=1900;
      this.NewEducation.ToMonth=0;
      this.NewEducation.Pursuing=true
    }
    else
    {
      this.NewEducation.To = new Date(this.NewEducation.To);
      this.NewEducation.ToYear=this.NewEducation.To.getFullYear().toString();
    this.NewEducation.ToMonth=this.NewEducation.To.getMonth()+1;
    this.NewEducation.Pursuing=false;
    }
    this.NewEducation.FromYear=this.NewEducation.From.getFullYear().toString();
    this.NewEducation.FromMonth=this.NewEducation.From.getMonth()+1;
  }

  DeleteEdu(x: any) {
  this.NewEducation=x;
    this.serviceObj.postArbData('DeleteEducation', this.NewEducation).subscribe(
        ArbApiResponse => {
         // document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          //document.getElementById('btnMessageModal').click();
          if(ArbApiResponse["Status"]==1)
          { 
            this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
            arbAPiresponse => {
            if (arbAPiresponse["data"]["User"]["Educations"].length > 0) 
              {
                      this.NewEducation = arbAPiresponse["data"]["User"]["Educations"][0];
                      this.NewEducationList = arbAPiresponse["data"]["User"]["Educations"];
                      if( this.NewEducation.From ==null)
                      {
                        this.NewEducation.From =new Date();
                      }
                      else
                      {
                        this.NewEducation.From = new Date(this.NewEducation.From);
                      }
                      if( this.NewEducation.To ==null)
                      {
                        this.NewEducation.To = new Date();
                        this.NewEducation.ToYear=1900;
                        this.NewEducation.ToMonth=0;
                        this.NewEducation.Pursuing=true
                      }
                      else
                      {
                        this.NewEducation.To = new Date(this.NewEducation.To);
                        this.NewEducation.ToYear=this.NewEducation.To.getFullYear().toString();
                      this.NewEducation.ToMonth=this.NewEducation.To.getMonth()+1;
                      this.NewEducation.Pursuing=false;
                      }
                      this.NewEducation.FromYear=this.NewEducation.From.getFullYear().toString();
                      this.NewEducation.FromMonth=this.NewEducation.From.getMonth()+1;
              }
            }, err => console.error(err)
            );
           
          }
        },
        ArbApiErr => {
          //this.UserDetails = null;
          this.eduSubmited = true;
        }
      );
    }
 

closeWorkModel(){

  // $('#EducationModel').removeClass('show ng-star-inserted'); 
  //  $('#EducationModel').addClass('fade'); 
   $('body').removeClass('modal-open');  
    $('#WorkExpModel').hide();
}

ShowWorkModel(x:any,AddUpdate:number){

  $('#WorkExpModel').removeClass('fade'); 
  $('body').addClass('modal-open');  
  $('#WorkExpModel').addClass('modal show ng-star-inserted');
  $('#WorkExpModel').show();
  this.NewWork=x;
  this.NewWork.AddUpdateFlag=AddUpdate;
  if(this.NewWork.AddUpdateFlag==1){this.NewWork.ID=x.ID;}
  else {
    this.NewWork.ID=0;
     this.NewWork.CompanyName=null;
     this.NewWork.From=null;
     this.NewWork.To =null;
     this.NewWork.Pursuing=null;
     this.NewWork.Title=null;
     this.NewWork.Description=null;
    }
  if( this.NewWork.From ==null)
  {
    this.NewWork.From ==new Date();
  }else
  {
    this.NewWork.From = new Date(this.NewWork.From);
  }
  if( this.NewWork.To ==null)
  {
    this.NewWork.To = new Date();
    this.NewWork.WToYear=1900;
    this.NewWork.WToMonth=0;
    this.NewWork.Pursuing=true
  }
  else
  {
    this.NewWork.To = new Date(this.NewWork.To);
    this.NewWork.WToYear=this.NewWork.To.getFullYear().toString();
    this.NewWork.WToMonth=this.NewWork.To.getMonth()+1;
    this.NewWork.Pursuing=false
  }
  this.NewWork.WFromYear=this.NewWork.From.getFullYear().toString();
  this.NewWork.WFromMonth=this.NewWork.From.getMonth()+1;
  if(this.NewWork.IsExperience==null){
    this.NewWork.IsExperience=false;
  }
}


SaveWork(workForm: NgForm) {
    this.workSubmited = false;
    if (workForm.valid &&(this.NewWork.WFromYear!=1900 && this.NewWork.WFromMonth!=0)) 
    {
      if(this.NewWork.CompanyName== '' || this.NewWork.Title == '') 
      {
       this.workSubmited = true;
      }
      if(this.workSubmited==false)
      {
      this.NewWork.From = this.NewWork.WFromYear + '-' + this.NewWork.WFromMonth+ '-' +1;
      if(this.NewWork.Pursuing==true)
      this.NewWork.To ='';
      else
      this.NewWork.To = this.NewWork.WToYear + '-' + this.NewWork.WToMonth + '-' + 20;
     
      this.serviceObj.postArbData('AddUpdateWorkExperience', this.NewWork).subscribe(
        ArbApiResponse => {
          document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          document.getElementById('btnMessageModal').click();
          this.workSubmited = true;
          if(ArbApiResponse["Status"]==1)
          {
            this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
              arbAPiresponse => {
              if (arbAPiresponse["data"]["User"]["Educations"].length > 0) 
                {
                  this.NewWork = arbAPiresponse["data"]["User"]["WorkExperiences"][0];
                  this.NewWorkList = arbAPiresponse["data"]["User"]["WorkExperiences"];
                  
                  if( this.NewWork.From ==null)
                  {
                    this.NewWork.From ==new Date();
                  }else
                  {
                    this.NewWork.From = new Date(this.NewWork.From);
                  }
                  if( this.NewWork.To ==null)
                  {
                    this.NewWork.To = new Date();
                    this.NewWork.WToYear=1900;
                    this.NewWork.WToMonth=0;
                    this.NewWork.Pursuing=true
                  }
                  else
                  {
                    this.NewWork.To = new Date(this.NewWork.To);
                    this.NewWork.WToYear=this.NewWork.To.getFullYear().toString();
                    this.NewWork.WToMonth=this.NewWork.To.getMonth()+1;
                    this.NewWork.Pursuing=false
                  }
                  this.NewWork.WFromYear=this.NewWork.From.getFullYear().toString();
                  this.NewWork.WFromMonth=this.NewWork.From.getMonth()+1;
                  if(this.NewWork.IsExperience==null){
                    this.NewWork.IsExperience=false;
                  }
              }
              }, err => console.error(err)
              );
           
        
          }
        },
        ArbApiErr => {
          //this.UserDetails = null;
          this.workSubmited = true;
        }
      );
    }
  }
  }

DeleteWork(x: any) {
    this.NewWork=x;
      this.serviceObj.postArbData('DeleteWrokExp', this.NewWork).subscribe(
          ArbApiResponse => {
         //   document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
           // document.getElementById('btnMessageModal').click();
            if(ArbApiResponse["Status"]==1)
            { 
              this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
              arbAPiresponse => {
                if (arbAPiresponse["data"]["User"]["WorkExperiences"].length > 0) 
                {
                  this.NewWork = arbAPiresponse["data"]["User"]["WorkExperiences"][0];
                  this.NewWorkList = arbAPiresponse["data"]["User"]["WorkExperiences"];
                  
                  if( this.NewWork.From ==null)
                  {
                    this.NewWork.From ==new Date();
                  }else
                  {
                    this.NewWork.From = new Date(this.NewWork.From);
                  }
                  if( this.NewWork.To ==null)
                  {
                    this.NewWork.To = new Date();
                    this.NewWork.WToYear=1900;
                    this.NewWork.WToMonth=0;
                    this.NewWork.Pursuing=true
                  }
                  else
                  {
                    this.NewWork.To = new Date(this.NewWork.To);
                    this.NewWork.WToYear=this.NewWork.To.getFullYear().toString();
                    this.NewWork.WToMonth=this.NewWork.To.getMonth()+1;
                    this.NewWork.Pursuing=false
                  }
                  this.NewWork.WFromYear=this.NewWork.From.getFullYear().toString();
                  this.NewWork.WFromMonth=this.NewWork.From.getMonth()+1;
                  if(this.NewWork.IsExperience==null){
                    this.NewWork.IsExperience=false;
                  }
              }
              }, err => console.error(err)
              );
            
            }
          },
          ArbApiErr => {
            //this.UserDetails = null;
            this.eduSubmited = true;
          }
        );
      }
   
closeSkillModel(){
        // $('#EducationModel').removeClass('show ng-star-inserted'); 
        //  $('#EducationModel').addClass('fade'); 
        $('body').removeClass('modal-open');  
          $('#SkillModel').hide();
     }
    
ShowSkillModel(x:any,AddUpdate:number)
{

        $('#SkillModel').removeClass('fade'); 
        $('body').addClass('modal-open');  
        $('#SkillModel').addClass('modal show ng-star-inserted');
        $('#SkillModel').show();
        this.NewSkill=x;
        this.NewSkill.AddUpdateFlag=AddUpdate;
        if(this.NewSkill.AddUpdateFlag==1){this.NewSkill.ID=x.ID;}
        else {
          this.NewSkill.ID=0;
          this.NewSkill.Name=null;
          this.NewSkill.ProficiencyID=null;
          this.NewSkill.Description=null;}
       
}
SaveSkill(SkillForm: NgForm){
        this.skillSubmited = false;
        if (SkillForm.valid) 
        {
          if(this.NewSkill.Name== '') 
            {
       this.skillSubmited = true;
            }
      if(this.skillSubmited==false)
      {
       this.serviceObj.postArbData('AddUpdateSkill', this.NewSkill).subscribe(
            ArbApiResponse => {
              document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
              document.getElementById('btnMessageModal').click();
              this.skillSubmited = true;
              if(ArbApiResponse["Status"]==1)
              {
                this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
                  arbAPiresponse => {
                  if (arbAPiresponse["data"]["User"]["Skills"].length > 0) 
                    {
                      this.NewSkill = arbAPiresponse["data"]["User"]["Skills"][0];
                      this.NewSkillList = arbAPiresponse["data"]["User"]["Skills"];
                      
                  }
                  }, err => console.error(err)
                  );
               
              }
            },
            ArbApiErr => {
              //this.UserDetails = null;
            //  this.workSubmited = true;
            }
          );
        }
      }
      }
    
DeleteSkill(x: any) {

        this.NewSkill=x;
          this.serviceObj.postArbData('DeleteSkill', this.NewSkill).subscribe(
              ArbApiResponse => {
               // document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
                //document.getElementById('btnMessageModal').click();
                if(ArbApiResponse["Status"]==1)
                { 
                  this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
                  arbAPiresponse => {
                    if (arbAPiresponse["data"]["User"]["Skills"].length > 0) 
                    {
                      this.NewSkill = arbAPiresponse["data"]["User"]["Skills"][0];
                      this.NewSkillList = arbAPiresponse["data"]["User"]["Skills"];
                      
                  }
                  }, err => console.error(err)
                  );
                 
                }
              },
              ArbApiErr => {
                //this.UserDetails = null;
                //this.eduSubmited = true;
              }
            );
          }

closeTrainingModel(){

        // $('#EducationModel').removeClass('show ng-star-inserted'); 
        //  $('#EducationModel').addClass('fade'); 
        $('body').removeClass('modal-open');  
          $('#TrainingModel').hide();
     }
    
ShowTrainingModel(x:any,AddUpdate:number){

        $('#TrainingModel').removeClass('fade'); 
        $('body').addClass('modal-open');  
        $('#TrainingModel').addClass('modal show ng-star-inserted');
        $('#TrainingModel').show();
        this.NewTraining=x;
        this.NewTraining.AddUpdateFlag=AddUpdate;
        if(this.NewTraining.AddUpdateFlag==1){this.NewTraining.ID=x.ID;}
        else {this.NewTraining.ID=0;
          this.NewTraining.Name=null;
          this.NewTraining.Institute=null;
          this.NewTraining.From=null;
          this.NewTraining.To=null;
          this.NewTraining.Pursuing=null;
          this.NewTraining.Hours=null;
        }
       
      }    
    
SaveTraining(TrainingForm: NgForm){

        this.TrainingSubmited = false;
        if (TrainingForm.valid && (this.NewTraining.FromYear!=1900)&&(this.NewTraining.FromMonth!=0))
    {
      if(this.NewTraining.Name== '' && this.NewTraining.Institute) 
      {
 this.TrainingSubmited = true;
      }
if(this.TrainingSubmited==false)
{
      this.NewTraining.From = this.NewTraining.FromYear + '-' + this.NewTraining.FromMonth + '-' +1;
      if(this.NewTraining.Pursuing==true)
      this.NewTraining.To ='';
      else
      {
        this.NewTraining.To = this.NewTraining.ToYear + '-' + this.NewTraining.ToMonth+ '-' +20;
      }
      if(this.NewTraining.Pursuing==false &&(this.NewTraining.ToYear!=1900)&&(this.NewTraining.ToMonth!=0))
      {

      }
    
       this.serviceObj.postArbData('AddUpdateTraining', this.NewTraining).subscribe(
            ArbApiResponse => {
              document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
              document.getElementById('btnMessageModal').click();
              this.skillSubmited = true;
              if(ArbApiResponse["Status"]==1)
              {
                this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
                  arbAPiresponse => {
                    if (arbAPiresponse["data"]["User"]["TrainingCourses"].length > 0) 
        {
                this.NewTraining = arbAPiresponse["data"]["User"]["TrainingCourses"][0];
                this.NewTrainingList = arbAPiresponse["data"]["User"]["TrainingCourses"];
                
                if( this.NewTraining.From ==null)
                {
                  this.NewTraining.From =new Date();
                }
                else
                {
                  this.NewTraining.From = new Date(this.NewTraining.From);
                }
                if( this.NewTraining.To ==null)
                {
                  this.NewTraining.To = new Date();
                  this.NewTraining.ToYear=1900;
                  this.NewTraining.ToMonth=0;
                  this.NewTraining.Pursuing=true
                }
                else
                {
                  this.NewTraining.To = new Date(this.NewTraining.To);
                  this.NewTraining.ToYear=this.NewTraining.To.getFullYear().toString();
                this.NewTraining.ToMonth=this.NewTraining.To.getMonth()+1;
                this.NewTraining.Pursuing=false;
                }
                this.NewTraining.FromYear=this.NewTraining.From.getFullYear().toString();
                this.NewTraining.FromMonth=this.NewTraining.From.getMonth()+1;
                     }
                     else
        {
              this.NewTraining.FromYear=1900;
              this.NewTraining.FromMonth=0;
              this.NewTraining.ToYear=1900;
              this.NewTraining.ToMonth=0;
                     }
                  }, err => console.error(err)
                  );
                

              }
            },
            ArbApiErr => {
              //this.UserDetails = null;
            this.TrainingSubmited = true;
            }
          );
        }
      }
      }
    
DeleteTraining(x: any) {

        this.NewTraining=x;
          this.serviceObj.postArbData('DeleteTraining', this.NewTraining).subscribe(
              ArbApiResponse => {
           //     document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
             //   document.getElementById('btnMessageModal').click();
                if(ArbApiResponse["Status"]==1)
                { 
                  this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
                  arbAPiresponse => {
                    if (arbAPiresponse["data"]["User"]["TrainingCourses"].length > 0) 
                    {
                            this.NewTraining = arbAPiresponse["data"]["User"]["TrainingCourses"][0];
                            this.NewTrainingList = arbAPiresponse["data"]["User"]["TrainingCourses"];
                            
                            if( this.NewTraining.From ==null)
                            {
                              this.NewTraining.From =new Date();
                            }
                            else
                            {
                              this.NewTraining.From = new Date(this.NewTraining.From);
                            }
                            if( this.NewTraining.To ==null)
                            {
                              this.NewTraining.To = new Date();
                              this.NewTraining.ToYear=1900;
                              this.NewTraining.ToMonth=0;
                              this.NewTraining.Pursuing=true
                            }
                            else
                            {
                              this.NewTraining.To = new Date(this.NewTraining.To);
                              this.NewTraining.ToYear=this.NewTraining.To.getFullYear().toString();
                            this.NewTraining.ToMonth=this.NewTraining.To.getMonth()+1;
                            this.NewTraining.Pursuing=false;
                            }
                            this.NewTraining.FromYear=this.NewTraining.From.getFullYear().toString();
                            this.NewTraining.FromMonth=this.NewTraining.From.getMonth()+1;
                    }
                    else
                    {
                          this.NewTraining.FromYear=1900;
                          this.NewTraining.FromMonth=0;
                          this.NewTraining.ToYear=1900;
                          this.NewTraining.ToMonth=0;
                    }
                  }, err => console.error(err)
                  );
                  
                }
              },
              ArbApiErr => {
                //this.UserDetails = null;
                this.TrainingSubmited = true;
              }
            );
          }

closeLanguageModel(){

            // $('#EducationModel').removeClass('show ng-star-inserted'); 
            //  $('#EducationModel').addClass('fade'); 
             $('body').removeClass('modal-open');  
              $('#LanguageModel').hide();
         }
        
ShowLanguagesModel(x:any,AddUpdate:number){

            $('#LanguageModel').removeClass('fade'); 
            $('body').addClass('modal-open');  
            $('#LanguageModel').addClass('modal show ng-star-inserted');
            $('#LanguageModel').show();
            this.NewLanguage=x;
            this.NewLanguage.AddUpdateFlag=AddUpdate;
            if(this.NewLanguage.AddUpdateFlag==1){this.NewLanguage.ID=x.ID;}
            else {this.NewLanguage.ID=0;
              this.NewLanguage.Name=null;
              this.NewLanguage.ProficiencyID=null;
              
           
          }   
        }
       
 SaveLanguage(LanguageForm: NgForm){

  this.LanguageSubmited = false;
  if (LanguageForm.valid) 
  {
  if(this.NewLanguage.Name== '') 
        {
   this.LanguageSubmited = true;
        }
  if(this.LanguageSubmited==false)
  {
 this.serviceObj.postArbData('AddUpdateLanguage', this.NewLanguage).subscribe(
      ArbApiResponse => {
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        this.LanguageSubmited = true;
        if(ArbApiResponse["Status"]==1)
        {
          this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
            arbAPiresponse => {
              if (arbAPiresponse["data"]["User"]["Languages"].length > 0) 
              {
          this.NewLanguage = arbAPiresponse["data"]["User"]["Languages"][0];
          this.NewLanguageList = arbAPiresponse["data"]["User"]["Languages"];
            }
            }, err => console.error(err)
            );
         
        }
      },
      ArbApiErr => {
        //this.UserDetails = null;
      this.LanguageSubmited = true;
      }
    );
  }
}
}

DeleteLanguages(x: any) {

  this.NewLanguage=x;
    this.serviceObj.postArbData('DeleteLanguage', this.NewLanguage).subscribe(
        ArbApiResponse => {
     //     document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
       //   document.getElementById('btnMessageModal').click();
          if(ArbApiResponse["Status"]==1)
          { 
            this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
            arbAPiresponse => {
              if (arbAPiresponse["data"]["User"]["Languages"].length > 0) 
              {
          this.NewLanguage = arbAPiresponse["data"]["User"]["Languages"][0];
          this.NewLanguageList = arbAPiresponse["data"]["User"]["Languages"];
            }
            }, err => console.error(err)
            );
           
          }
        },
        ArbApiErr => {
          //this.UserDetails = null;
          this.LanguageSubmited = true;
        }
      );
    }


closeCertificationModel(){

      // $('#EducationModel').removeClass('show ng-star-inserted'); 
      //  $('#EducationModel').addClass('fade'); 
       $('body').removeClass('modal-open');  
        $('#CertificationModel').hide();
   }
  
ShowCertificationsModel(x:any,AddUpdate:number){

      $('#CertificationModel').removeClass('fade'); 
      $('body').addClass('modal-open');  
      $('#CertificationModel').addClass('modal show ng-star-inserted');
      $('#CertificationModel').show();
      this.NewCertification=x;
      this.NewCertification.AddUpdateFlag=AddUpdate;
      if(this.NewCertification.AddUpdateFlag==1){this.NewCertification.ID=x.ID;}
      else {this.NewCertification.ID=0;
        this.NewCertification.Name=null;
        this.NewCertification.Institute=null;

      }
     
    }   
 
SaveCertification(CertificationForm: NgForm){

      this.CertifiSubmited = false;
      if (CertificationForm.valid && (this.NewCertification.FromYear!=1900)&&(this.NewCertification.FromMonth!=0))
      {
        if(this.NewCertification.Name== '' || this.NewCertification.Institute== '') 
        {
   this.CertifiSubmited = true;
        }
  if(this.CertifiSubmited==false)
  {
    this.NewCertification.From = this.NewCertification.FromYear + '-' + this.NewCertification.FromMonth + '-' +1;
    if(this.NewCertification.Pursuing==true)
    this.NewCertification.To ='';
    else
    {
      this.NewCertification.To = this.NewCertification.ToYear + '-' + this.NewCertification.ToMonth+ '-' +20;
    }
    if(this.NewCertification.Pursuing==false &&(this.NewCertification.ToYear!=1900)&&(this.NewCertification.ToMonth!=0))
    {

    }
  
     this.serviceObj.postArbData('AddUpdateCertification', this.NewCertification).subscribe(
          ArbApiResponse => {
            document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
            document.getElementById('btnMessageModal').click();
            this.skillSubmited = true;
            if(ArbApiResponse["Status"]==1)
            {
              this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
                arbAPiresponse => {
                  if (arbAPiresponse["data"]["User"]["Certifications"].length > 0) 
        {
                this.NewCertification = arbAPiresponse["data"]["User"]["Certifications"][0];
                this.NewCertificationList = arbAPiresponse["data"]["User"]["Certifications"];
                
                if( this.NewCertification.From ==null)
                {
                  this.NewCertification.From =new Date();
                }
                else
                {
                  this.NewCertification.From = new Date(this.NewCertification.From);
                }
                if( this.NewCertification.To ==null)
                {
                  this.NewCertification.To = new Date();
                  this.NewCertification.ToYear=1900;
                  this.NewCertification.ToMonth=0;
                  this.NewCertification.Pursuing=true
                }
                else
                {
                  this.NewCertification.To = new Date(this.NewCertification.To);
                  this.NewCertification.ToYear=this.NewCertification.To.getFullYear().toString();
                this.NewCertification.ToMonth=this.NewCertification.To.getMonth()+1;
                this.NewCertification.Pursuing=false;
                }
                this.NewCertification.FromYear=this.NewCertification.From.getFullYear().toString();
                this.NewCertification.FromMonth=this.NewCertification.From.getMonth()+1;
                   }
                   else
        {
              this.NewCertification.FromYear=1900;
              this.NewCertification.FromMonth=0;
              this.NewCertification.ToYear=1900;
              this.NewCertification.ToMonth=0;
         }

                }, err => console.error(err)
                );
            
            
            }
          },
          ArbApiErr => {
            //this.UserDetails = null;
          this.CertifiSubmited = true;
          }
        );
      }
    }
    }
  
DeleteCertifications(x: any) {

      this.NewCertification=x;
        this.serviceObj.postArbData('DeleteCertification', this.NewCertification).subscribe(
            ArbApiResponse => {
         //     document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
           //   document.getElementById('btnMessageModal').click();
              if(ArbApiResponse["Status"]==1)
              { 
                this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
                arbAPiresponse => {
                  if (arbAPiresponse["data"]["User"]["Certifications"].length > 0) 
        {
                this.NewCertification = arbAPiresponse["data"]["User"]["Certifications"][0];
                this.NewCertificationList = arbAPiresponse["data"]["User"]["Certifications"];
                
                if( this.NewCertification.From ==null)
                {
                  this.NewCertification.From =new Date();
                }
                else
                {
                  this.NewCertification.From = new Date(this.NewCertification.From);
                }
                if( this.NewCertification.To ==null)
                {
                  this.NewCertification.To = new Date();
                  this.NewCertification.ToYear=1900;
                  this.NewCertification.ToMonth=0;
                  this.NewCertification.Pursuing=true
                }
                else
                {
                  this.NewCertification.To = new Date(this.NewCertification.To);
                  this.NewCertification.ToYear=this.NewCertification.To.getFullYear().toString();
                this.NewCertification.ToMonth=this.NewCertification.To.getMonth()+1;
                this.NewCertification.Pursuing=false;
                }
                this.NewCertification.FromYear=this.NewCertification.From.getFullYear().toString();
                this.NewCertification.FromMonth=this.NewCertification.From.getMonth()+1;
                   }
                   else
        {
              this.NewCertification.FromYear=1900;
              this.NewCertification.FromMonth=0;
              this.NewCertification.ToYear=1900;
              this.NewCertification.ToMonth=0;
         }
                }, err => console.error(err)
                );
                
              }
            },
            ArbApiErr => {
              //this.UserDetails = null;
              this.CertifiSubmited = true;
            }
          );
        }

closeMemberModel(){

          // $('#EducationModel').removeClass('show ng-star-inserted'); 
          //  $('#EducationModel').addClass('fade'); 
         $('body').removeClass('modal-open');  
            $('#MembershipModel').hide();
       }
      
ShowMembershipsModel(x:any,AddUpdate:number){
 
          $('#MembershipModel').removeClass('fade'); 
          $('body').addClass('modal-open');  
          $('#MembershipModel').addClass('modal show ng-star-inserted');
          $('#MembershipModel').show();
          this.NewMembership=x;
          this.NewMembership.AddUpdateFlag=AddUpdate;
          if(this.NewMembership.AddUpdateFlag==1){this.NewMembership.ID=x.ID;}
          else {this.NewMembership.ID=0;
            this.NewMembership.Name=null;
            this.NewMembership.Role=null;}
         
        }   
   
SaveMemberships(MembershipsForm: NgForm){

  this.MembershipSubmited = false;
  if (MembershipsForm.valid && (this.NewMembership.FromYear!=1900)&&(this.NewMembership.FromMonth!=0))
  {
    if(this.NewMembership.Name== '' || this.NewMembership.Role== '') 
    {
this.MembershipSubmited = true;
    }
if(this.MembershipSubmited==false)
{
this.NewMembership.From = this.NewMembership.FromYear + '-' + this.NewMembership.FromMonth + '-' +1;

 this.serviceObj.postArbData('AddUpdateMembership', this.NewMembership).subscribe(
      ArbApiResponse => {
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        this.MembershipSubmited = true;
        if(ArbApiResponse["Status"]==1)
        {
          this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
            arbAPiresponse => {
              if (arbAPiresponse["data"]["User"]["Memberships"].length > 0) 
              {
                      this.NewMembership = arbAPiresponse["data"]["User"]["Memberships"][0];
                      this.NewMembershipList = arbAPiresponse["data"]["User"]["Memberships"];
                      
                      if( this.NewMembership.From ==null)
                      {
                        this.NewMembership.From =new Date();
                      }
                      else
                      {
                        this.NewMembership.From = new Date(this.NewMembership.From);
                      }
                      this.NewMembership.FromYear=this.NewMembership.From.getFullYear().toString();
                      this.NewMembership.FromMonth=this.NewMembership.From.getMonth()+1;
              }
              else
              {
                    this.NewMembership.FromYear=1900;
                    this.NewMembership.FromMonth=0;
              }

            }, err => console.error(err)
            );
        
        }
      },
      ArbApiErr => {
        //this.UserDetails = null;
      this.MembershipSubmited = true;
      }
    );
  }
  }
}

  
DeleteMemberships(x: any) {

  this.NewMembership=x;
    this.serviceObj.postArbData('DeleteMembership', this.NewMembership).subscribe(
        ArbApiResponse => {
         // document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          //document.getElementById('btnMessageModal').click();
          if(ArbApiResponse["Status"]==1)
          { 
            this.serviceObj.getArbData('UserInfo', this.SearchObj).subscribe(
            arbAPiresponse => {
              if (arbAPiresponse["data"]["User"]["Memberships"].length > 0) 
              {
                      this.NewMembership = arbAPiresponse["data"]["User"]["Memberships"][0];
                      this.NewMembershipList = arbAPiresponse["data"]["User"]["Memberships"];
                      
                      if( this.NewMembership.From ==null)
                      {
                        this.NewMembership.From =new Date();
                      }
                      else
                      {
                        this.NewMembership.From = new Date(this.NewMembership.From);
                      }
                      this.NewMembership.FromYear=this.NewMembership.From.getFullYear().toString();
                      this.NewMembership.FromMonth=this.NewMembership.From.getMonth()+1;
              }
              else
              {
                    this.NewMembership.FromYear=1900;
                    this.NewMembership.FromMonth=0;
              }

            }, err => console.error(err)
            );
            
          }
        },
        ArbApiErr => {
          //this.UserDetails = null;
          this.MembershipSubmited= true;
        }
      );
    }

       


  SavePassword(changePass: NgForm) {
    this.PasswordSubmited = false;
    if(this.NewPass.NewConfirmPassword==this.NewPass.NewPassword)
    {
    if (changePass.valid) {
      this.PasswordSubmited = true;
      this.serviceObj.postArbData('ChangePassword', this.NewPass).subscribe(
        ArbApiResponse => {
          if(ArbApiResponse["Status"]==1)
          {
          this.UserDetails = null;
          this.serviceObj.removeUD();
          document.getElementById('btnHeaderRefreshUD').click();
          this.router.navigate(['/Home', 0]);
        }else
        {
          this.PasswordSubmited = true;
        }
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        },
        ArbApiErr => {
          // this.UserDetails = null;
        }
      );
    }
    }
    else
    {
      if(this.MyAccountPage)
      document.getElementById('divMessage').innerHTML = this.MyAccountPage.PasswordMismatch;
        document.getElementById('btnMessageModal').click();
        this.PasswordSubmited = true;
    }

  }

  addPics(fileInput: any) {
    this.ProfilePicErrormsg='';
  let file:FileList=fileInput.target.files
  this.fileToUpload=file.item(0);
  var reader=new FileReader();
  reader.onload=(event:any)=>{
    document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
    setTimeout(() => {  
      var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
      var hgth = document.getElementById('imgDimensionValidation')['naturalHeight'];
      if (wdth == 0 || hgth == 0 || wdth < 349) {
        this.ProfilePicErrormsg='Minimum width of image should be 350px';
        this.imageUrlProfilePic='';
      }else{
        this.ProfileImages.ProfilePic = fileInput.target.files;
        this.ProfilePicErrormsg='';
        this.imageUrlProfilePic=event.target.result;
      }
    }, 2000);
   
  }
  reader.readAsDataURL(this.fileToUpload);
  }

  addCoverPic(fileInput: any) { 
    this.CoverPicErrormsg=''; 
  
    let file:FileList=fileInput.target.files
    this.fileToUpload1=file.item(0);
    var reader=new FileReader();
    reader.onload=(event:any)=>{
      document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
      setTimeout(() => {  
        var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
        var hgth = document.getElementById('imgDimensionValidation')['naturalHeight'];
        if (wdth == 0 || hgth == 0 || wdth < 1349) {
          this.CoverPicErrormsg='Minimum width of image should be 1350px';
          this.imageUrlCoverPic='';
        }else{
          this.ProfileImages.CoverPic = fileInput.target.files;
          this.CoverPicErrormsg='';
          this.imageUrlCoverPic=event.target.result;
        }
      }, 2000);

    }
    reader.readAsDataURL(this.fileToUpload1);
  
  }

  
  SaveProfileImages(){
        this.serviceObj.postArbData('SaveProfileImages', this.ProfileImages).subscribe(
          ArbApiResponse => {
           
           // setTimeout(this.getDetails(), 100000);
            //------------deb
            this.serviceObj.getArbData('UserDetails', null).subscribe(
              ArbApiResponse => {                 
               // this.RefreshUD();

               // this.UserDetails = ArbApiResponse["data"];              

                this.serviceObj.setUD(ArbApiResponse["data"], false);   
                setTimeout(() => {
                  this.RefreshUD();
                  this.UserDetails.CoverPic=this.imageUrlCoverPic;
                  this.UserDetails.PictureUrl=this.imageUrlProfilePic;
                  document.getElementById('btnHeaderRefreshUD').click();
                }, 1500);
               
                // this.UserDetails_PictureUrl=ArbApiResponse["data"]["PictureUrl"];
                // this.UserDetails_CoverPic=ArbApiResponse["data"]["CoverPic"];
               // document.getElementById('btnRefreshUD').click();
              },
              ArbApiErr => {
              //  console.error("ArbApiErr (Login)-->" + ArbApiErr);
             }
            ); 
             //------------ 
             document.getElementById('divMessage').innerHTML=ArbApiResponse["Message"];
             document.getElementById('btnMessageModal').click();  
             $('#basicInfoTab').removeClass('active');
             $('#controlPanMenu').removeClass('active');
             $('#myInterTab').removeClass('active');
             $('#eduWorkTab').removeClass('active');
             $('#changePassTab').removeClass('active');
             $('#changeTab').removeClass('active');
             $('#myInterTab').addClass('active');

             $('#basicTab').removeClass('active');
             $('#controlPanTab').removeClass('active');
             $('#interTab').removeClass('active');
             $('#eduTab').removeClass('active');
             $('#interTab').addClass('active'); 
            
          },
          ArbApiErr => { 
            //this.UserDetails = null; console.error("ArbApiErr (Login)-->" + ArbApiErr);
           }
        );
      }
}
