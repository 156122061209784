import { Component, OnInit,Pipe, PipeTransform } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer,Meta,Title, MetaDefinition } from '@angular/platform-browser';
import { SEOService } from '../seo.service';
declare var $: any;
declare var UserSetting :any;

@Component({
  selector: 'app-wall',
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.css']
})

export class WallComponent implements OnInit {
  posted: number = 0;
  CommentID: number = 0;
  CPI: number = 1;
  InAppComment: string="";
  InappPostId: number=0;
  isOK: boolean = false;
  poplarSelected: number = 0;
  UserDetails: any = {}
  SearchObj: any = [];
  MyNewPostList: any = [];
  PostList: any = [];
  LikedUsersList:any=[];
  PostComments:any[];
  fileToUpload: File = null;
  ArabesqueWallCategories: any = [];
  UserInterestCategories: any = [];
  MyNewPost: any = {};
  NewComment: any = {};
  showIntr: boolean = true;
  showMile: boolean = true;
  showBusi: boolean = true;
  showMileStyle: string = 'addNew';
  Countries: any = [];
  Cities: any = [];
  MostRecommendedPeople: any = [];
  searchText: string = '';
  rdoAnywhere: boolean = true;
  moreWallPagesAvailable: boolean = false;
  MyCategories: any = [];
  CategoriesList: any = [];
  IsLoading: boolean = true;
  TimeLineContent:any={};
  PostCode:string='';
  IsPostCode: boolean =false;

  constructor(private meta:Meta,private seoService: SEOService,private serviceObj: ArabesqueService,private title: Title, private route: ActivatedRoute, private router: Router, public sanitizer: DomSanitizer) {
    this.MyNewPost.PostText = '';
    this.MyNewPost.CategoryID = 0;
    this.MyNewPost.FileType = '';
    this.MyNewPost.FileTitle = '';
    this.seoService.addMetaTags();
  
    this.NewComment.PostID = 0;
    this.NewComment.CommentText = '';
    this.NewComment.CommentID = 0;

    this.SearchObj.ItemType = 'All';
    this.SearchObj.PageIndex = 1;
    this.SearchObj.CategoryIDs = 0;
    this.SearchObj.SearchText = '';
    this.SearchObj.UserInterestIDs = '';
    this.SearchObj.Distance = 0;
    this.SearchObj.PostCode =0;
    //this.SearchObj.LocationIDs = '';
    this.SearchObj.SortBy = 'Latest';
    this.UserDetails.LocationName = '';
    this.SearchObj.Longitude = '';
    this.SearchObj.Latitude = '';
    this.route.params.subscribe(params => this.PostCode= params.PostCode);
    if(this.PostCode!=undefined)
    this.SearchObj.PostCode =this.PostCode;
    else
    this.SearchObj.PostCode =0;

    this.SearchObj.IsTimeline = false;
    document.getElementById('btnLoadMap').click();
  }

  RefreshUD() 
  {
    this.UserDetails = this.serviceObj.getUD();
    this.TimeLineContent=this.serviceObj.GetPageSpecificContent('TimeLine'); 
    //this.ngOnInit();
  }

  

  ShareWhatsApp(Pst:any)
  {
    let API_BaseURL: any = window.location.href.toLowerCase();
    let BaseUlr:string='';
      if (API_BaseURL.toLowerCase().indexOf('//peepway.com') > 0)
        BaseUlr='https://peepway.com';
      else
        BaseUlr='https://beta.peepway.com';

    let lastUlr:string;
    let  tempTitle:string='';
    if(Pst.PostText.length>20)
    {
      tempTitle=Pst.PostText.substring(0, 20);
    }
    else
    tempTitle=Pst.PostText;
    let newtempTitle:string=tempTitle.replace(/\s/g, "_");

    if(Pst.PostImgURL!='')
    lastUlr='id='+Pst.PostCode+'&title='+newtempTitle+'&image='+Pst.PostImgURL;
    else if(Pst.ExternalVdoURL!='')
    {
      let youtubeUrl:string='';
      if(Pst.ExternalVdoURL.toLowerCase().indexOf('youtube') > -1)
      {
        if(Pst.ExternalVdoURL.toLowerCase().split('embed/')[1]!='')
        youtubeUrl='http://img.youtube.com/vi/'+Pst.ExternalVdoURL.split('embed/')[1]+'/0.jpg';
      }
      lastUlr='id='+Pst.PostCode+'&title='+newtempTitle+'&image='+youtubeUrl;
    }
    else
    lastUlr='id='+Pst.PostCode+'&title='+newtempTitle+'&image='+Pst.Thumbnail;

   // window.location.href='https://api.whatsapp.com/send?text='+encodeURIComponent('https://beta.peepway.com/Default.aspx?'+lastUlr)
   window.open('https://api.whatsapp.com/send?text='+encodeURIComponent(BaseUlr+'/Default.aspx?'+lastUlr+'&onwhatsapp'),'_blank','toolbar=0,status=0,width=626,height=436');return false;
 
  }

  ShareFaceBook(Pst:any)
  {
    let API_BaseURL: any = window.location.href.toLowerCase();
    let BaseUlr:string='';
      if (API_BaseURL.toLowerCase().indexOf('//peepway.com') > 0)
        BaseUlr='https://peepway.com';
      else
        BaseUlr='https://beta.peepway.com';

    let lastUlr:string='';
    let  tempTitle:string='';
    if(Pst.PostText.length>20)
    {
      tempTitle=Pst.PostText.substring(0, 20);
    }
    else
    tempTitle=Pst.PostText;

    if(Pst.PostImgURL!='')
    lastUlr='id='+Pst.PostCode+'&title='+tempTitle+'&image='+Pst.PostImgURL;
    else if(Pst.PostVdoURL!='')
    lastUlr='id='+Pst.PostCode+'&title='+tempTitle+'&image='+Pst.Thumbnail;
    else if(Pst.ExternalVdoURL!='')
    {
      let youtubeUrl:string='';
      if(Pst.ExternalVdoURL.toLowerCase().indexOf('youtube') > -1)
      {
        if(Pst.ExternalVdoURL.toLowerCase().split('embed/')[1]!='')
        youtubeUrl='http://img.youtube.com/vi/'+Pst.ExternalVdoURL.split('embed/')[1]+'/0.jpg';
      }
      lastUlr='id='+Pst.PostCode+'&title='+tempTitle+'&image='+youtubeUrl;
    }
    else
    lastUlr='id='+Pst.PostCode+'&title='+tempTitle+'&image=';

        window.open('http://www.facebook.com/sharer.php?u='+
        encodeURIComponent(BaseUlr+'/Default.aspx?'+lastUlr)+
    '&url='+encodeURIComponent(BaseUlr+'/Wall/'+Pst.PostCode)+
    '&t='+Pst.PostText ,'_blank','toolbar=0,status=0,width=626,height=436');return false;
      
  }

  Sharelinkedin(Pst:any)
  {
    debugger;
    let API_BaseURL: any = window.location.href.toLowerCase();
    let BaseUlr:string='';
      if (API_BaseURL.toLowerCase().indexOf('//peepway.com') > 0)
        BaseUlr='https://peepway.com';
      else
        BaseUlr='https://beta.peepway.com';

    let lastUlr:string='';
    let  tempTitle:string='';
    if(Pst.PostText.length>20)
    {
      tempTitle=Pst.PostText.substring(0, 20);
    }
    else
    tempTitle=Pst.PostText;
   if(Pst.PostImgURL!='')
      lastUlr='id='+Pst.PostCode+'&title='+tempTitle+'&image='+Pst.PostImgURL;
    else if(Pst.PostVdoURL!='')
    lastUlr='id='+Pst.PostCode+'&title='+tempTitle+'&image='+Pst.Thumbnail;
     else if(Pst.ExternalVdoURL!='')
    {
      let youtubeUrl:string='';
      if(Pst.ExternalVdoURL.toLowerCase().indexOf('youtube') > -1)
      {
        if(Pst.ExternalVdoURL.toLowerCase().split('embed/')[1]!='')
        youtubeUrl='http://img.youtube.com/vi/'+Pst.ExternalVdoURL.split('embed/')[1]+'/0.jpg';
      }
     lastUlr='id='+Pst.PostCode+'&title='+tempTitle+'&image='+youtubeUrl;
    } 
    else
     lastUlr='id='+Pst.PostCode+'&title='+tempTitle+'&image=';

    window.open('http://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent(BaseUlr+'/Default.aspx?'+lastUlr+'&summary='+tempTitle+'&source='+BaseUlr+'/Wall/'+Pst.PostCode)
    ,'_blank','toolbar=0,status=0,width=626,height=436');return false;
   
  }

  ShowPostImage(pst:any,img:any)
  {
    pst.PostImgURL=img.Image;
  }

  ngOnInit() {
    
    this.IsLoading = true;
    this.MyCategories = [];
    this.CategoriesList = [];
    this.UserDetails = this.serviceObj.getUD();
    this.TimeLineContent=this.serviceObj.GetPageSpecificContent('TimeLine'); 

    if (this.UserDetails != null && (this.UserDetails.LocationName == '' || this.UserDetails.LocationName == null)) {
      this.UserDetails.LocationName = '';
    }
    if (this.UserDetails != null && this.UserDetails.WallPostRadius != null) {
      this.SearchObj.Distance = this.UserDetails.WallPostRadius;

      if (sessionStorage.getItem('LD') != null) 
      {
     if(this.TimeLineContent)
     {
          // if(UserSetting.Language=='EN')
          // document.getElementById('lblPostTopLocation').innerHTML = 'You are seeing the posts within ' + this.SearchObj.Distance + ' kms from ' + JSON.parse(sessionStorage.getItem('LD'))['LocationName'];
          // else
          // document.getElementById('lblPostTopLocation').innerHTML =JSON.parse(sessionStorage.getItem('LD'))['LocationName']+ ' من الكيلومترات '+ this.SearchObj.Distance+ ' أنت تشاهد المشاركات داخل حدود ' ;
      }
      }
    }
   

    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

    this.serviceObj.getArbData('ArabesqueWallCategories', '').subscribe(
      ArbApiResponse => {
       
        this.MyCategories = [];
        this.ArabesqueWallCategories = [];
        this.ArabesqueWallCategories = ArbApiResponse["data"];
        let i: number = 0;
        if(this.ArabesqueWallCategories!=null && this.ArabesqueWallCategories.length>0){
        for (let C of this.ArabesqueWallCategories) {
          if (this.UserDetails != null) {
            if (C.Ischecked) {
              this.MyCategories.push({ 'CategoryID': C.CategoryID, 'Category': C.Category, 'Ischecked': C.Ischecked, 'PostCount': C.PostCount });
              this.ArabesqueWallCategories = this.ArabesqueWallCategories.filter(i => i.CategoryID !== C.CategoryID);
            }
          }
          else {
            if (i < 10) {
              this.MyCategories.push({ 'CategoryID': C.CategoryID, 'Category': C.Category, 'Ischecked': C.Ischecked, 'PostCount': C.PostCount });
              this.ArabesqueWallCategories = this.ArabesqueWallCategories.filter(i => i.CategoryID !== C.CategoryID);
            }
          }
          i++;
        }
      }

        if (this.UserDetails != null) {
          if (sessionStorage.getItem('WallCats') != null) {
            this.MyCategories = JSON.parse(sessionStorage.getItem('WallCats'));
              for (let LangC of this.MyCategories) 
              {
                LangC.Category= ArbApiResponse["data"].filter(i => i.CategoryID === LangC.CategoryID)[0].Category;//this.GetCategoryByID(LangC.CategoryID);
                this.ArabesqueWallCategories = this.ArabesqueWallCategories.filter(i => i.CategoryID !== LangC.CategoryID);
              }
          }

          if (this.MyCategories.length == 0) {
            document.getElementById('btnAddMyInterest').click();
          }
        }
        this.DefaultSearchPost();
      });


    this.serviceObj.getArbData('MostRecommendedPeople', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.MostRecommendedPeople = ArbApiResponse["data"];
      },
      err => console.error(err)
    );

  }

  GetCategoryByID(catID)
  {
   return this.ArabesqueWallCategories.filter(i => i.CategoryID === catID)[0].Category;
  }

  SelectUnselect(Cat: any) {
    Cat.Ischecked = true;
    this.ArabesqueWallCategories = this.ArabesqueWallCategories.filter(i => i.CategoryID !== Cat.CategoryID);
    this.MyCategories.push({ 'CategoryID': Cat.CategoryID, 'Category': Cat.Category, 'Ischecked': Cat.Ischecked, 'PostCount': Cat.PostCount });
    sessionStorage.setItem('WallCats', JSON.stringify(this.MyCategories));
  }

  SelectUnselectRemove(Cat: any) {
    Cat.Ischecked = false;
    this.MyCategories = this.MyCategories.filter(i => i.CategoryID !== Cat.CategoryID);
    this.ArabesqueWallCategories.push({ 'CategoryID': Cat.CategoryID, 'Category': Cat.Category, 'Ischecked': Cat.Ischecked, 'PostCount': Cat.PostCount });
    sessionStorage.setItem('WallCats', JSON.stringify(this.MyCategories));
  }

  RemoveCategoryFilter(Category: any) {
    this.MyCategories = this.MyCategories.filter(i => i.CategoryID !== Category.CategoryID);
  }

  MyAccount() {
    if (this.UserDetails != null) {
      document.getElementById('btnAddMyInterest').click();
      this.router.navigate(['/MyAccount/1'])
    } else {
      document.getElementById('plusLoginBtn').click();
    }
  }
  DefaultSearchPost()
  {
    if(this.PostCode!=undefined)
    {
    this.SearchObj.PostCode =this.PostCode;
    this.IsPostCode=true;
    }
    else
    {
    this.SearchObj.PostCode=0;
    this.IsPostCode=false;
    }
    this.SearchPosts(false);
  }

  SearchPosts(IsLazyLoading: boolean) 
  {
    if(this.IsPostCode==true)
    {
      this.SearchObj.PostCode =this.PostCode;
      this.IsPostCode=false;
    }else
    {
      this.SearchObj.PostCode =0;
      this.IsPostCode=false;
    }
    this.IsLoading = true;
    if (IsLazyLoading == null || IsLazyLoading == false) {
      this.SearchObj.PageIndex = 1;
      this.PostList = [];
    }

    if (document.getElementById('txtAddressCoords') != null && document.getElementById('txtAddressCoords')["value"] != '' && this.SearchObj.Distance > 0) {
      this.SearchObj.Latitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[0];
      this.SearchObj.Longitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[1];

      //document.getElementById('lblPostTopLocation').innerHTML = 'You are seeing the posts within ' + this.SearchObj.Distance + ' kms from ' + document.getElementById('lblCurrentLocation').innerHTML;
      if(this.TimeLineContent)
      {
          //  if(UserSetting.Language=='EN')
          //  document.getElementById('lblPostTopLocation').innerHTML = 'You are seeing the posts within ' + this.SearchObj.Distance + ' kms from ' ;//+ document.getElementById('lblCurrentLocation').innerHTML;
          //  else
          //  document.getElementById('lblPostTopLocation').innerHTML =document.getElementById('lblCurrentLocation').innerHTML + ' من الكيلومترات '+ this.SearchObj.Distance+ ' أنت تشاهد المشاركات داخل حدود ' ;
       }
    } 
    else {      
      this.SearchObj.Latitude = '';
      this.SearchObj.Longitude = '';

      var LD= JSON.parse(sessionStorage.getItem('LD'));
      if(LD!=null && this.UserDetails==null)
        {	
          this.SearchObj.Latitude = LD['Latitude'];
          this.SearchObj.Longitude = LD['Longitude'];
        }

      //  document.getElementById('lblPostTopLocation').innerHTML =this.TimeLineContent.YouareSeeing;
    }

    var allCats = '';
    for (let x of this.MyCategories) {
      if (allCats == '')
        allCats = x.CategoryID;
      else
        allCats += "," + x.CategoryID;
    }
    this.SearchObj.CategoryIDs = allCats;
 

    this.serviceObj.getArbData('GetWallPosts', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.IsLoading = false;
        if (ArbApiResponse["data"].length > 0) {
          if (this.SearchObj.PageIndex == 1) {
            this.PostList = ArbApiResponse["data"];
          }
          else {
            for (var p = 0; p < ArbApiResponse["data"].length; p++)
              this.PostList.push(ArbApiResponse["data"][p]);
          }

          if (ArbApiResponse["data"].length < 5)
              this.moreWallPagesAvailable = false;
            else
              this.moreWallPagesAvailable = true;
        }
        else {
          this.moreWallPagesAvailable = false;
        }
      },
      err => {
        this.moreWallPagesAvailable = false;
      });
  }


  GetNextWallPage() 
  {
    if (this.moreWallPagesAvailable) {
      this.SearchObj.PageIndex += 1;
      this.SearchPosts(true);
    }
  }


  onSortBySelect(sortBy: any) {
    this.SearchObj.SortBy = sortBy;//.split(' ')[1];
    this.SearchPosts(null);
  }
  toggleIntr() {
    this.showIntr = !this.showIntr;
  }
  toggleMile() {
    this.showMile = !this.showMile;
    this.rdoAnywhere = false;
  }
  toggleBusi() {
    this.showBusi = !this.showBusi;
    this.rdoAnywhere = false;
  }

  Anywhere() {
    this.showMile = true;
    this.showBusi = true;
    this.rdoAnywhere = true;
    this.SearchObj.Distance = 0;
    //this.SearchObj.LocationIDs = '';
  }

  ReportInappropriate(postId : number) 
  {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      if(this.UserDetails.CoinBalance<10) 
        {
          if(this.TimeLineContent)
          { 
            document.getElementById('divMessage').innerHTML = this.TimeLineContent.Insufficientcoin;
          document.getElementById('btnMessageModal').click();
        }
    }
      else
      {
       this.InappPostId=postId;
        document.getElementById('btnReportInappropriate').click();
      }
     }
    }
      
       SetInappropriateFlag()
      {
       let Apost: any={};
       Apost.PostID = this.InappPostId;
       Apost.UserComment=this.InAppComment;
           this.serviceObj.postArbData('ReportWallAbuse',Apost).subscribe(
          ArbApiResponse => {
           if (ArbApiResponse["Status"] == 1) 
           {
             this.SearchPosts(false);
              document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
           }
           document.getElementById('btnReportInappropriate').click();
           document.getElementById('btnMessageModal').click();
           this.InAppComment="";
         });
      }
 

  AddPostComment(pst: any) 
  {
    if (document.getElementById('Comment_' + pst.PostID)["value"] != "") {
      this.NewComment.PostID = pst.PostID;
      this.NewComment.CommentID = 0;
      this.NewComment.CommentText = document.getElementById('Comment_' + pst.PostID)["value"];
      this.serviceObj.postArbData('AddWallComment', this.NewComment).subscribe(
        ArbApiResponse => {
          const d: Date = new Date();
          pst.Comments.push({
            "CommentID": ArbApiResponse["data"], "Comment": this.NewComment.CommentText, "CommentDateTime": d.toString(),
            "Replies": [],
            "CommentedBy": [{ "FirstName": this.UserDetails.FirstName, "PictureUrl": this.UserDetails.PictureUrl }]
          });
          document.getElementById('Comment_' + pst.PostID)["value"] = "";
          this.posted = pst.PostID;
        }
      );
    }
  }

  AddPostComment_ON(Comment1: any, PostID: number) 
  {
    let NewComment: any = {};
    if (document.getElementById('Comment_ON_' + Comment1.CommentID)["value"] != "") {
      NewComment.CommentID = Comment1.CommentID;
      NewComment.PostID = PostID
      NewComment.CommentText = document.getElementById('Comment_ON_' + Comment1.CommentID)["value"];
      this.serviceObj.postArbData('AddWallComment', NewComment).subscribe(
        ArbApiResponse => {
          const d: Date = new Date();
          Comment1.Replies.push({
            "CommentID": 0, "Comment": NewComment.CommentText, "CommentDateTime": d.toString(),
            "CommentedBy": [{ "FirstName": this.UserDetails.FirstName, "PictureUrl": this.UserDetails.PictureUrl }]
          });
          document.getElementById('Comment_ON_' + Comment1.CommentID)["value"] = "";
        }
      );
    }

  }

  AddPostLike(pst: any) 
  {
    if (this.UserDetails) {
      if (!pst.HasLiked && !pst.HasDisLiked) {
        pst.HasLiked = true;
        this.NewComment.PostID = pst.PostID;
        this.serviceObj.postArbData('AddLike', this.NewComment).subscribe(
          ArbApiResponse => {
            this.NewComment.PostID = 0;
            this.NewComment.CommentText = '';
            pst.Likes = pst.Likes + 1;
          }
        );
      }

    }
    else {
      document.getElementById('plusLoginBtn').click();
    }
  }

  AddPostDisLike(pst: any) {
    if (this.UserDetails) {
      if (!pst.HasLiked && !pst.HasDisLiked) {
        pst.HasDisLiked = true;
        this.NewComment.PostID = pst.PostID;
        this.serviceObj.postArbData('AddDisLike', this.NewComment).subscribe(
          ArbApiResponse => {
            this.NewComment.PostID = 0;
            this.NewComment.CommentText = '';
            pst.DisLikes = pst.DisLikes + 1;
          }
        );
      }
    }
    else {
      document.getElementById('plusLoginBtn').click();
    }
  }

  PostCommentToggle(pst: any) {
if (this.posted != pst.PostID) {
      this.posted = pst.PostID;
    } else {
      this.posted = 0;
    }
    this.CPI=1;
  }

  MorePostComment(pst:any,CPI:number)
  {
    this.CPI=CPI+1;
     let ObjCmm: any={};
      ObjCmm.PostID = pst.PostID;
      ObjCmm.pi =this.CPI;
      if(pst.CommentsCount>5)
          {
          if (this.UserDetails!=null) 
          {
          this.serviceObj.getArbData('GetWallPostComment', ObjCmm).subscribe(
              ArbApiResponse => {
                for (var p = 0; p < ArbApiResponse["data"].length; p++){
                  pst.Comments.push(ArbApiResponse["data"][p]);
                }
              },
              err => console.error(err)
            );
          }
        else
        {
          document.getElementById('plusLoginBtn').click();
        }
      }
  }
 



  ClickInvite() 
  {
    if (this.UserDetails!=null && this.UserDetails.ReferralCode != null)
      $('#shareWithFriends').show();
    else {
      if(this.TimeLineContent)
      document.getElementById('divMessage').innerHTML = this.TimeLineContent.Thereisnoreferral;//'There is no referral code assigned to you, please contact support to assign a referral code before inviting your contacts.';
      document.getElementById('btnMessageModal').click();
    }
  }

  FirstCommentToggle(Comment: any) 
  {
    if (this.CommentID != Comment.CommentID) {
      this.CommentID = Comment.CommentID
    } else {
      this.CommentID = 0;
    }

  }

  MyTimeLine() 
  {
    if (this.UserDetails) {
      this.router.navigate(['/MyTimeLine'])
    }
    else {
      document.getElementById('plusLoginBtn').click();
    }
  }

   
  LikedUsers(pst:any,Type:string)
  {
    // if (this.UserDetails!=null) 
    //   {
        if(Type=='L' && pst.Likes==0)
        {
          if(this.TimeLineContent)
          document.getElementById('divMessage').innerHTML =this.TimeLineContent.Nolikefound
          document.getElementById('btnMessageModal').click();
        }
        else if(Type=='D' && pst.DisLikes==0)
        {
          if(this.TimeLineContent)
          document.getElementById('divMessage').innerHTML = this.TimeLineContent.Nodislikefound;
          document.getElementById('btnMessageModal').click();
        }else
        {
          let LikedObj:any={};
          LikedObj.PostID=pst.PostID
          LikedObj.IsLiked=(Type=='L')?true:false;
          this.serviceObj.getArbData('WallPostLikedUsers', LikedObj).subscribe(
            ArbApiResponse => {
              if(ArbApiResponse["Status"]==1)
              {
                  this.LikedUsersList = ArbApiResponse["data"];
                 /*  if(Type=='L')
                  $('#likeModelID').modal('toggle');
                  else
                  $('#DislikesModelID').modal('toggle');
                  if(Type=='D')
                  $('#DislikesModelID').modal('toggle');
                  else
                  $('#likeModelID').modal('toggle'); */
              }
            },
            err => console.error(err)
          );
       }
    //   }
    // else
    // {
    //   document.getElementById('plusLoginBtn').click();
    // }
  }

 
}
