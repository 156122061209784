
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'EncodeText'
})

export class EncodeText implements PipeTransform {

    transform(Text) {
        return encodeURI(Text.trim());
      }
  
}