import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import {  Location, PopStateEvent } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})

export class PeopleComponent implements OnInit {
  PeopleItems: any = [];
  PeoplePage: any = {};
  UserInterestCategories: any = [];
  MostRecommendedPeople: any = [];
  SearchObj: any = {};
  UserDetails: any = {}
  showIntr: boolean = true;
  showMile: boolean = true;
  showBusi: boolean = true;
  rdoAnywhere: boolean = true;
  NewpersonToConnect: any = [];
  Countries: any = [];
  Cities: any = [];
  searchText: string = '';
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  IsLoading:boolean=true;
  morePagesAvailable: boolean = false;

  constructor(private serviceObj: ArabesqueService, private router: Router, private location: Location) 
  {
    this.SearchObj.PageIndex = 1;
    this.SearchObj.SearchText = '';
    this.SearchObj.IsTimeline = false;
    this.SearchObj.UserInterestIDs = 0;
    this.SearchObj.Distance =0;
    this.SearchObj.LocationIDs = '';
    this.SearchObj.Latitude = '';
    this.SearchObj.Longitude = '';

    this.NewpersonToConnect.ID = 0;
  }

  RefreshUD() { 
    this.UserDetails = this.serviceObj.getUD();    
    this.PeoplePage = this.serviceObj.GetPageSpecificContent('People'); 
    this.SearchPeopleItems(false);
  }

  ngOnInit() 
  {    
    this.IsLoading=true;
    this.UserDetails = this.serviceObj.getUD();    
    this.PeoplePage = this.serviceObj.GetPageSpecificContent('People'); 
    
    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

    /*set on top start*/
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });

    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else
          window.scrollTo(0, 0);
      }
    });
    /*set on top start*/
    
    if (sessionStorage.getItem('SearchCriteria') != null) 
    {
        this.SearchObj = JSON.parse(sessionStorage.getItem('SearchCriteria'));
        // this.PeopleItems = JSON.parse(sessionStorage.getItem('PeopleItems'));
        // this.IsLoading=false;
        this.SearchPeopleItems(true);
        sessionStorage.removeItem('BackToPeople');
    }else
    {
      this.SearchPeopleItems(false);
    }

    this.serviceObj.getArbData('GetUserInterestCategories', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.UserInterestCategories = ArbApiResponse["data"];
      },
      err => console.error(err)
    );

    this.serviceObj.getArbData('MostRecommendedPeople', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.MostRecommendedPeople = ArbApiResponse["data"];
      },
      err => console.error(err)
    );

    this.serviceObj.getArbData('CountryAndCity',null).subscribe(
      ArbApiResponse => {
        this.Countries = ArbApiResponse["data"]["Countries"];
        this.Cities = ArbApiResponse["data"]["Cities"];
      },
      err => console.error(err)
    );
    document.getElementById('btnLoadMap').click();
  }

  SearchPeopleItems(IsLazyLoading: boolean) {
    this.IsLoading=true;
    if (IsLazyLoading == null || IsLazyLoading == false) {
      this.SearchObj.PageIndex = 1;
      this.PeopleItems = [];
    }
   
     
            if (this.SearchObj.Distance > 0) {
              this.SearchObj.Latitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[0];
              this.SearchObj.Longitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[1];
            }
            else 
            {
              this.SearchObj.Latitude = '';
              this.SearchObj.Longitude = '';
            }
            this.serviceObj.getArbData('SearchUsers', this.SearchObj).subscribe
            (
                ArbApiResponse => {
                this.IsLoading = false;
                if (ArbApiResponse["data"].length > 0) 
                {
                  if (this.SearchObj.PageIndex == 1) {
                    this.PeopleItems = ArbApiResponse["data"];
                  }
                  else {
                    for (var p = 0; p < ArbApiResponse["data"].length; p++)
                      this.PeopleItems.push(ArbApiResponse["data"][p]);
                  }
                  if (ArbApiResponse["data"].length < 10)
                  this.morePagesAvailable = false;
                else
                  this.morePagesAvailable = true;
                }
                else {
                  this.morePagesAvailable = false;
                }
                sessionStorage.setItem('SearchCriteria', JSON.stringify(this.SearchObj));
                sessionStorage.setItem('PeopleItems', JSON.stringify(this.PeopleItems));
              }
              ,
              err => {
                this.IsLoading = false;
                this.morePagesAvailable = false;
                console.error(err);
              }
            );

  
  }

  FilterPeople() {
    this.SearchPeopleItems(false);
 
  }

  toggleIntr() {
    this.showIntr = !this.showIntr;
  }
  toggleMile() {
    this.showMile = !this.showMile;
    this.rdoAnywhere = false;
  }
  toggleBusi() {
    this.showBusi = !this.showBusi;
    this.rdoAnywhere = false;
  }
  Anywhere() {
    this.showMile = true;
    this.showBusi = true;
    this.rdoAnywhere = true;
    this.SearchObj.Distance = 0;
    this.SearchObj.LocationIDs = '';
  }

  GetNextPage() {
    if (this.morePagesAvailable) {
      this.SearchObj.PageIndex += 1;
      this.SearchPeopleItems(true);
    }
  }
  ConnectionRequest(personToConnect: any) {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      // if (!personToConnect.ConnectionStatus) {
      this.NewpersonToConnect.ID = personToConnect.ID;
      this.serviceObj.postArbData('SendConnectionRequest', this.NewpersonToConnect).subscribe(
        ArbApiResponse => {
          personToConnect.ConnectionStatus = 'Pending';
        },
        ArbApiErr => {
        }
      );
      // }
    }
  }



  //End Class
}
