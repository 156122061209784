import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
@Component({
  selector: 'app-image-guide-line',
  templateUrl: './image-guide-line.component.html',
  styleUrls: ['./image-guide-line.component.css']
})
export class ImageGuideLineComponent implements OnInit {
  StaticPage: any = {};
  constructor(private serviceObj: ArabesqueService) { }

  ngOnInit() { this.StaticPage = this.serviceObj.GetPageSpecificContent('StaticPage');
  }

}
