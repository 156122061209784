import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd,NavigationStart  } from '@angular/router';
import { Location, PopStateEvent } from "@angular/common";
import { ArabesqueService } from './../arabesque-service.service';
import { FormGroup, FormBuilder, Validators, FormControl ,ReactiveFormsModule} from '@angular/forms';
import { ImageCroppedEvent } from '../../../node_modules/ngx-image-cropper';

declare var $:any;

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  UserDetails: any = {};
  SupportPage: any = {};
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  FAQList:any=[];
  searchText:string='';
  HeadText:string='';
  RequestForm:any={};
  isValidUserName:boolean=true;
  isValidCaptcha:boolean=false;
  isValidEmail:boolean=true;
  isValidUserContent:boolean=true;
  filesize:number=100;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToUpload: File = null;
  ServerError:string='';
  myRecaptcha = new FormControl(false);

  constructor(private serviceObj: ArabesqueService, private router: Router, private location: Location) { 
    this.RequestForm.UserName='';
    this.RequestForm.UserEmail='';
    this.RequestForm.UserContent='';
    this.RequestForm.UserFile='';
  }
 
 
    onScriptLoad() {
          console.log('Google reCAPTCHA loaded and is ready for use!')
    }
 
    onScriptError() {
        console.log('Something went long when loading the Google reCAPTCHA')
    }
  RefreshUD() {

     this.UserDetails = this.serviceObj.getUD();
     this.SupportPage = this.serviceObj.GetPageSpecificContent('Support'); 
  }

  ngOnInit() 
  {
    this.searchText='';
    this.SupportPage = this.serviceObj.GetPageSpecificContent('Support'); 
    this.UserDetails = this.serviceObj.getUD();
    
    if (window.location.href.indexOf('/Home') > 0)
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

    this.serviceObj.getArbData('CommonFAQ', null).subscribe(
      ArbApiResponse => {
        this.FAQList = ArbApiResponse["data"];
      }
    );
  }

  Request(RequestType:any)
  {
      this.RequestForm.UserName='';
    this.RequestForm.UserEmail='';
    this.RequestForm.UserContent='';
    this.RequestForm.UserFile='';
    this.RequestForm.RequestType='';
    this.croppedImage='';
    this.imageChangedEvent='';
    this.isValidUserName = true;
    this.isValidEmail = true;
    this.isValidUserContent = true;
    this.ServerError='';

      if(this.SupportPage)
      { 
        if(RequestType=='F')
        {
          this.RequestForm.RequestType='Feature';
          this.HeadText=this.SupportPage.FeatureRequest;
        }
       
        else if(RequestType=='S')
          {
            this.RequestForm.RequestType='Support';
            this.HeadText=this.SupportPage.SupportRequest;
          }
        else
        {
          this.RequestForm.RequestType='Bug';
          this.HeadText=this.SupportPage.ReportBug;
        }
      }
      $('#FeatureRequestID').modal('toggle');
  }

  SupportRequest(MemberID:any)
  {
          if(this.UserDetails==null)
            {
              if (this.RequestForm.UserName == '') 
                this.isValidUserName = false;
               else 
                this.isValidUserName = true;
              
              if (this.RequestForm.UserEmail == '') 
                this.isValidEmail = false;
               else 
                this.isValidEmail = true;
              
              if (this.RequestForm.UserContent == '') 
                this.isValidUserContent = false;
               else 
                this.isValidUserContent = true;
                
                if (this.myRecaptcha.status == 'VALID') 
                this.isValidCaptcha = false;
               else 
                this.isValidCaptcha = true;

              if(this.RequestForm.UserName != '' && this.RequestForm.UserEmail != '' && this.RequestForm.UserContent != '' && this.myRecaptcha.status == 'VALID')
              {
                let Request:any={};
                Request.ID=0;
                Request.UserName=this.RequestForm.UserName
                Request.UserEmail=this.RequestForm.UserEmail;
                Request.UserContent=this.RequestForm.UserContent;
                Request.Type=this.RequestForm.RequestType
                Request.CoverPic='';
                if(this.croppedImage!='')
                {
                  let blob=this.dataURItoBlob(this.croppedImage);
                  let typeimg=this.base64MimeType(this.croppedImage);
                  var t=typeimg.split('/')[1];
                  let names:string='filename.'+t;
                  var file = new File([blob], names, {type: typeimg});
                  Request.CoverPic=file;
                }
              this.serviceObj.postArbData('SupportRequest', Request).subscribe(
                ArbApiResponse => 
                {
                  if(ArbApiResponse["Status"]==1)
                  {
                    $('#FeatureRequestID').modal('toggle');
                    document.getElementById('divMessage').innerHTML =ArbApiResponse["Message"];
                    document.getElementById('btnMessageModal').click();
                  }else
                    this.ServerError=ArbApiResponse["Message"];
                },
                ArbApiErr => {
                }
              );
            }
        }
   else
      {
        if (this.RequestForm.UserContent == '') 
        {
          this.isValidUserContent = false;
        } 
        else 
        this.isValidUserContent = true;
         
        if (this.myRecaptcha.status == 'VALID') 
        this.isValidCaptcha = false;
       else 
        this.isValidCaptcha = true;
        if (this.RequestForm.UserContent != '' && this.myRecaptcha.status == 'VALID') 
        {
         
          let Request:any={};
          Request.ID=0;
          Request.UserName='';
          Request.UserEmail='';
          Request.UserContent=this.RequestForm.UserContent;
          Request.Type=this.RequestForm.RequestType
          Request.CoverPic='';
                if(this.croppedImage!='')
                {
                  let blob=this.dataURItoBlob(this.croppedImage);
                  let typeimg=this.base64MimeType(this.croppedImage);
                  var t=typeimg.split('/')[1];
                  let names:string='filename.'+t;
                  var file = new File([blob], names, {type: typeimg});
                  Request.CoverPic=file;
                }
          this.isValidUserContent = true;
          if(this.RequestForm.UserContent != '')
          {
          this.serviceObj.postArbData('SupportRequest', Request).subscribe(
            ArbApiResponse => 
            {
              if(ArbApiResponse["Status"]==1)
              {
                $('#FeatureRequestID').modal('toggle');
                document.getElementById('divMessage').innerHTML =ArbApiResponse["Message"];
                document.getElementById('btnMessageModal').click();
              }else
              this.ServerError=ArbApiResponse["Message"];
            },
            ArbApiErr => {
            }
          );
        }
      }
         
      }
  }
  base64MimeType(encoded) {
    var result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
}
  dataURItoBlob(dataURI) {

    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {    type: 'image/jpg'});
}

  fileChangeEvent(ev: any): void {
    let file:FileList=ev.target.files
         this.fileToUpload=file.item(0);
          var reader=new FileReader();
          reader.onload=(event:any)=>{
            document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
            setTimeout(() => {  
              var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
              if(wdth>380)
              this.filesize=380
              else
              this.filesize=wdth
              this.imageChangedEvent = ev;
            }, 2000);
             
          }
          reader.readAsDataURL(this.fileToUpload);
    }
    imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
    }
    imageLoaded() {
        // show cropper
    }
    loadImageFailed() {
        // show message
    }
}
