import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd,NavigationStart  } from '@angular/router';
import { Location, PopStateEvent } from "@angular/common";
import { ArabesqueService } from './../arabesque-service.service';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  UserDetails: any = {};
  StaticPage: any = {};
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  constructor(private serviceObj: ArabesqueService, private router: Router, private location: Location) { }
  RefreshUD() { this.UserDetails = this.serviceObj.getUD(); 
    this.StaticPage = this.serviceObj.GetPageSpecificContent('StaticPage');}
  ngOnInit() {
    this.StaticPage = this.serviceObj.GetPageSpecificContent('StaticPage');

    if (window.location.href.indexOf('/Home') > 0)
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
  else
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader';
    
    /*start scroll top*/
    this.location.subscribe((ev:PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
  });
  this.router.events.subscribe((ev:any) => {
      if (ev instanceof NavigationStart) {
          if (ev.url != this.lastPoppedUrl)
              this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
          if (ev.url == this.lastPoppedUrl) {
              this.lastPoppedUrl = undefined;
              window.scrollTo(0, this.yScrollStack.pop());
          } else
              window.scrollTo(0, 0);
      }
  });
/*End scroll top*/
  }

}
