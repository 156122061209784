import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { Observable } from 'rxjs';
import { ArabesqueService } from './../arabesque-service.service';
import { Router } from '@angular/router';
import { FilterPipe } from '../filter.pipe';
declare var $: any;

@Component({
  selector: 'app-my-group',
  templateUrl: './my-group.component.html',
  styleUrls: ['./my-group.component.css']
})
export class MyGroupComponent implements OnInit {
  UserDetails: any = {}
  GroupItems: any = [];
  SearchObj: any = {};
  Incoming: any = [];
  Outgoing: any = [];
  Notifications: any = [];
  RequestrejectedByYous: any = [];
  AcceptRejectRequest: any = {};
  IneterestedPeople: any = {};
  IneterestedPeopleList: any = [];
  GroupRecommendations: any = [];
  DelStatus:any={};
  AllRecList:any=[];
  RecommededBy:any='';
  GropupContent:any={};

  constructor(private serviceObj: ArabesqueService, private router: Router) {
    this.SearchObj.PageIndex = 1;
    this.SearchObj.ItemType = 'All';
    this.SearchObj.SearchText = '';
    this.SearchObj.IsTimeline = true;
    this.SearchObj.CategoryIDs = 0;

    this.IneterestedPeople.GroupID = 0;
  }

  RefreshUD() { this.UserDetails = this.serviceObj.getUD();
    if(this.UserDetails==null)
    this.router.navigate(['/Home', 0]);
    this.GropupContent=this.serviceObj.GetPageSpecificContent('Group'); 
  }

  ngOnInit() {
    this.RefreshUD();
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
       $("#MessageModal1").modal({ backdrop: 'static', keyboard: false});
      }
    }
    let tab = sessionStorage.getItem('TAB');
    if (tab != null) {
      if (tab == 'G') {
        $('#propMyItem').removeClass('active');
        $('#propItem').removeClass('active');

        $('#propReq').removeClass('active');
        $('#propRequest').removeClass('active');

        $('#propRecom').removeClass('active');
        $('#propRecomend').removeClass('active');

        $('#propReq').addClass('active');
        $('#propRequest').addClass('active');
        sessionStorage.removeItem('TAB');
      }
    }

    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';


    this.serviceObj.getArbData('GroupItems', this.SearchObj).subscribe(
      data => {
        this.GroupItems = data["data"];
      },
      err => console.error(err)
    );

    this.serviceObj.getArbData('GetGroupRequests', null).subscribe(
      ArbApiResponse => {
        this.Notifications = ArbApiResponse["data"]["Notifications"];
        this.Outgoing = ArbApiResponse["data"]["Outgoing"];
        this.Incoming = ArbApiResponse["data"]["Incoming"];
        this.RequestrejectedByYous = ArbApiResponse["data"]["RequestrejectedByYous"];
      },
      err => console.error(err)
    );
    //recommendation
    this.serviceObj.getArbData('GetGroupRecommendations', this.IneterestedPeople).subscribe(
      ArbApiResponse => {
        this.GroupRecommendations = ArbApiResponse["data"];
      }
    );

  }

  SearchGroupItems(tyValue) {
    if (tyValue != null)
      this.SearchObj.ItemType = tyValue;
    this.serviceObj.getArbData('GroupItems', this.SearchObj).subscribe(
      data => {
        this.GroupItems = data["data"]
      },
      err => console.error(err)
    );
  }

  Edit(Items: any) {
    this.serviceObj.SetData(Items);
    this.router.navigate(['/CreateGroup', 1]);
  }
  DeleteGroup(Item: any) {
    this.DelStatus=Item;
    $('#ConfirmID').removeClass('fade'); 
    $('body').addClass('modal-open');
    $('#ConfirmID').addClass('show');
  }

  OK(status:string,Item: any){
    $('#ConfirmID').addClass('fade'); 
    $('body').removeClass('modal-open');
    $('#ConfirmID').removeClass('show');
          if(status=='C'){

          }else if(status=='O'){
            this.GroupItems = this.GroupItems.filter(i => i.GroupID != Item.GroupID);
              this.serviceObj.postArbData('DeleteGroup', Item).subscribe(
                ArbApiResponse => {
                  this.GroupItems = this.GroupItems.filter(i => i.GroupID != Item.GroupID);
                  document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
                  document.getElementById('btnMessageModal').click();
                },
                ArbApiErr => { 
                  document.getElementById('divMessage').innerHTML = ArbApiErr["Message"];
                  document.getElementById('btnMessageModal').click();
                }
              );
          }
  }
  
  getInterestedPeopleList(Itm) {
    //this.IneterestedPeople.ID=Itm.SenderID;
    this.IneterestedPeople.GroupID = Itm.SocietyID;
    this.serviceObj.getArbData('GetIncomingRequestsBygroupID', this.IneterestedPeople).subscribe(
      ArbApiResponse => {
        this.IneterestedPeopleList = ArbApiResponse["data"];
      },
      err => console.error(err)
    );
  }

  AcceptOffer(rqst) {
    this.AcceptRejectRequest.SenderID = rqst.SenderID;
    this.AcceptRejectRequest.GroupID = rqst.SocietyID;
    this.AcceptRejectRequest.IsApproved = true;

    this.serviceObj.postArbData('AcceptRejectGroupRequest', this.AcceptRejectRequest).subscribe(
      ArbApiResponse => {
        this.IneterestedPeopleList = this.IneterestedPeopleList.filter(i => i.SenderID !== rqst.SenderID);
        if (this.IneterestedPeopleList.length == 0) {
          $('#incomingGroupPop').modal('toggle');
          this.Incoming = this.Incoming.filter(i => i.SocietyID != rqst.SocietyID);
        }

        this.serviceObj.getArbData('UserDetails', null).subscribe(
          ArbApiResponse => {
            this.serviceObj.removeUD();
            this.serviceObj.setUD(ArbApiResponse["data"], false);
            this.RefreshUD();
            document.getElementById('btnHeaderRefreshUD').click();
          });
      },
      ArbApiErr => {
      }
    );
  }

  RejectOffer(rqst) {
    this.AcceptRejectRequest.SenderID = rqst.SenderID;
    this.AcceptRejectRequest.GroupID = rqst.SocietyID;
    this.AcceptRejectRequest.IsApproved = false;
    this.serviceObj.postArbData('AcceptRejectGroupRequest', this.AcceptRejectRequest).subscribe(
      ArbApiResponse => {
        this.IneterestedPeopleList = this.IneterestedPeopleList.filter(i => i.SenderID !== rqst.SenderID);
        if (this.IneterestedPeopleList.length == 0) {
          $('#incomingGroupPop').modal('toggle');
          this.Incoming = this.Incoming.filter(i => i.SocietyID != rqst.SocietyID);
        }

        this.serviceObj.getArbData('UserDetails', null).subscribe(
          ArbApiResponse => {
            this.serviceObj.removeUD();
            this.serviceObj.setUD(ArbApiResponse["data"], false);
            this.RefreshUD();
            document.getElementById('btnHeaderRefreshUD').click();

          });
      },
      ArbApiErr => {
      }
    );
  }




  CancelSentGroupRequest(rqst: any) {
    this.AcceptRejectRequest.GroupID = rqst.SocietyID;
    this.serviceObj.postArbData('CancelSentGroupRequest', this.AcceptRejectRequest).subscribe(
      ArbApiResponse => {
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        this.Outgoing = this.Outgoing.filter(i => i.SocietyID !== rqst.SocietyID);
      },
      ArbApiErr => {
      }
    );
  }
        ViewRecommended(Item:any)
        {
         // $('#totalRecommended').modal('toggle');
          let Recommed:any={};
          Recommed.SocietyID=Item.SocietyID;
          Recommed.IsSent=(this.UserDetails.ID==Item.OwnerID);
          if(this.UserDetails.ID==Item.OwnerID)
          {
          if(this.GropupContent)
            this.RecommededBy=this.GropupContent.Recommendedto;
          }
          else
          {if(this.GropupContent)
            this.RecommededBy=this.GropupContent.Recommendedby;
          }
         
          this.serviceObj.getArbData('GetAllRecommendationByGroup', Recommed).subscribe(
            ArbApiResponse => {
              this.AllRecList=ArbApiResponse["data"];
              $('#totalRecommended').modal('toggle');
            });
        
      }

}
