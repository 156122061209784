import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Router, ActivatedRoute } from '@angular/router'
import { ImageCroppedEvent } from '../../../node_modules/ngx-image-cropper';
declare var $ :any;

@Component({
  selector: 'app-group-view',
  templateUrl: './group-view.component.html',
  styleUrls: ['./group-view.component.css']
})

export class GroupViewComponent implements OnInit {
  UserDetails: any = {}
  posted: number = 0;
  CommentID: number = 0;
  GroupDetailsList: any = [];
  GroupDetailsList1: any = {};
  isValidPostText:boolean=true;
  PostFileErrormsg:string='';
  MyNewPost: any = {};
  SearchObj: any = {};
  PostList: any = [];
  ID: any = 0;
  imageUrl: any = '';
  vdoURL: string = '';
  fileToUpload: File = null;
  isValidText: boolean = true;
  MyConnectionsList: any = [];
  NewRecommendation: any = {};
  NewComment: any = {};
  searchText: string = '';
  selectAll = true;
  selectedAll: any;
  MostRecommendedGroupsList: any = [];
  GroupRecommendationsList: any = [];
  GroupRecommendations: any = {};
  uploadedVdoFileName:string='';
  ShowOnlineVdoField:boolean=true;
  Suggested:any=[];
  Society:any=[];
  data2:any;
  tempImag:any='';
  GropupContent:any={};
  filesize:number=100;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isDisablePublish:boolean=false;
  TempGroupID:string='';

  constructor(private serviceObj: ArabesqueService, private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe(params => this.TempGroupID = params.ID);
    this.MyNewPost.ID =0;
    this.MyNewPost.EncryptedID=this.TempGroupID;
    this.MyNewPost.PostText = '';
    this.MyNewPost.FileTitle = '';

    this.SearchObj.GroupID =0;
    this.SearchObj.EncryptedID =this.TempGroupID;
    this.SearchObj.PageIndex = 1;
    this.SearchObj.ItemType = 'All';
    this.SearchObj.SearchText = '';
    this.SearchObj.IsTimeline = false;

    this.NewRecommendation.GroupID =0;
    this.NewRecommendation.EncryptedID =this.TempGroupID;
    this.NewRecommendation.UserIDs = '';

    this.NewComment.PostID =0;
    this.NewComment.GroupID =0;
    this.NewComment.EncryptedID =this.TempGroupID;
    this.NewComment.CommentText = '';
    this.GroupDetailsList1.GroupID =0;
    this.data2={};
    this.data2.image='';
  }

  RefreshUD() { 
    this.UserDetails = this.serviceObj.getUD();
    this.GropupContent=this.serviceObj.GetPageSpecificContent('Group');
  }

  
  ngOnInit() 
  {
    this.UserDetails = this.serviceObj.getUD();
    this.GropupContent=this.serviceObj.GetPageSpecificContent('Group');
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!='')
      {
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
        document.getElementById('btnMessageModal1').click();
      }
    }
    this.serviceObj.getArbData('GetGroupDetails', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.GroupDetailsList = ArbApiResponse["data"];
        this.Suggested=ArbApiResponse["data"]["SuggestedGroups"];
        this.Society=ArbApiResponse["data"]["Society"]
        this.GroupDetailsList1.GroupID=this.Society.ID;
        this.NewRecommendation.GroupID =this.Society.ID;
        if(this.UserDetails!=null && (this.GroupDetailsList.IsMember==true || this.Society.OwnerID==this.UserDetails.ID))
        {
          this.serviceObj.getArbData('GetGroupPosts', this.SearchObj).subscribe(
            ArbApiResponse => {
              this.PostList = ArbApiResponse["data"];
            });
        }
      }
    );
    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

  }

  LeaveGroup(MemberID:any){
    let LeaveGroup:any={};
    LeaveGroup.GroupID=this.GroupDetailsList1.GroupID;
    if(MemberID!=null && MemberID!=0)
      LeaveGroup.MemberID=MemberID;
    else
      LeaveGroup.MemberID='';

    this.serviceObj.postArbData('ExitGroup', LeaveGroup).subscribe(
      ArbApiResponse => {
        document.getElementById('divMessage').innerHTML =ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        if(ArbApiResponse["Status"]==1)
        {
          if(MemberID!=null && MemberID!=0)
          {
          this.Society.JoinStatus='';
          this.Society.Members=this.Society.Members.filter(i=>i.ID !==MemberID);
          $('#totalMemberCount').modal('toggle');
          }
          else      
            this.ngOnInit();
        }
      },
      ArbApiErr => {
      }
    );
  }

  

  ViewMembers()
  {
        if(this.Society.Members!=null && this.Society.Members.length>0){
          document.getElementById('btntotalMemberCount').click();
        }else{
          document.getElementById('divMessage').innerHTML ='There are no participant on this group';
          document.getElementById('btnMessageModal').click();
        }

   }

  AddPostFile(fileInput: any, fileType: string) 
  {
          if(this.UserDetails==null){
            document.getElementById('plusLoginBtn').click();
          } else
          {
          this.PostFileErrormsg='';
          this.MyNewPost.FileType = fileType;
          this.MyNewPost.PostPic = fileInput.target.files;
          this.ShowOnlineVdoField=true;
          let file: FileList = fileInput.target.files;
          if(file.length>0)
          {
              this.fileToUpload = file.item(0);
              //show image preview
              var reader = new FileReader();

              reader.onload = (event: any) => {
                if (fileType == 'Img') {
                  document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
                  setTimeout(() => {  
                    var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
                    var hgth = document.getElementById('imgDimensionValidation')['naturalHeight'];
                    if (wdth == 0 || hgth == 0 || wdth < 350) {
                      this.RemovePic();
                      this.PostFileErrormsg='Maximum allowed size for video is 10 MB';
                      this.imageUrl ='';
                      this.vdoURL = '';
                    }
                  }, 2000);
                  this.imageUrl = event.target.result;
                  this.vdoURL = '';
                  this.PostFileErrormsg='';
                }
                else
                {
                  if (fileInput.size == 0 || fileInput.size > 10240000) // 2MB
                  {
                    if(this.GropupContent)
                    this.PostFileErrormsg=this.GropupContent.MaximumVideo;
                    this.RemovePic();          
                    // show message
                  }
                  else
                  {
                    this.vdoURL = event.target.result;
                    this.imageUrl = '';
                    this.PostFileErrormsg='';
                    this.uploadedVdoFileName= file[0].name;
                  }
                
                }
              }
              reader.readAsDataURL(this.fileToUpload);
            }
      }
  }

  VideoLink()
  {
      this.MyNewPost.FileType='';
      this.MyNewPost.PostPic='';
      this.vdoURL='';
    this.ShowOnlineVdoField=!this.ShowOnlineVdoField;
      if(this.ShowOnlineVdoField==true)
      {
        this.MyNewPost.OnlineVdo='';
      }
  }
  
  RemovePic() {
    this.MyNewPost.PostPic = '';
    this.imageUrl = '';
    this.vdoURL = '';
    this.MyNewPost.FileTitle = '';
    this.MyNewPost.FileType = '';
  }
CreateGroupEvent() 
{
  if (this.UserDetails == null) {
    document.getElementById('plusLoginBtn').click();
  }
  else {
    if (sessionStorage.getItem('GroupID') != null) {
      sessionStorage.setItem('GroupID',null);
      sessionStorage.setItem('GroupID', this.GroupDetailsList1.GroupID);
      this.router.navigate(['/CreateEvents', 0]);
    }else
    {
      sessionStorage.setItem('GroupID', this.GroupDetailsList1.GroupID);
      this.router.navigate(['/CreateEvents', 0]);
    }
  }
}


fileChangeEvent(ev: any): void 
{
        let file:FileList=ev.target.files
       this.fileToUpload=file.item(0);
       if(file.length>0)
       {
          var reader=new FileReader();
          reader.onload=(event:any)=>{
            document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
            setTimeout(() => {  
              var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
              if(wdth>380)
              this.filesize=380
              else
              this.filesize=wdth
              this.imageChangedEvent = ev;
            }, 2000);
            
          }
          reader.readAsDataURL(this.fileToUpload);
      }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.data2.image = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  loadImageFailed() {
      // show message
  }
  PublishPost() 
  {
    if(this.UserDetails==null)
    {
      document.getElementById('plusLoginBtn').click();
    } 
    else
    {
      if (this.MyNewPost.PostText == '') {
        this.isValidPostText = false;
      }
      else {
        this.isValidPostText = true;
      }
    if (this.MyNewPost.PostText != '') 
    {
      this.isDisablePublish=true;
      this.MyNewPost.ID =0;
      this.serviceObj.postArbData('AddGroupPost', this.MyNewPost).subscribe(
        ArbApiResponse => {
          this.MyNewPost.PostPic = '';
          this.imageUrl = '';
          this.MyNewPost.FileTitle = '';
          this.MyNewPost.FileType = '';
          this.MyNewPost.PostText = '';
          this.isValidPostText = true;
          this.isDisablePublish=false;
          this.serviceObj.getArbData('GetGroupPosts', this.SearchObj).subscribe(
            ArbApiResponse => {
              this.PostList = ArbApiResponse["data"];
              this.RemovePic();
              this.isValidPostText = true;
              this.ShowOnlineVdoField = true;
            });
        },
        ArbApiErr => {
          this.isDisablePublish=false;
        }
      );
    }
    }
  }



  SendGroupRecommendation() {

    this.NewRecommendation.UserIDs = '';
    for (let r of this.MyConnectionsList.Connected) {
      if (r.IsReceivingRecommendation) {
        if (this.NewRecommendation.UserIDs == "")
          this.NewRecommendation.UserIDs += r.ID;
        else
          this.NewRecommendation.UserIDs += "," + r.ID;
      }
    }
    this.serviceObj.postArbData('SendGroupRecommendation', this.NewRecommendation).subscribe(
      ArbApiResponse => {

        document.getElementById('recommendPopup').click();
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
      },
      ArbApiErr => {
      }
    );
  }

  

  AddPostComment(pst: any) {
    if (document.getElementById('Comment_' + pst.PostID)["value"] != "") {
      this.NewComment.PostID = pst.PostID;
      this.NewComment.CommentID = 0;  
      this.NewComment.CommentText = document.getElementById('Comment_' + pst.PostID)["value"];
      this.serviceObj.postArbData('AddGroupComment', this.NewComment).subscribe(
        ArbApiResponse => {
          const d: Date = new Date();
          pst.Comments.push({
            "CommentID":ArbApiResponse["data"], "Comment": this.NewComment.CommentText, "CommentDateTime": d.toString(),
            "Replies": [],
            "CommentedBy": [{ "FirstName": this.UserDetails.FirstName, "PictureUrl": this.UserDetails.PictureUrl }]
          });
          document.getElementById('Comment_' + pst.PostID)["value"] = "";
          this.posted = pst.PostID;
        }
      );
    }
  }

  AddPostComment_ON(Comment1: any,PostID:number) {
    let NewComment: any = {};
    if (document.getElementById('Comment_ON_' + Comment1.CommentID)["value"] != "") {
      NewComment.CommentID = Comment1.CommentID;
      NewComment.PostID = PostID
      NewComment.CommentText = document.getElementById('Comment_ON_' + Comment1.CommentID)["value"];
      this.serviceObj.postArbData('AddGroupComment', NewComment).subscribe(
        ArbApiResponse => {
       const d: Date = new Date();
      Comment1.Replies.push({
        "CommentID": 0, "Comment": NewComment.CommentText, "CommentDateTime": d.toString(),
        "CommentedBy": [{ "FirstName": this.UserDetails.FirstName, "PictureUrl": this.UserDetails.PictureUrl }]
      });
      document.getElementById('Comment_ON_' + Comment1.CommentID)["value"] = "";
        }
      );
    }

  }
  GetMyConnections() 
  {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      
      this.serviceObj.getArbData('MyConnections', this.SearchObj).subscribe(
        ArbApiResponse => {
          this.MyConnectionsList = ArbApiResponse["data"];
          if(this.MyConnectionsList.Connected.length>0){
            document.getElementById('btnRecommendModal').click();
          }
          else
          {
            document.getElementById('divMessage').innerHTML ='You do not have any connection to recommend';
            document.getElementById('btnMessageModal').click();
          }
        },
        err => console.error(err)
      );
    }
  }


  //(click)="GetEventRecommendations()"

  // GetGroupRecommendations() 
  // {
  //   this.serviceObj.getArbData('GetGroupRecommendations', this.GroupRecommendations).subscribe(
  //     ArbApiResponse => {
  //       this.GroupRecommendationsList = ArbApiResponse["data"];
  //     },
  //     err => console.error(err)
  //   );
  // }
  PostCommentToggle(PostID: number) {
    if (this.posted != PostID) {
      this.posted = PostID
    } else {
      this.posted = 0;
    }

  }

  FirstCommentToggle(Comment: any) {
    if (this.CommentID != Comment.CommentID) {
      this.CommentID = Comment.CommentID
    } else {
      this.CommentID = 0;
    }

  }
  // selectAlls() {
  //   for (var i = 0; i < this.MyConnectionsList.Connected.length; i++) {
  //     this.MyConnectionsList.Connected[i].IsReceivingRecommendation = this.selectedAll;
  //   }
  // }
  // checkIfAllSelected() {
  //   this.selectedAll = this.MyConnectionsList.Connected.every(function(item:any) {
  //       return item.IsReceivingRecommendation == true;
  //     })
  // }

  selectAlls() {
    for (var i = 0; i < this.MyConnectionsList.Connected.length; i++) {
      this.MyConnectionsList.Connected[i].IsReceivingRecommendation = this.selectedAll;
    }
  }
  checkIfAllSelected() {
    this.selectedAll = this.MyConnectionsList.Connected.every(function (item: any) {
      return item.IsReceivingRecommendation == true;
    })
  }

  JoinGroup() {
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      if (this.Society.JoinStatus == '') {
        let NewJoinRequest:any={};
        NewJoinRequest.GroupID = this.GroupDetailsList1.GroupID ;     
        this.serviceObj.postArbData('JoinGroup', NewJoinRequest).subscribe(
          ArbApiResponse => {
            this.Society.JoinStatus = 'Request Sent';
            this.ngOnInit();
          },
          ArbApiErr => {
          }
        );
      }
    }
  }

  
//   fileChangeListener($event) 
//   {
//     var image:any = new Image();
//     var file:File = $event.target.files[0];
//     if(file!=undefined){
//     var myReader:FileReader = new FileReader();
//     var that = this;
//     myReader.onloadend = function (loadEvent:any) {
//         image.src = loadEvent.target.result;
//         //that.cropper.setImage(image);
//     };
//     myReader.readAsDataURL(file);
//   }
// }
  closeCro(){
    $('#MdlCropImg').removeClass('show'); 
    $('#MdlCropImg').addClass('fade'); 
    $('body').removeClass('modal-open');
  }

   CropImage(){
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    } else 
    {
        $('#MdlCropImg').removeClass('fade'); 
        $('body').addClass('modal-open');  
        $('#MdlCropImg').addClass('show'); 
    }
   } 

    base64MimeType(encoded) 
    {
    var result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

      SaveImage()
      {
        if(this.data2.image!='')
        {
          this.ShowOnlineVdoField=true;
          let blob=this.dataURItoBlob(this.data2.image);
          let typeimg=this.base64MimeType(this.data2.image);
          var t=typeimg.split('/')[1];
          let names:string='filename.'+t;
          var file = new File([blob], names, {type: typeimg});
          this.MyNewPost.PostPic=file;
          this.imageUrl=this.data2.image;
          this.MyNewPost.FileType='Img';
          this.vdoURL = '';
         // this.tempImag=this.data2.image;
     
        $('#MdlCropImg').removeClass('show'); 
        $('#MdlCropImg').addClass('fade'); 
        $('body').removeClass('modal-open');  
        }
      }

    
   RemoveIMG(){
     this.imageUrl='';
    this.MyNewPost.PostPic='';
   }
   dataURItoBlob(dataURI) 
   {
          var binary = atob(dataURI.split(',')[1]);
          var array = [];
        for (var i = 0; i < binary.length; i++) 
        {
          array.push(binary.charCodeAt(i));
        }
       return new Blob([new Uint8Array(array)], {    type: 'image/jpg'});
    }
  

}
