
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterChecked'
})

export class FilterCheckedPipe implements PipeTransform {

  transform(items: any[], IsChecked: boolean): any[] { 
    if (!items || items.length==0) return [];
    return items.filter(it => {
      //if(it.IsChecked==IsChecked)
      return it.Ischecked==IsChecked;  
       });
  }
}
