import { Component, OnInit, Injectable } from '@angular/core';
import { ArabesqueService } from '../arabesque-service.service';
import { Router,NavigationEnd,NavigationStart,ActivatedRoute} from '@angular/router';
import { Location, PopStateEvent } from "@angular/common";

declare var $ :any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  UserDetails: any = {};
  HomeContent: any = {};
  ReferralCode:any='';
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  constructor(private serviceObj: ArabesqueService,private route:ActivatedRoute,private router: Router, private location: Location) { 
    this.route.params.subscribe( params => this.ReferralCode =params.CODE );  
  }

  RefreshUD() { 
      this.UserDetails = this.serviceObj.getUD();
    this.HomeContent=this.serviceObj.GetPageSpecificContent('Home'); 
 }

  ngOnInit() 
        { 
            this.HomeContent=this.serviceObj.GetPageSpecificContent('Home'); 
             
                if (window.location.href.indexOf('/Home') > 0)
                document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
            else
                document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

                /*set on top start*/
                this.location.subscribe((ev:PopStateEvent) => {
                this.lastPoppedUrl = ev.url;
            });
            this.router.events.subscribe((ev:any) => {
                if (ev instanceof NavigationStart) {
                    if (ev.url != this.lastPoppedUrl)
                        this.yScrollStack.push(window.scrollY);
                } else if (ev instanceof NavigationEnd) {
                    if (ev.url == this.lastPoppedUrl) {
                        this.lastPoppedUrl = undefined;
                        window.scrollTo(0, this.yScrollStack.pop());
                    } else
                        window.scrollTo(0, 0);
                }
            });
            /*set on top start*/

          

      }//loginDialog

      HomeRegister(){
            document.getElementById('plusLoginBtn').click();
            $('#registerATab').addClass('active');
            $('#registerA').addClass('active').removeClass('fade');
            $('#loginATab').removeClass('active');
            $('#loginA').removeClass('active').addClass('fade');
            $('#forgotDiv').hide();
        }
        
        HomeLogin(){
            if (this.UserDetails == null) {                
                document.getElementById('plusLoginBtn').click();
            }
        }

        close()
        {
            $('#shareWithFriends').hide()
        }

                
    ClickInvite()
    {
        if (this.UserDetails == null) {
            document.getElementById('plusLoginBtn').click();
        }else{
            if (this.UserDetails.ReferralCode != null)
            $('#shareWithFriends').show();
            else {
            document.getElementById('divMessage').innerHTML = 'There is no referral code assigned to you, please contact support to assign a referral code before inviting your contacts.';
            document.getElementById('btnMessageModal').click();
            }

        }
    }
}
