import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Router } from '@angular/router';

declare var $: any;
declare var LanguageSpecificContent: any;
declare var UserSetting: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  Credentials: any = {};
  UserDetails: any = {};
  ErrorMsgServerReset: string = '';
  NewUser: any = {};
  ResetCredentials: any = {};
  ErrorMsg: string = '';
  ErrorMsgServer: string = '';
  ErrorMsgServerLogin: string = '';
  isValidUserPassword: boolean = true;
  isValidUserEmail: boolean = true;
  isValidUserName: boolean = true;
  isValidEmail: boolean = true;
  isValidPassword: boolean = true;
  isValidConfPassword: boolean = true;
  isValidReferralCode: boolean = true;
  isRegisterUserDisabled: boolean = true;
  isResetDisable: boolean = false;
  isLoginDisabled: boolean = false;
  TempRefCode: any = '';
  isValidresetEmail: boolean = true;
  Friends: any = [];
  Share: any = {};
  isEmail: boolean = false;
  FacebookSelectedContacts: any = [];
  FacebookNotSelectedContacts: any = [];
  GoogleSelectedContacts: any = [];
  GoogleNotSelectedContacts: any = [];
  LinkedInSelectedContacts: any = [];
  LinkedInNotSelectedContacts: any = [];
  MyConnectionsList: any = [];
  MyConnectionsListChat: any = [];
  phonePattern = "[0-9]{10}";
  InviteByEmail: any = [];
  SearchT: any;
  PageContent: any = {};
  Header: any = {};
  selectedCtry: any = {};
  SelectedCountryName: string;
  SelectedLanguage: string;
  SelectedLanguageStyle: string;
  CountryLanguages: any = [];

  LocationCountry: any = [{ 'CountryID': 32, 'CountryName': 'CAN', 'Name': 'Canada', 'cls': 'canadaFlag', 'cl': 'currentCanada', 'Lang': [{ 'code': 'EN', 'name': 'English' }, { 'code': 'FR', 'name': 'Français' }] },
  { 'CountryID': 53, 'CountryName': 'EGY', 'Name': 'Egypt', 'cls': 'egyptFlag', 'cl': 'currentEgypt', 'Lang': [{ 'code': 'AR', 'name': 'عربي' }, { 'code': 'EN', 'name': 'English' }] },
  { 'CountryID': 182, 'CountryName': 'UAE', 'Name': 'United Arab Emirates', 'cls': 'uaeFlag', 'cl': 'currentUae', 'Lang': [{ 'code': 'AR', 'name': 'عربي' }, { 'code': 'EN', 'name': 'English' }] }]

  constructor(private serviceObj: ArabesqueService, private route: Router) {
    this.Credentials.UserEmail = '';
    this.Credentials.UserPassword = '';

    this.NewUser.UserName = '';
    this.NewUser.Email = '';
    this.NewUser.UserPhone = '';
    this.NewUser.Password = '';
    this.NewUser.ConfPassword = '';
    this.NewUser.ReferredBy = '';
    this.NewUser.DontRefcode=false;
    
    this.NewUser.AgreeTermsChecked = false;
    this.NewUser.MemberType = true;

    this.ResetCredentials.Email = '';
    this.ResetCredentials.resetEmail = '';
    this.Share.Emails = '';
    this.Share.invitText = '';
    this.Share.inviteMsg = '';
    UserSetting.CountryID = 53;
    UserSetting.CountryName = 'EGY';
    UserSetting.Language = 'AR';
  }

  ngOnInit() {
    if (localStorage.getItem('UserSetting') != null) {
      UserSetting = JSON.parse(localStorage.getItem('UserSetting'));
      this.ChangeCountry(this.LocationCountry.filter(x => x.CountryID == UserSetting.CountryID)[0], true);
      this.SelectedLanguage = UserSetting.Language;
      let tempCountryLanguages: any = [];
      tempCountryLanguages = this.LocationCountry.filter(x => x.CountryID == UserSetting.CountryID)[0].Lang;
      this.ChangeLang(tempCountryLanguages.filter(x => x.code == UserSetting.Language)[0]);
    }
    else {
      this.ChangeCountry({ 'CountryID': 53, 'CountryName': 'EGY', 'Name': 'Egypt', 'cls': 'egyptFlag', 'cl': 'currentEgypt', 'Lang': [{ 'code': 'AR', 'name': 'عربي' }, { 'code': 'EN', 'name': 'English' }] })
    }

    this.GetPageContent();
    this.UserDetails = this.serviceObj.getUD();
    this.TempRefCode = this.serviceObj.getRefCode();
    if (this.TempRefCode != null && this.TempRefCode != 0) {
      this.NewUser.ReferredBy = this.TempRefCode;
    }
  }

  ChangeCountry(selectedCounty: any, isCallFromInit: boolean = false) {
    UserSetting.CountryID = selectedCounty.CountryID;
    UserSetting.CountryName = selectedCounty.CountryName;

    this.SelectedLanguageStyle = selectedCounty.cl;
    this.SelectedCountryName = selectedCounty.CountryName;
    this.CountryLanguages = selectedCounty.Lang;
    if (isCallFromInit == false)
      this.ChangeLang(selectedCounty.Lang[0]);
  }

  ChangeLang(selectedLanguage: any) {
   
    this.SelectedLanguage = selectedLanguage.code;
    if (UserSetting.Language != selectedLanguage.code) {
      UserSetting.Language = selectedLanguage.code;
     // this.SelectedLanguage = selectedLanguage.code;
      localStorage.setItem('UserSetting', JSON.stringify(UserSetting));
      this.GetPageContent();
      
      document.getElementById('btnChangeMapLanguage').click();
    }
   
    if (selectedLanguage.code == 'AR') {
      document.getElementById("arcss")["disabled"] = false;
    }
    else
      document.getElementById("arcss")["disabled"] = true;

    if (document.getElementById('btnRefreshInitData') != null)
      document.getElementById('btnRefreshInitData').click();
  }

  GetPageContent() {
    this.serviceObj.getArbData('PageContent', UserSetting).subscribe(
      ArbApiResponse => {
        LanguageSpecificContent = ArbApiResponse["data"];
        this.Header = this.serviceObj.GetPageSpecificContent('Header');
        document.getElementById('btnRefreshUD').click();
        document.getElementById('btnRefreshUD1').click();
      }
    );
  }

  Pending(Type: any) {
    let tab = sessionStorage.getItem('TAB');
    if (tab != null) {
      sessionStorage.removeItem('TAB');
    }

    if (Type == 'I') {
      sessionStorage.setItem('TAB', 'I');
      this.route.navigate(['/MyExchange']);
    }
    else if (Type == 'P') {
      sessionStorage.setItem('TAB', 'P');
      this.route.navigate(['/MyPeople']);
    }
    else if (Type == 'G') {
      sessionStorage.setItem('TAB', 'G');
      this.route.navigate(['/MyGroup']);
    }
    else if (Type == 'E') {
      sessionStorage.setItem('TAB', 'E');
      this.route.navigate(['/MyEvent']);
    }
  }

  UnSelectContact(provider, NotInvCont) {
    this.GoogleNotSelectedContacts.push(NotInvCont);
    this.GoogleSelectedContacts = this.GoogleSelectedContacts.filter(i => i.Email != NotInvCont.Email);
  }

  SelectContact(provider, InvCont) {
    this.GoogleSelectedContacts.push(InvCont);
    this.GoogleNotSelectedContacts = this.GoogleNotSelectedContacts.filter(i => i.Email != InvCont.Email);
  }

  IsLoggedIn() {
    if (this.UserDetails == null)
      document.getElementById('plusLoginBtn').click();
  }

  PasswordReset() {
    this.ErrorMsgServerReset = '';
    if (this.ResetCredentials.resetEmail != '') {
      this.ResetCredentials.Email = this.ResetCredentials.resetEmail;
      this.isResetDisable = true;
      this.isValidresetEmail = true;
      this.serviceObj.postArbData('PasswordReset', this.ResetCredentials).subscribe(
        ArbApiResponse => {
          if (ArbApiResponse["Status"] == 1) {
            this.ResetCredentials.resetEmail = '';
            document.getElementById('plusLoginBtn').click();
            document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
            document.getElementById('btnMessageModal').click();
            this.isValidresetEmail = true;
            this.ResetCredentials.resetEmail = '';
            this.ResetCredentials.Email = '';
            this.isResetDisable = false;
            this.isValidReferralCode = true;
          } else {
            this.ResetCredentials.resetEmail = '';
            this.ErrorMsgServerReset = ArbApiResponse["Message"];
            this.isValidresetEmail = true;
            this.ResetCredentials.resetEmail = '';
            this.ResetCredentials.Email = '';
            this.isResetDisable = false;
            this.isValidReferralCode = true;
          }

        },

      );
    }
    else {
      this.isResetDisable = false;
      this.isValidresetEmail = false;
    }
  }

  Login() {
    this.ErrorMsgServerLogin = '';
    if (this.Credentials.UserEmail == '') {
      this.isValidUserEmail = false;
    }
    else {
      this.isValidUserEmail = true;
    }
    if (this.Credentials.UserPassword == '') {
      this.isValidUserPassword = false;
    }
    else {
      this.isValidUserPassword = true;
    }
    if (this.Credentials.UserEmail == '' && this.Credentials.UserPassword == '') {
    }
    else {
      this.isLoginDisabled = true;
      this.serviceObj.postArbData('Login', this.Credentials).subscribe(
        ArbApiResponse => {
          if (ArbApiResponse["Status"] == 1) {
            this.UserDetails = ArbApiResponse["data"];
            this.serviceObj.setUD(this.UserDetails, false);
            document.getElementById('plusLoginBtn').click();
            this.ErrorMsgServerLogin = '';//ArbApiResponse["Message"];
            document.getElementById('btnRefreshUD').click();
            this.Credentials.UserEmail == '';
            this.Credentials.UserPassword == '';
            this.isValidUserPassword = true;
            this.isValidUserEmail = true;
            this.isLoginDisabled = false;
            if (this.UserDetails.IsBasicFilled !== '') {
              document.getElementById('divMessage1').innerHTML = this.UserDetails.IsBasicFilled;
              $("#MessageModal1").modal({ backdrop: 'static', keyboard: false });
            }
          }
          else {
            this.isLoginDisabled = false;
            this.UserDetails = null;
            this.Credentials.UserPassword == '';
            this.isValidUserPassword = true;
            this.isValidUserEmail = true;
            this.ErrorMsgServerLogin = ArbApiResponse["Message"];

          }
        },
        ArbApiErr => {
          this.isLoginDisabled = false;
          this.UserDetails = null;
          this.Credentials.UserPassword == '';
          this.isValidUserPassword = true;
          this.isValidUserEmail = true;
        }
      );
    }

  }

  Logout() {
    this.UserDetails = null;
    this.serviceObj.removeUD();

    //sessionStorage.clear();
    //localStorage.clear();
    //if (window.location.toString().toUpperCase().lastIndexOf('HOME') == -1)
    // window.location.href = '/';
    this.GetPageContent();
    //document.getElementById('btnRefreshUD').click();
    this.route.navigate(['/Home', 0]);
  }

  TermCheck() {
    if (this.NewUser.AgreeTermsChecked == true) {
      this.isRegisterUserDisabled = false;
    }
    else {
      this.isRegisterUserDisabled = true;
    }
  }

  RegisterUser() {

    this.ErrorMsgServer = '';
    if (this.NewUser.UserName == '') {
      this.isValidUserName = false;
    } else {
      this.isValidUserName = true;
    }
    if (this.NewUser.Email == '') {
      this.isValidEmail = false;
    } else {
      this.isValidEmail = true;
    }

    if (this.NewUser['Password'] == '') {
      this.isValidPassword = false;
    } else {
      this.isValidPassword = true;
    }
    if (this.NewUser["ConfPassword"] == '') {
      this.isValidConfPassword = false;
    } else {
      this.isValidConfPassword = true;
    }
    if (this.NewUser['Password'] == this.NewUser['ConfPassword']) {
      this.isValidConfPassword = true;
      this.ErrorMsg = '';
    } else {
      this.ErrorMsg = 'Confirm password is does not match.';
      this.isValidConfPassword = false;
    }
    if(this.NewUser.DontRefcode==false)
    {
              if (this.NewUser['ReferredBy'] == '') {
                this.isValidReferralCode = false;
              } else {
                this.isValidReferralCode = true;
              }

              if (this.NewUser.UserName != '' && this.NewUser.Email != '' && this.NewUser["password"] != '' && this.NewUser["ConfPassword"] != '' && this.NewUser['Password'] == this.NewUser['ConfPassword'] && this.NewUser['ReferredBy'] != '') 
              {
               this.RegisterNow();
              }
     }//without reffcode
     else
     {
      if (this.NewUser.UserName != '' && this.NewUser.Email != '' && this.NewUser["password"] != '' && this.NewUser["ConfPassword"] != '' && this.NewUser['Password'] == this.NewUser['ConfPassword']) 
      {
       this.RegisterAsk();
      }
     }

  }
  RegisterAsk()
  {
    this.isRegisterUserDisabled = true;
    this.serviceObj.postArbData('AskForReferralCode', this.NewUser).subscribe(
      ArbApiResponse => {
        if (ArbApiResponse["Status"] == 1) 
        {
          this.Logout();
          document.getElementById('btnRefreshUD').click();
          document.getElementById('plusLoginBtn').click();
          this.ErrorMsgServer = '';
          this.NewUser.UserName = '';
          this.NewUser.Email = '';
          this.NewUser['Password'] = '';
          this.NewUser['ConfPassword'] = '';
          this.isValidUserName = true;
          this.isValidEmail = true;
          this.isValidConfPassword = true;
          this.ErrorMsg = '';
          this.isValidPassword = true;
          this.isRegisterUserDisabled = true;
          this.isValidReferralCode = true;
          document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          document.getElementById('btnMessageModal').click();
        }
        else 
        {
          this.ErrorMsg = '';
          this.ErrorMsgServer = '';
          this.NewUser['Password'] = '';
          this.NewUser['ConfPassword'] = '';
          this.isRegisterUserDisabled = false;
          this.isValidPassword = true;
          this.isValidConfPassword = true;
          this.isValidReferralCode = true;
          this.ErrorMsgServer = ArbApiResponse["Message"];
        }
      },
      ArbApiErr => {
        this.isRegisterUserDisabled = false;
        this.ErrorMsgServer = ArbApiErr;
      }
    );
  }
RegisterNow()
{
  this.isRegisterUserDisabled = true;
  this.serviceObj.postArbData('Register', this.NewUser).subscribe(
    ArbApiResponse => {
      if (ArbApiResponse["Status"] == 1) {
        this.Logout();
        document.getElementById('btnRefreshUD').click();
        document.getElementById('plusLoginBtn').click();
        this.ErrorMsgServer = '';
        this.NewUser.UserName = '';
        this.NewUser.Email = '';
        this.NewUser['Password'] = '';
        this.NewUser['ConfPassword'] = '';
        this.isValidUserName = true;
        this.isValidEmail = true;
        this.isValidConfPassword = true;
        this.ErrorMsg = '';
        this.isValidPassword = true;
        this.isRegisterUserDisabled = true;
        this.isValidReferralCode = true;
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
      }
      else 
      {
        this.ErrorMsg = '';
        this.ErrorMsgServer = '';
        this.NewUser['Password'] = '';
        this.NewUser['ConfPassword'] = '';
        this.isRegisterUserDisabled = false;
        this.isValidPassword = true;
        this.isValidConfPassword = true;
        this.isValidReferralCode = true;
        this.ErrorMsgServer = ArbApiResponse["Message"];
      }
    },
    ArbApiErr => {
      this.isRegisterUserDisabled = false;
      this.ErrorMsgServer = ArbApiErr;
    }
  );

}

  FaceBookUser() {
    document.getElementById('btnFaceBookLogin').click();
  }

  close() {
    $('#shareWithFriends').hide()
  }

  getConnections(provider) {
    let objContacts: any = {};
    objContacts.provider = provider;

    if (provider == 'facebook')
      objContacts.code = localStorage.getItem("FacebookAccessCode");
    else if (provider == 'google')
      objContacts.code = localStorage.getItem("GoogleAccessCode");
    else if (provider == 'linkedin')
      objContacts.code = localStorage.getItem("LinkedInAccessCode");

    this.serviceObj.getArbData('MySocialConnections', objContacts)
      .subscribe(
        ArbApiResponse => {
          if (provider == 'facebook')
            this.FacebookNotSelectedContacts = ArbApiResponse["data"];
          else if (provider == 'google')
            this.GoogleNotSelectedContacts = ArbApiResponse["data"];
          else if (provider == 'linkedin')
            this.LinkedInNotSelectedContacts = ArbApiResponse["data"];
        }
      );

  }

  ClickInvite() {
    if (this.UserDetails.ReferralCode != null)
      $('#shareWithFriends').show();
    else {
      if (this.Header != null) {
        document.getElementById('divMessage').innerHTML = this.Header.Thereisnoreferral;
        document.getElementById('btnMessageModal').click();
      }
    }
  }

  ShareWithGoogleFriends() {
    if (this.UserDetails != null) {
      this.isEmail = true;
      let objShare: any = {};
      objShare.Emails = '';

      if (this.GoogleSelectedContacts.length > 0) {
        for (let x of this.GoogleSelectedContacts) {
          if (objShare.Emails == "") {
            objShare.Emails = x.Email;
          }
          else {
            objShare.Emails += ',' + x.Email;
          }
        }

        this.SendInvitations(objShare);
      }
      else {
        this.isEmail = false;
      }
    } else {
      document.getElementById('plusLoginBtn').click();
    }
  }


  ShareWithFriend() {
    if (this.UserDetails != null) {
      this.isEmail = false;
      let objShare: any = {};
      objShare.Emails = '';

      if (this.InviteByEmail.length > 0) {
        for (var x = 0; x < this.InviteByEmail.length; x++) {
          if (this.validateEmail(this.InviteByEmail[x].display)) {
            if (objShare.Emails == "") {
              objShare.Emails = this.InviteByEmail[x].display;
            }
            else {
              objShare.Emails += ',' + this.InviteByEmail[x].display;
            }
          }
          else {
            this.isEmail = true;
            break;
          }
        }
        if (this.isEmail == false) {
          this.SendInvitations(objShare);
        }

      }
      else {
        this.isEmail = false;
      }
    } else {
      document.getElementById('plusLoginBtn').click();
    }
  }
  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  SendInvitations(objShare) {
    objShare.Name = this.UserDetails.FirstName;
    objShare.ReferralCode = this.UserDetails.ReferralCode;
    this.serviceObj.postArbData('ShareWithFriend', objShare)
      .subscribe(
        ArbApiResponse => {
          if (ArbApiResponse["Status"] == 1) {
            $('#shareWithFriends').hide()
            this.InviteByEmail = [];
            document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
            document.getElementById('btnMessageModal').click();
          } else {
            $('#shareWithFriends').hide()
            document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
            document.getElementById('btnMessageModal').click();
          }
        }
      );
  }


  ClickShared() {
    $('#sharedFriendsL').show();
  }

  closeSh() {
    $('#sharedFriendsL').hide()
  }

  ChatUser(Item: any) {
    if (Item != null) {
      let user = localStorage.getItem('CHAT');
      if (user != null) {
        localStorage.removeItem('CHAT');
      }
      localStorage.setItem('CHAT', JSON.stringify(Item));
    }
    document.getElementById('btncallchat').click()
  }

  getUser() {
    if (this.UserDetails != null) {
      if (this.UserDetails.ChatStatus) {
        $('.switchUser input').attr("checked", "checked");
      } else {
        $('.switchUser input').removeAttr("checked");
      }
      this.serviceObj.getArbData('MyConnections', null).subscribe(
        ArbApiResponse => {
          if (ArbApiResponse["Status"] == 1) {
          this.MyConnectionsList = ArbApiResponse["data"];
          debugger;
              
                this.serviceObj.getArbData('GetUnreadChat', null).subscribe(ArbApiResponse => {
                     console.log('data  : ' + JSON.stringify(JSON.parse(ArbApiResponse))); 
                     this.MyConnectionsListChat=  ArbApiResponse;
           
                 });
                if(this.MyConnectionsListChat!=null){}
                 }
        });
    }
  }
// getChatUnread(){
//   http.get("https://api.talkjs.com/v1/gYvb5eK6/users/"+ +"/conversations?unreadsOnly=true")
//   .subscribe(response => {

//   });
// }

  ActiveInactiveUser(ChatStatus: boolean) {
    if (this.UserDetails != null) 
    {
      if (this.UserDetails.ChatStatus) 
      {
        $('.switchUser input').attr("checked", "checked");
      }
      else 
      {
        $('.switchUser input').removeAttr("checked");
      }
      let UserStatus: any = {};
      UserStatus.IsActive = !ChatStatus;
      this.serviceObj.postArbData('SetChatStatus', UserStatus).subscribe(
        ArbApiResponse => {
          if (ArbApiResponse["Status"] == 1) {
            this.serviceObj.getArbData('UserDetails', null).subscribe(
              ArbApiResponse => {
                this.serviceObj.removeUD();
                this.serviceObj.setUD(ArbApiResponse["data"], false);
              }
            );
          }
        }
      );

    }
  }
}
