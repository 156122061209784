import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
declare var $:any;
@Component({
  selector: 'test-contact',
  templateUrl: './contact.component.html',
  styles: []
})
export class ContactComponent implements OnInit {
  UserDetails: any = {};
  StaticPage: any = {};
  constructor(private serviceObj: ArabesqueService) { }

  RefreshUD() { this.UserDetails = this.serviceObj.getUD();
    this.StaticPage = this.serviceObj.GetPageSpecificContent('StaticPage'); }
  
  ngOnInit() {
    this.StaticPage = this.serviceObj.GetPageSpecificContent('StaticPage');
    this.UserDetails = this.serviceObj.getUD();
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
       $("#MessageModal1").modal({ backdrop: 'static', keyboard: false});
      }
    }
    if (window.location.href.indexOf('/Home') > 0)
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
  else
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader';
    
  }

}
