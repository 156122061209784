import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from '../arabesque-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit  {
  FooterContent:any={};
  constructor(private serviceObj: ArabesqueService) { 
  }

  RefreshUD() {
    this.FooterContent=this.serviceObj.GetPageSpecificContent('Footer'); 
 }

  ngOnInit() 
        { 
          this.FooterContent=this.serviceObj.GetPageSpecificContent('Footer');
        }
}
