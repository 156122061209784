
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] { 
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      if(it.FirstName!=null)
      return it.FirstName.toLowerCase().includes(searchText);
      else  if(it.Interest!=null)
      return it.Interest.toLowerCase().includes(searchText);
      else  if(it.Status!=null)
      return it.Status.toLowerCase().includes(searchText);
      else
      return it.Category.toLowerCase().includes(searchText);    });
  }




}
