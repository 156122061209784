import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Title ,Meta} from '@angular/platform-browser'; 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  constructor(private meta:Meta){
  }
}
