import { Component, OnInit } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-my-exchange',
  templateUrl: './my-exchange.component.html',
  styleUrls: ['./my-exchange.component.css']
})

export class MyExchangeComponent implements OnInit {
  order = "ItemID";
  ascIncoming: boolean = true;
  ascPending: boolean = true;
  ascApprove: boolean = true;
  ascendingRequests: boolean = true;
  ExItems: any = [];
  SearchObj: any = {};
  IneterestedPeople: any = {};
  AcceptRejectRequest: any = {};
  UserDetails: any = {}

  ApprovedRequests: any = [];
  ApprovedRequestsOwn: any = [];
  
 
  IncomingRequests: any = [];
  PendingRequests: any = [];
  IneterestedPeopleList = [];
  ItemRecommendationsList: any = [];
  DelStatus:any={};
  QAndAr:any={};
  isDisableAccept:boolean=false;
  AllRecList:any=[];
  RecommededBy: string;
  RequestLogList: any = [];
  ExchangeContent: any = {}
  UserFavoriteItems:any=[];
  
  constructor(private serviceObj: ArabesqueService, private router: Router) {
    this.SearchObj.PageIndex = 1;
    this.SearchObj.ItemType = 'All';
    this.SearchObj.SearchText = '';
    this.SearchObj.IsTimeline = true;
    this.SearchObj.SortBy = 'Latest';
    this.SearchObj.Distance = 0;
    this.SearchObj.Latitude = '';
    this.SearchObj.Longitude = '';
    this.SearchObj.CategoryID = 0;
    this.SearchObj.SubCategoryID = 0;

    this.IneterestedPeople.ID = 0;
    this.IneterestedPeople.RequestID = 0;
    this.IneterestedPeople.All = true;
    this.IneterestedPeople.ExchangeOfBarter = '';
    this.IneterestedPeople.ExchangeOfService =  '';
    this.IneterestedPeople.ExchangeOfPrice =0;
    this.IneterestedPeople.Currency = '';
    this.IneterestedPeople.ExchangeOfGift =  '';
    this.IneterestedPeople.ItemName = '';
    this.IneterestedPeople.Coin =0;
  }

  RefreshUD() { this.UserDetails = this.serviceObj.getUD();
    if(this.UserDetails==null)
    this.router.navigate(['/Home', 0]);
    this.ExchangeContent=this.serviceObj.GetPageSpecificContent('Exchange'); 
  }

  ngOnInit() 
  {
    this.UserDetails = this.serviceObj.getUD();
    this.ExchangeContent=this.serviceObj.GetPageSpecificContent('Exchange'); 
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
       $("#MessageModal1").modal({ backdrop: 'static', keyboard: false});
      }
    }
    let tab=sessionStorage.getItem('TAB');
    if(tab!=null){
      if(tab=='I')
      {
      $('#propMyItem').removeClass('active');
      $('#propItem').removeClass('active');

      $('#propReq').removeClass('active');
      $('#propRequest').removeClass('active');

      $('#propRecom').removeClass('active');
      $('#propRecomend').removeClass('active');
      
      $('#propReq').addClass('active');
      $('#propRequest').addClass('active');
      sessionStorage.removeItem('TAB');
      }
    }
    if (window.location.href.indexOf('/Home') > 0)
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
    else
      document.getElementsByTagName('header')[0].className = 'arabesqueHeader';

    this.serviceObj.getArbData('ExItems', this.SearchObj).subscribe(
      data => {
        this.ExItems = data["data"];
      },
      err => console.error(err)
    );
    //GetExchangeRequests
    this.serviceObj.getArbData('GetExchangeRequests', null).subscribe(
      ArbApiResponse => {
        this.ApprovedRequests = ArbApiResponse["data"]["ApprovedRequests"];
        this.IncomingRequests = ArbApiResponse["data"]["IncomingRequests"];
        this.PendingRequests = ArbApiResponse["data"]["PendingRequests"];
        this.ApprovedRequestsOwn = ArbApiResponse["data"]["ApprovedRequestOwn"];
      },
      err => console.error(err)
    );


  }
  ngAfterViewInit() {

    this.serviceObj.getArbData('GetMyItemRecommendations', null).subscribe(
      data => {
        this.ItemRecommendationsList = data["data"];
      },
      err => console.error(err)
    );
    this.serviceObj.getArbData('GetUserFavoriteItems', null).subscribe(
      data => {
        this.UserFavoriteItems = data["data"];
      },
      err => console.error(err)
    );

  }

  SortByIncoming() {
    this.ascIncoming = !this.ascIncoming;
  }

  SortByPending() {
    this.ascPending = !this.ascPending;
  }

  SortByApprove() {
    this.ascApprove = !this.ascApprove;
  }

  getInterestedPeopleList(Itm) {
   
    this.IneterestedPeople.ID = Itm.ItemID;
    this.IneterestedPeople.RequestID = Itm.RequestID;
    this.QAndAr.Quantity=Itm.Quantity;
    this.QAndAr.AcceptedRequests=Itm.AcceptedRequests;
    this.IneterestedPeople.ExchangeOfBarter = Itm.ExchangeOfBarter;
    this.IneterestedPeople.ExchangeOfService =  Itm.ExchangeOfService;
    this.IneterestedPeople.ExchangeOfPrice =  Itm.ExchangeOfPrice;
    this.IneterestedPeople.Currency = Itm.Currency;
    this.IneterestedPeople.ExchangeOfGift = Itm.ExchangeOfGift;
    this.IneterestedPeople.Coin = Itm.Coin;
    this.IneterestedPeople.ItemName =Itm.ItemName;

    this.isDisableAccept=this.QAndAr.AcceptedRequests>=this.QAndAr.Quantity;
    this.serviceObj.getArbData('GetUsersInterestedInItemMY', this.IneterestedPeople).subscribe(
      data => {
        this.IneterestedPeopleList = data["data"];
        this.isDisableAccept=this.QAndAr.AcceptedRequests>=this.QAndAr.Quantity;
      },
      err => console.error(err)
    );
  }

  AcceptOffer(rqst) {    
    this.isDisableAccept=true;
    this.AcceptRejectRequest.RequestID = rqst.RequestID;
    this.AcceptRejectRequest.ActionType = true;
    this.serviceObj.postArbData('AcceptRejectExchangeRequest', this.AcceptRejectRequest).subscribe(
      ArbApiResponse => 
      {
      
        if(ArbApiResponse["Status"]==1)
        {
        this.IneterestedPeopleList = this.IneterestedPeopleList.filter(i => i.RequestID !== rqst.RequestID);
        var temp = this.IncomingRequests.filter(i => i.ItemID == this.IneterestedPeople.ID)[0];
       this.QAndAr.AcceptedRequests=this.QAndAr.AcceptedRequests+1;
       temp.AcceptedRequests=temp.AcceptedRequests+1;
       this.isDisableAccept=this.QAndAr.AcceptedRequests>=this.QAndAr.Quantity;
        if (this.IneterestedPeopleList.length == 0) 
        {
          $('#incomingRequesPop').modal('toggle');
          this.IncomingRequests = this.IncomingRequests.filter(i => i.RequestID != rqst.RequestID);
        }
        
        this.serviceObj.getArbData('UserDetails', null).subscribe(
          ArbApiResponse => {
            this.serviceObj.removeUD();
            this.serviceObj.setUD(ArbApiResponse["data"], false);
            this.RefreshUD();
            document.getElementById('btnHeaderRefreshUD').click();
          });
        }
        else
        {
          $('#incomingRequesPop').modal('toggle');
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        }
        

      },
      ArbApiErr => {
      }
    );
  }

  RejectOffer(rqst) {
    this.AcceptRejectRequest.RequestID = rqst.RequestID;
    this.AcceptRejectRequest.ActionType = false;
    this.serviceObj.postArbData('AcceptRejectExchangeRequest', this.AcceptRejectRequest).subscribe(
      ArbApiResponse => 
      {
        
        if(ArbApiResponse["Status"]==1)
        {
        this.IneterestedPeopleList = this.IneterestedPeopleList.filter(i => i.RequestID !== rqst.RequestID);
        if (this.IneterestedPeopleList.length == 0) {
          $('#incomingRequesPop').modal('toggle');
          this.IncomingRequests = this.IncomingRequests.filter(i => i.RequestID != rqst.RequestID);
        }
        this.serviceObj.getArbData('UserDetails', null).subscribe(
          ArbApiResponse => {
            this.serviceObj.removeUD();
            this.serviceObj.setUD(ArbApiResponse["data"], false);
            this.RefreshUD();
            document.getElementById('btnHeaderRefreshUD').click();
      
          });
        }
        else
        {
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
        }
      },
      ArbApiErr => {
      }
    );
  }

  SearchExchangeItems(tyValue) {
    if (tyValue != null)
      this.SearchObj.ItemType = tyValue;
    this.serviceObj.getArbData('ExItems', this.SearchObj).subscribe(
      ArbApiResponse => {
        this.ExItems = ArbApiResponse["data"];
      },
      err => console.error(err)
    );
  }

  Edit(Items: any) {
    this.serviceObj.SetData(Items);
    this.router.navigate(['/AddExchange', 1])
  }

  DeleteExchange(Item: any) {
    this.DelStatus=Item;
    $('#ConfirmID').removeClass('fade'); 
    $('body').addClass('modal-open');
    $('#ConfirmID').addClass('show');
  }


  OK(status:string,Item: any)
  {
    $('#ConfirmID').addClass('fade'); 
    $('body').removeClass('modal-open');
    $('#ConfirmID').removeClass('show');
          if(status=='C')
          {

          }else if(status=='O')
          {
              this.serviceObj.postArbData('DeleteItem', Item).subscribe(
                ArbApiResponse => {
                  this.ExItems = this.ExItems.filter(i => i.ItemID != Item.ItemID);
                  document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
                  document.getElementById('btnMessageModal').click();
                },
                ArbApiErr => { 
                }
              );
          }
  }


  DeleteItemRecommendation(Item: any) {
    let ObjItem: any = {};
    ObjItem.ItemID = Item.ItemID;
    ObjItem.SenderID = Item.SenderID;
    this.serviceObj.postArbData('DeleteItemRecommendation', ObjItem).subscribe(
      ArbApiResponse => {
        this.ItemRecommendationsList = this.ItemRecommendationsList.filter(i => i.ItemID !== Item.ItemID)
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
      },
      ArbApiErr => {
      }
    );
  }  

   // Offer Log
   requestofferlog(Item: any) {
    let ObjItem: any = {};
    ObjItem.ID = Item.ItemID;
    $('#requestLog').modal('toggle');
    if (this.UserDetails == null) {
      document.getElementById('plusLoginBtn').click();
    }
    else {
      this.serviceObj.getArbData('GetOfferLog',  ObjItem).subscribe(
          ArbApiResponse => {
            this.RequestLogList = ArbApiResponse["data"];
          },
          err => console.error(err)
        );
      }
    }
    requestItemofferDetail(Item: any) {
      let ObjItem: any = {};
      ObjItem.ID = Item.ItemID;
      ObjItem.UsrID =Item.RequestPersonID;
      $('#requestItemDetail').modal('toggle');
      if (this.UserDetails == null) {
        document.getElementById('plusLoginBtn').click();
      }
      else {
        this.serviceObj.getArbData('GetOfferLogDetail',  ObjItem).subscribe(
            ArbApiResponse => {
              this.RequestLogList = ArbApiResponse["data"];
            },
            err => console.error(err)
          );
        }
      }
  CancelPendingRequest(rqst) {

    this.AcceptRejectRequest.RequestID = rqst.RequestID;
    this.serviceObj.postArbData('CancelPendingRequest', this.AcceptRejectRequest).subscribe(
      ArbApiResponse => {
        if(ArbApiResponse["Status"]==1)
        {
        this.PendingRequests = this.PendingRequests.filter(i => i.RequestID !== rqst.RequestID);
        }
        document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
        document.getElementById('btnMessageModal').click();
      },
      ArbApiErr => {
      }
    );
  }

  
        ViewRecommended(Item:any)
        {
          let ItemsRec:any={};
          ItemsRec.ItemID=Item.ItemID;
          ItemsRec.IsSent=(this.UserDetails.ID==Item.SenderID);
          if(this.UserDetails.ID==Item.SenderID)
          {
            if(this.ExchangeContent)
            this.RecommededBy=this.ExchangeContent.Recommendedto;
          }
          else
          {
            if(this.ExchangeContent)
          this.RecommededBy=this.ExchangeContent.Recommendedby;
          }
          this.serviceObj.getArbData('GetAllRecommendationByItem', ItemsRec).subscribe(
            ArbApiResponse => {
              this.AllRecList=ArbApiResponse["data"];
              $('#totalRecommended').modal('toggle');
            });
        
      }

      
  SetASFavorite(exchangeItem:any)
  {
        if (this.UserDetails == null) 
            document.getElementById('plusLoginBtn').click();
      else{
        let MyFav:any={};
        MyFav.ItemID=exchangeItem.ItemID;
        MyFav.Status='N'
        this.serviceObj.postArbData('AddWishList', MyFav).subscribe(
          ArbApiResponse => {
            debugger;
            if(ArbApiResponse["Status"]==1)
            {
                this.UserFavoriteItems=this.UserFavoriteItems.filter(i=>i.FavID!=exchangeItem.FavID);
            }
          document.getElementById('divMessage').innerHTML = ArbApiResponse["Message"];
          document.getElementById('btnMessageModal').click();
          },
        );
      }
  }

}
