import { Component, OnInit,ViewChild } from '@angular/core';
import { ArabesqueService } from './../arabesque-service.service';
import { Router,NavigationEnd,NavigationStart,ActivatedRoute  } from '@angular/router';
import { Location, PopStateEvent } from "@angular/common";
import { ImageCroppedEvent } from '../../../node_modules/ngx-image-cropper';

declare var $ :any;
declare var UserSetting: any;
@Component({
  selector: 'app-add-exchange',
  templateUrl: './add-exchange.component.html',
  styleUrls: ['./add-exchange.component.css']
})

export class AddExchangeComponent implements OnInit {
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  isEdit:number=0
  isDisablePublish:boolean=false;
  addPicsValidation:string='';
  addCoverPicValidation:string='';
  isImageEdit:boolean=false;
  public sms:string='';
  NewExchange: any = {};
  updateExchange: any = {};
  ExchangeCatagories: any = [];
  ExchangeSubCatagories: any = [];
  Currencies: any = [];
  UserDetails: any = {}
  MyList: any = [];
  PublisList: any = [];
  isValid: boolean = true;
  isValidDescription: boolean = true;
  isValidCat: boolean = true;
  isValidSubCad: boolean = true;
  isValidKey: boolean = true;
  isValidPrice: boolean = false;
  isValidCur: boolean = true;
  isValidQuantity:boolean=true;
  isValidSer: boolean = true;
  isValidBarter: boolean = true;
  isValidGift: boolean = true;
  isStart: boolean = false;
  isNext: boolean = true;
  isNext1: Boolean = true;
  ErrorMessage: string = '';
  public showSell: boolean = false;
  public showServ: boolean = false;
  public showBarter: boolean = false;
  public showGift: boolean = false;
  isWallPost:boolean=false;
  imageUrl:string='';
  fileToUpload:File=null;
  fileToUpload1:File=null;
  Basic:string='pl-3 pb-3 position-relative sle ActiveS';
  Product:string='pl-3 pb-3 position-relative sle';
  Publish:string='pl-3 pb-3 position-relative sle';
  showIndicator = true;
  ExchangeContent: any = {};

TempImageArr:any=[];
TempImageArray:any=[];
ID:number=0;
tempImag:any='';
EnteredKeywords:string='';
filesize:number=100;
imageChangedEvent: any = '';
croppedImage: any = '';

  constructor(private serviceObj: ArabesqueService,private route:ActivatedRoute, private router: Router,private location: Location) {
    this.NewExchange.ItemID = 0;
    this.NewExchange.ItemName = '';
    this.NewExchange.CategoryID = 0;
    this.NewExchange.SubCategoryID = 0;
    this.NewExchange.Category = '';
    this.NewExchange.SubCategory = '';
    this.NewExchange.Currency=''; 
    this.NewExchange.Keywords =[];
    this.NewExchange.KeyW ='';
    this.NewExchange.Description = '';  
    this.NewExchange.Latitude = '';
    this.NewExchange.Longitude = ''; 

    this.NewExchange.CurrencyID = 1;
    this.NewExchange.ExchangeOfPrice = '';
    this.NewExchange.ExchangeOfService = '';
    this.NewExchange.ExchangeOfBarter = '';
    this.NewExchange.ExchangeOfGift = '';
    this.NewExchange.CoverPic='';
    this.NewExchange.ProductPic = '';
    this.NewExchange.Quantity=1;
    this.NewExchange.Coin='';
    this.NewExchange.ExpireOn=15;
    this.NewExchange.isWallPost=false;

    this.route.params.subscribe( params => this.isEdit =params.ID );
  }
  switchIndicator(): void 
  {
    this.showIndicator = !this.showIndicator;
  }
  RefreshUD() { this.UserDetails = this.serviceObj.getUD();
    if(this.UserDetails==null)
    this.router.navigate(['/Home', 0]);
    this.ExchangeContent=this.serviceObj.GetPageSpecificContent('Exchange'); 
  }

  ngOnInit() {
    this.UserDetails = this.serviceObj.getUD();
    this.ExchangeContent=this.serviceObj.GetPageSpecificContent('Exchange'); 
    if(this.UserDetails==null)
    this.router.navigate(['/Home', 0]);
    if(this.UserDetails!=null)
    {
      if(this.UserDetails.IsBasicFilled !=0 && this.UserDetails.IsBasicFilled!=''){
        document.getElementById('divMessage1').innerHTML=this.UserDetails.IsBasicFilled;
        document.getElementById('btnMessageModal1').click();
      }
    }

    if (window.location.href.indexOf('/Home') > 0)
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
  else
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader';
            /*set on top start*/
            this.location.subscribe((ev:PopStateEvent) => {
              this.lastPoppedUrl = ev.url;
          });
          this.router.events.subscribe((ev:any) => {
              if (ev instanceof NavigationStart) {
                  if (ev.url != this.lastPoppedUrl)
                      this.yScrollStack.push(window.scrollY);
              } else if (ev instanceof NavigationEnd) {
                  if (ev.url == this.lastPoppedUrl) {
                      this.lastPoppedUrl = undefined;
                      window.scrollTo(0, this.yScrollStack.pop());
                  } else
                      window.scrollTo(0, 0);
              }
          });
           /*set on top start*/


    this.serviceObj.getArbData('ExchangeCategories', '').subscribe(
      ArbApiResponse => { 
        this.ExchangeCatagories = ArbApiResponse["data"]["Categories"];
       this.Currencies = ArbApiResponse["data"]["Currencies"]; 
       
      if(this.isEdit==1){
       this.serviceObj.getData.subscribe(      
        u_data => {
          if(u_data!='0') {  
            this.isImageEdit=true;
            this.isWallPost=true;
             this.ExchangeSubCatagories = this.ExchangeCatagories[u_data["CategoryID"] - 1]["Subcategories"];
             this.NewExchange = u_data;    
            if(u_data["ExchangeOfPrice"]>0 || u_data["Coin"]>0){
              this.NewExchange.ExchangeOfPrice=u_data["ExchangeOfPrice"];
              this.showSell=true;
            //  $('#sellProd')
            $('#sellProd').attr("checked", "checked");
            }else{
              this.showSell=false;
            }
            //ExchangeOfService
            if(u_data["ExchangeOfService"].length){
              this.showServ=true;
              $('#exchaneProd').attr("checked", "checked");
            }else{
              this.showServ=false;
            }
                 //ExchangeOfBarter
                 if(u_data["ExchangeOfBarter"].length){
                  this.showBarter=true;
                  $('#barterProd').attr("checked", "checked");
                }else{
                  this.showBarter=false;
                }
                     //ExchangeOfGift
            if(u_data["ExchangeOfGift"].length){
              this.showGift=true;
              $('#giftProd').attr("checked", "checked");
            }else{
              this.showGift=false;
            }
            if(this.NewExchange.ExpireOn==null || this.NewExchange.ExpireOn==''){
              this.NewExchange.ExpireOn=15;
            }else{

            }
            if(this.NewExchange.isWallPost==null){
              this.NewExchange.isWallPost=false;
            }
            if(this.NewExchange.Coin==null){
              this.NewExchange.Coin='';
            }
            if(this.NewExchange.Keywords!='')
            {
              this.NewExchange.Keywords =this.serviceObj.GetSplitArray(this.NewExchange.Keywords);           
            }
          }
        }
      );
    }//if end
    else {
    if (UserSetting.CountryID==182){this.NewExchange.CurrencyID = 1;}
 if (UserSetting.CountryID==32){this.NewExchange.CurrencyID = 26;}
 if (UserSetting.CountryID==53){this.NewExchange.CurrencyID = 41;}
    }
      },
      ArbApiErr => { 
        //console.error("ArbApiErr (UserDetails)-->" + ArbApiErr); 
      }
    );
    document.getElementById('btnLoadMap').click();
  }


  fileChangeEvent(ev: any): void 
  {
          let file:FileList=ev.target.files
          if(file.length>0)
          {
              this.fileToUpload=file.item(0);
                var reader=new FileReader();
                reader.onload=(event:any)=>
                {
                  document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
                  setTimeout(() => {  
                    var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
                    if(wdth>380)
                    this.filesize=380
                    else
                    this.filesize=wdth
                    this.imageChangedEvent = ev;
                  }, 2000);
                  
                }
            reader.readAsDataURL(this.fileToUpload);
        }
    }
  
    imageCropped(event: ImageCroppedEvent) {
  
      this.croppedImage = event.base64;
    }
  
    imageLoaded() {
        // show cropper
    }
    loadImageFailed() {
        // show message
    }

  onSelectCur(crID) {
    var selindex = crID.split(":")[0];
 
    if (selindex == 0) {
      this.isValidCur=false;
    } else {
      this.isValidCur=true;
    }
  }
  onSubCategorySelect(vlue){
    var selindex = vlue.split(":")[0];
if(selindex==0){
  this.isValidSubCad=false;
}
 else{
  this.isValidSubCad=true;
  }
}

  onCategorySelect(vlue) {   
    var selindex = vlue.split(":")[0];
    if (selindex == 0) { 
    this.isValidCat=false;
     }
    else {
      this.isValidCat=true;
      this.ExchangeSubCatagories = this.ExchangeCatagories[this.NewExchange.CategoryID - 1]["Subcategories"];
    }
  }

  StartExchange() {
    this.Product='pl-3 pb-3 position-relative sle ActiveS';
     this.isValid=false;
     this.isValidCat=false;
     this.isValidSubCad=false;
     this.isValidKey=false;
    this.ErrorMessage = '';
    if (this.NewExchange.ItemName=='') {
      this.isValid=false;
      this.isStart = false;
      this.isNext = true;
      this.isNext1 = true;
    } else {
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
      this.isValid=true;
    }
    if (this.NewExchange.CategoryID == 0) {
      this.isValidCat=false;
      this.isStart = false;
      this.isNext = true;
      this.isNext1 = true;
    } else {
      this.isValidCat=true;
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
    }
    if (this.NewExchange.SubCategoryID == 0) {
      this.isValidSubCad=false;
      this.isStart = false;
      this.isNext = true;
      this.isNext1 = true;
      return false;
    } else {
      this.isValidSubCad=true;
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
    }
    if (this.NewExchange.Keywords.length==0) {
     this.isValidKey=false;
      this.isStart = false;
      this.isNext = true;
      this.isNext1 = true;
    }
    else {
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
     this.isValidKey=true;
      
    }
    if (this.NewExchange.Description=='') {
      this.isValidDescription=false;
       this.isStart = false;
       this.isNext = true;
       this.isNext1 = true;
     }
     else {
       this.isStart = true;
       this.isNext = false;
       this.isNext1 = true;
      this.isValidDescription=true;
       
     }
     
    if(this.UserDetails.RegisteredAs=='B'){
    if (this.NewExchange.Quantity ==0) {
      this.isValidQuantity=false;
       this.isStart = false;
       this.isNext = true;
       this.isNext1 = true;
     }
     else {
       this.isStart = true;
       this.isNext = false;
       this.isNext1 = true;
      this.isValidQuantity=true;
     }
    }

    if(this.NewExchange.ItemName=='' || this.NewExchange.CategoryID == 0 || this.NewExchange.SubCategoryID == 0 || this.NewExchange.Keywords.length==0){
      this.isStart = false;
      this.isNext = true;
      this.isNext1 = true;
    
    }else{
      this.isStart = true;
      this.isNext = false;
      this.isNext1 = true;
      //  this.NewGroup.Category =this.GroupsCategory.filter(x => x.CategoryID == this.NewGroup.CategoryID)[0].Category;
         
    }
    this.NewExchange.Category =this.ExchangeCatagories.filter(x => x.ID == this.NewExchange.CategoryID)[0].NameEn;
    this.NewExchange.SubCategory = this.ExchangeSubCatagories.filter(x => x.ID == this.NewExchange.SubCategoryID)[0].NameEn;

    this.EnteredKeywords='';
    for(var x=0;x<this.NewExchange.Keywords.length;x++){
        if(this.EnteredKeywords=='')
        this.EnteredKeywords=this.NewExchange.Keywords[x].value;
        else
        this.EnteredKeywords=this.EnteredKeywords+','+this.NewExchange.Keywords[x].value;
      }
  }

  NextExchangeValidation() {
    /*Price*/
    debugger;
    this.Publish='pl-3 pb-3 position-relative sle ActiveS';
    this.ErrorMessage = '';
    if (this.showSell == true) {
      if (this.NewExchange.ExchangeOfPrice >0 || this.NewExchange.Coin>0) {
        this.isValidPrice = false;
        this.isStart = true;
        this.isNext = true;
        this.isNext1 = false;
      }
      else 
      {
        this.isValidPrice = true;
        this.isStart = true;
        this.isNext = false;
        this.isNext1 = true;
      }

    }

    /*service*/
    if (this.showServ == true) {
      if (this.NewExchange.ExchangeOfService=='') {
      this.isValidSer=false;
        this.isStart = true;
        this.isNext = false;
        this.isNext1 = true;
      }
      else {
        this.isStart = true;
        this.isNext = true;
        this.isNext1 = false;
        this.isValidSer=true;
      }
    } /*Barter*/
    if (this.showBarter == true) {
      if (this.NewExchange.ExchangeOfBarter=='') {
        this.isStart = true;
        this.isNext = false;
        this.isNext1 = true;
        this.isValidBarter=false;
      }
      else {
        this.isStart = true;
        this.isNext = true;
        this.isNext1 = false;
        this.isValidBarter=true;
      }
    }/*Gift*/
    if (this.showGift == true) {
      // if (this.NewExchange.Gift=='') 
      // {
      //   this.isStart = true;
      //   this.isNext = false;
      //   this.isNext1 = true;
      //   this.isValidGift=false;
      // }
      // else {
      //   this.isStart = true;
      //   this.isNext = true;
      //   this.isNext1 = false;
      //   this.isValidGift=true;
      // }
      if (this.NewExchange.ExchangeOfGift=='') {
        this.isStart = true;
        this.isNext = false;
        this.isNext1 = true;
        this.isValidGift=false;
      }
      else {
        this.isStart = true;
        this.isNext = true;
        this.isNext1 = false;
        this.isValidGift=true;
      }
    }
    if(this.showGift==false && this.showBarter==false && this.showServ==false && this.showSell==false)
    {
     if(this.ExchangeContent){
        document.getElementById('divMessage').innerHTML=this.ExchangeContent.PleaseSelectAny;
        document.getElementById('btnMessageModal').click();
      }
    }
  
    this.NewExchange.CurrencyName =this.Currencies.filter(x => x.ID == this.NewExchange.CurrencyID)[0].Symbol;   
}
PublishProduct(){ 
  this.isDisablePublish=true; 
  //this.NewExchange.Keywords=this.EnteredKeywords;  
  this.NewExchange.KeyW=this.EnteredKeywords; 
  if (document.getElementById('txtAddressCoords')["value"] != '') {
    this.NewExchange.Latitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[0];
    this.NewExchange.Longitude = document.getElementById('txtAddressCoords')["value"].split(' - ')[1];
  }
  else {
    this.NewExchange.Latitude = '';
    this.NewExchange.Longitude = '';
  }
        this.serviceObj.postArbData('PublishProduct', this.NewExchange).subscribe(
          ArbApiResponse => {
            if(ArbApiResponse["Status"]==1){
              this.router.navigate(['/MyExchange'])
            }
            else{
              this.isDisablePublish=false;
            }
            document.getElementById('divMessage').innerHTML=ArbApiResponse["Message"];
            document.getElementById('btnMessageModal').click();
            console.clear();
          },
          ArbApiErr => { 
            this.isDisablePublish=false;
           }
        );
    }


  PreviousExchangeStart() {
    this.Product='pl-3 pb-3 position-relative sle';
    this.isStart = false;
    this.isNext = true;
    this.isNext1 = true;
  }
  PreviousExchangeValidation() {
    this.isStart = true;
    this.isNext = false;
    this.isNext1 = true;
  }

  toggleSell() {
    this.showSell = !this.showSell;
  }
  toggleService() {
 
    this.showServ = !this.showServ;
  }
  toggleBarter() {
    this.showBarter = !this.showBarter;
  }
  toggleGift() {
    this.showGift = !this.showGift;
  }

/* --------------------*/

//   fileChangeListener($event) {
//     var image:any = new Image();
//     var file:File = $event.target.files[0];
//     if(file!=undefined){
//     var myReader:FileReader = new FileReader();
//     var that = this;
//     myReader.onloadend = function (loadEvent:any) {
//         image.src = loadEvent.target.result;
//         //that.cropper.setImage(image);
//     };
//     myReader.readAsDataURL(file);
//   }
// }
  closeCro()
  {
    $('#MdlCropImg').removeClass('show'); 
    $('#MdlCropImg').addClass('fade'); 
    $('body').removeClass('modal-open');   
  }

   CropImage()
   {
    $('#MdlCropImg').removeClass('fade'); 
    $('body').addClass('modal-open');  
    $('#MdlCropImg').addClass('show');  
   } 

    base64MimeType(encoded) 
    {
    var result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

      SaveImage()
      {
        if(this.TempImageArr.length>0)
        {
            let i:number=1;
          for(let x of this.TempImageArr)
          {
            let blob=this.dataURItoBlob(x.IMG);
            let typeimg=this.base64MimeType(x.IMG);
            var t=typeimg.split('/')[1];
            let names:string='filename.'+t;
            var file = new File([blob], names, {type: typeimg});
            if(i==1)
            this.NewExchange.ProductPic=file;
            if(i==2)
            this.NewExchange.ProductPic0=file;
            if(i==3)
            this.NewExchange.ProductPic1=file;
            if(i==4)
            this.NewExchange.ProductPic2=file;
            if(i==5)
            this.NewExchange.ProductPic3=file;
            if(i==6)
            this.NewExchange.ProductPic4=file;
            i++;
            this.isImageEdit=false;
          }
         
        }
        this.TempImageArray=this.TempImageArr;
        $('#MdlCropImg').removeClass('show'); 
        $('#MdlCropImg').addClass('fade'); 
        $('body').removeClass('modal-open');
      }

  Add()
  {
      if(this.croppedImage!='')
      {
          this.ID+=this.ID+1;
          if(this.TempImageArr.length<6){
            this.TempImageArr.push({ID:this.ID,IMG:this.croppedImage});
      }
    }
    this.imageChangedEvent='';
   }    

   RemoveIMG(Img:any){
    this.TempImageArr=this.TempImageArr.filter(x => x.ID != Img.ID);
   }

   dataURItoBlob(dataURI) {
     if(dataURI!=null)
     {
          var binary = atob(dataURI.split(',')[1]);
          var array = [];
        for (var i = 0; i < binary.length; i++) 
        {
          array.push(binary.charCodeAt(i));
        }
       return new Blob([new Uint8Array(array)], {    type: 'image/jpg'});
      }
    }
  

}
