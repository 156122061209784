import {Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms'
import {Observable} from 'rxjs';
import { ArabesqueService } from './../arabesque-service.service';
import { CommonModule ,Location, PopStateEvent } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { Router,NavigationEnd,NavigationStart  } from '@angular/router';


@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  UserDetails: any = {}
  ProfileImages: any = {}
  fileToUpload:File=null;
  fileToUpload1:File=null;
  imageUrlProfilePic:string='';
  imageUrlCoverPic:string='';
  UserDetails_CoverPic:string='';
  UserDetails_PictureUrl:string='';
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  ProfilePicErrormsg:string='';
  CoverPicErrormsg:string='';

  constructor(private serviceObj: ArabesqueService,private router: Router, private location: Location) {
    this.ProfileImages.CoverPic='';
    this.ProfileImages.ProfilePic='';    
   }
  
  RefreshUD() { this.UserDetails = this.serviceObj.getUD(); }

  ngOnInit() {
    this.UserDetails = this.serviceObj.getUD();
    this.imageUrlCoverPic=this.UserDetails.CoverPic;
    this.imageUrlProfilePic=this.UserDetails.PictureUrl;

    if (window.location.href.indexOf('/Home') > 0)
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader arabesqueHeaderFront';
  else
    document.getElementsByTagName('header')[0].className = 'arabesqueHeader';
    

        /*set on top start*/
        this.location.subscribe((ev:PopStateEvent) => {
          this.lastPoppedUrl = ev.url;
      });
      this.router.events.subscribe((ev:any) => {
          if (ev instanceof NavigationStart) {
              if (ev.url != this.lastPoppedUrl)
                  this.yScrollStack.push(window.scrollY);
          } else if (ev instanceof NavigationEnd) {
              if (ev.url == this.lastPoppedUrl) {
                  this.lastPoppedUrl = undefined;
                  window.scrollTo(0, this.yScrollStack.pop());
              } else
                  window.scrollTo(0, 0);
          }
      });
       /*set on top start*/
  }

  SaveProfileImages(){
        this.serviceObj.postArbData('SaveProfileImages', this.ProfileImages).subscribe(
          ArbApiResponse => {
           
           // setTimeout(this.getDetails(), 100000);
            //------------deb
            this.serviceObj.getArbData('UserDetails', null).subscribe(
              ArbApiResponse => {                 
               // this.RefreshUD();

               // this.UserDetails = ArbApiResponse["data"];              

                this.serviceObj.setUD(ArbApiResponse["data"], false);   
                setTimeout(() => {
                  this.RefreshUD();
                  this.UserDetails.CoverPic=this.imageUrlCoverPic;
                  this.UserDetails.PictureUrl=this.imageUrlProfilePic;
                }, 1500);
               
                // this.UserDetails_PictureUrl=ArbApiResponse["data"]["PictureUrl"];
                // this.UserDetails_CoverPic=ArbApiResponse["data"]["CoverPic"];
               // document.getElementById('btnRefreshUD').click();
              },
              ArbApiErr => {
              //  console.error("ArbApiErr (Login)-->" + ArbApiErr);
             }
            ); 
             //------------ 
             document.getElementById('divMessage').innerHTML=ArbApiResponse["Message"];
             document.getElementById('btnMessageModal').click();   
          },
          ArbApiErr => { 
            //this.UserDetails = null; console.error("ArbApiErr (Login)-->" + ArbApiErr);
           }
        );
      }


  addPics(fileInput: any) {
    this.ProfilePicErrormsg='';
   
    let file:FileList=fileInput.target.files
  this.fileToUpload=file.item(0);
  var reader=new FileReader();
  reader.onload=(event:any)=>{
    document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
    setTimeout(() => {  
      var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
      var hgth = document.getElementById('imgDimensionValidation')['naturalHeight'];
      if (wdth == 0 || hgth == 0 || wdth < 349) {
        this.ProfilePicErrormsg='Minimum width of image should be 350px';
        this.imageUrlProfilePic='';
      }else{
        this.ProfileImages.ProfilePic = fileInput.target.files;
        this.ProfilePicErrormsg='';
        this.imageUrlProfilePic=event.target.result;
      }
    }, 2000);
   
  }
  reader.readAsDataURL(this.fileToUpload);
  }

  addCoverPic(fileInput: any) { 
    this.CoverPicErrormsg=''; 
  
    let file:FileList=fileInput.target.files
    this.fileToUpload1=file.item(0);
    var reader=new FileReader();
    reader.onload=(event:any)=>{
      document.getElementById('imgDimensionValidation')['src'] = event.target.result;        
      setTimeout(() => {  
        var wdth = document.getElementById('imgDimensionValidation')['naturalWidth'];
        var hgth = document.getElementById('imgDimensionValidation')['naturalHeight'];
        if (wdth == 0 || hgth == 0 || wdth < 1349) {
          this.CoverPicErrormsg='Minimum width of image should be 1350px';
          this.imageUrlCoverPic='';
        }else{
          this.ProfileImages.CoverPic = fileInput.target.files;
          this.CoverPicErrormsg='';
          this.imageUrlCoverPic=event.target.result;
        }
      }, 2000);

    }
    reader.readAsDataURL(this.fileToUpload1);
  
  }
}
